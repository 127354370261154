import React, { useCallback, useEffect, useState } from 'react'

import { Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import services from '../../services/index.js'

//import './style.scss'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Pagination } from '../../components/Table'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { Collapse, Divider, Popconfirm } from 'antd'
import { getChamadosRes, getDashboardRulesPaged } from '../../services/api'
import { IDashboardFilterRule, IDashboardRule } from '../../interfaces'
import { number } from '../../services/mask'
import TableDashboardFilterRule from './TableDashboardFilterRule'
import { useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { debounce } from 'lodash'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import SearchIcon from '@mui/icons-material/Search'
import { Delete, Save } from '@mui/icons-material'


function DashboardFilterRules() {
  const { id } = useParams();

  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const { promiseInProgress } = usePromiseTracker()

  const [dashboardFilterRules, setDashboardFilterRules] = useState<IDashboardFilterRule[] | []>([])
  const [overviewFilter, setOverviewFilter] = useState<string | undefined>()
  const [modalNewItem, setModalNewItem] = useState<boolean>(false)
  const [modalEditItem, setModalEditItem] = useState<boolean>(false)

  const [modalPerfil, setModalPerfil] = useState(false)
  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')
  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null);


  const [name, setNewName] = useState<string | undefined>(undefined)
  const [fixedName, setFixedName] = useState<string | undefined>(undefined)
  const [column, setNewColumn] = useState<string | undefined>(undefined)
  const [table, setNewTable] = useState<string | undefined>(undefined)

  const [attribute, setAttribute] = useState<string | undefined>(undefined)
  const [value, setValue] = useState<string | undefined>(undefined)
  const [dashId, setDashId] = useState<number | undefined>(undefined)
  const [envId, setEnvId] = useState<number | undefined>(undefined)
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const [userFullName, setUserFullName] = useState<string | undefined>(undefined)

  const [editId, setEditId] = useState<number | undefined>(undefined)
  const [editAttribute, setEditAttribute] = useState<string | undefined>(undefined)
  const [editValue, setEditValue] = useState<string | undefined>(undefined)
  const [editDashId, setEditDashId] = useState<number | undefined>(undefined)
  const [editEnv, setEditEnv] = useState<number | undefined>(undefined)
  const [editUserFullName, setEditUserFullName] = useState<string | undefined>(undefined)
  
  
  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')
  const [filterUsers, setFilterUsers] = useState<any>([])



  const [overviewActive, setOverviewActive] = useState<string>()
  const [commonSearch, setCommonSearch] = useState<string>('')
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDesc, setOrderDesc] = useState<boolean>(false)
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)



  const getDashboardFilterRules = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.getDashboardFilterRulesPaged(currentPage, pageSize, orderBy, commonSearch, orderDesc, id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os items'
        )
      }
      setDashboardFilterRules(res.data.data)
      setTotalCount(res.data.totalCount)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [overviewFilter, orderBy, orderDesc, currentPage, pageSize, commonSearch])


  const getRule = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.getDashboardRule(id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os items'
        )
      }
      setFixedName(res.data.name)
      setNewName(res.data.name)
      setNewColumn(res.data.column)
      setNewTable(res.data.table)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [])



  useEffect(() => {
    const init = async () => {
      await getRule()
      await getDashboardFilterRules()
    };
    init().then()
  }, []);


  const handlePagination = async (page: number, busca: any, itemsPerPage: number) => {
    setPageSize(itemsPerPage)
    setCurrentPage(page)
  }

  const handleSortOrder = (data: any) => {
    try {
      setOrderBy(data.column.key)
      setOrderDesc(data.order != 'ascend')
    } catch {
      setOrderBy(undefined)
    }
    getDashboardFilterRules()
  }


  const handleEditItem = async (data: any) => {
    try {
      let res = await trackPromise(services.api.getDashboardFilterRule(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar o item'
        )
      }
      setEditValue(res.data.value)
      setEditId(res.data.id)
      setEditAttribute(res.data.attribute)
      setEditUserFullName(res.data.user.name)
      setEditEnv(res.data.environmentId)
      setEditDashId(res.data.dashboardId)
      setModalEditItem(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleDeleteItem = async (data: any) => {
    try {
      let res = await trackPromise(services.api.deleteDashboardFilterRule(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível deletar este item'
        )
      }
      notify("Item deletado com sucesso", {variant: 'success'})
      await getDashboardFilterRules()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  function toNullable(value: any): any {
    return value === undefined || value === '' ? null : value;
  }

  const saveEditedItem = useCallback(async () => {
    try {
      let data = {
        Id: editId,
        Attribute: editAttribute,
        Value: editValue,
        dashboardId: toNullable(editDashId),
        environmentId: toNullable(editEnv)
      }
      let res = await trackPromise(services.api.updateDashboardRuleItem(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível salvar o item'
        )
      }
      notify("Item criado com sucesso", {variant: 'success'})
      setUserId(undefined)
      setUserFullName(undefined)
      setAttribute(undefined)
      setValue(undefined)
      setEditDashId(undefined)
      setEditEnv(undefined)
      setModalNewItem(false)
      await getDashboardFilterRules()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [editAttribute, editValue, editId, editEnv, editDashId])

  const saveEditedRule = useCallback(async () => {
    try {
      let data = {
        Id: id,
        Name: name,
        Column: column,
        Table: table,
      }
      let res = await trackPromise(services.api.updateDashboardRule(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível salvar alterações'
        )
      }
      notify("Alterações salvas com sucesso", {variant: 'success'})
      await getRule()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [id, name,  column, table])


  const deleteRule = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.deleteDashboardRule(id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível salvar alterações'
        )
      }
      window.location.href = `/app/dash_management/rules`
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [id])
  
  
  const createNewRuleItem = useCallback(async () => {
    try {
      let data = {
        attribute: attribute,
        value: value,
        userId: userId,
        dashboardRuleId: id,
        dashboardId: toNullable(dashId),
        environmentId: toNullable(envId)
      }
      let res = await trackPromise(services.api.createNewDashboardRuleItem(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível adicionar novo item'
        )
      }
      notify("Item criado com sucesso", {variant: 'success'})
      setUserId(undefined)
      setUserFullName(undefined)
      setAttribute(undefined)
      setValue(undefined)
      setEnvId(undefined)
      setDashId(undefined)
      setModalNewItem(false)
      await getDashboardFilterRules()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [attribute, value, userId, envId, dashId])



  const openNewItemModal = () =>{
    setModalNewItem(true)
  }

  const closeNewItemModal = () =>{
    setModalNewItem(false)
  }

  const closeEditItemModal = () =>{
    setModalEditItem(false)
  }

  const handleClickAddUser = (idUser: any, nameUser: any, index: number) => {
    setUserId(idUser)
    setUserFullName(nameUser)
    setModalPerfil(false)
    debugger
  }

  const handleChangeBuscaUserDisponivel = (busca:string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }

  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget);
  };

  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800),  [colunaBuscaUserDisponivel])

  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca
    
    try {
      let termoBuscado = busca ? busca : busca
      let res: any
      let paramns = {adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : ''}
      if (userRoles.includes("common_users_read_any")) {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      } else {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      let usersOrdeby = []
      if (userRoles.includes("common_users_read_any")) {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
      } else {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        // usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
      }
      setFilterUsers(usersOrdeby)
    } catch (error: any) {
      notify(error.message, { variant: 'warning' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //getDasgboardRules()
    }, 50)

    return () => clearTimeout(delayDebounceFn)
  }, [currentPage, pageSize])



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getDashboardFilterRules()
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [commonSearch])



  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id='wrapper-page-content'>
        <PageTitle subText={`Editando regra #${id}`}>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{fixedName ? `Regra  ${fixedName}` : 'Regra > ...'}</div>
          </div>


        </PageTitle>

        <Grid container justifyContent='center' spacing={{ xs: 3 }}>
          <Grid item md={11} xs={12}>
            <div className='panel'>
              <div className="panel-body">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowBackIcon sx={{ fontSize: 40, marginRight: '10px', cursor: 'pointer' }}
                                 onClick={() => window.location.href = `/app/dash_management/rules`} />
                  <h2>Dados da regra</h2>
                </div>


                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Nome"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={name}
                      onChange={e => setNewName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Tabela"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={table}
                      onChange={e => setNewTable(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Coluna"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={column}
                      onChange={e => setNewColumn(e.target.value)}
                    />
                  </Grid>


                </Grid>

                <Grid item xs={12} className="flex justify-end gap-6">

                  <div style={{textAlign: "right", marginTop: "10px"}}>

                    <Popconfirm title="Deseja deletar esta regra e seus items? A regra também será removida dos dashboards!" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={deleteRule}>
                      <Button
                        variant="contained"
                        className='btn-red mr-6 content-end mb-12'
                        type="button"
                        size='small'
                        color="inherit"
                        //onClick={Delete}
                      >
                        <Delete style={{marginRight: '5px'}} fontSize='small' />  Excluir regra
                      </Button>
                    </Popconfirm>


                  </div>

                  <div style={{textAlign: "right", marginTop: "10px"}}>
                    <Button
                      variant="contained"
                      className='btn-purple mr-6 content-end mb-12'
                      type="button"
                      size='small'
                      color="inherit"
                      //disabled={!dashboardChanged}
                      onClick={saveEditedRule}
                    >
                      <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar alterações
                    </Button>
                  </div>



                </Grid>


                <Divider></Divider>
                <h3>Items da regra</h3>


                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>


                  <Button
                    variant="contained" type="button" color="secondary"
                    onClick={openNewItemModal}
                    startIcon={<AddCircleOutlineIcon fontSize="small" />}
                    //disabled={promiseInProgress}
                  >
                    <span>Novo</span>
                  </Button>


                  <TextField
                    size="small"
                    label="Buscar nas regras"
                    placeholder="Buscar nas regras"
                    value={commonSearch}
                    onChange={(e) => setCommonSearch(e.target.value)}
                    defaultValue=""
                  />

                </div>

                <TableDashboardFilterRule
                  data={dashboardFilterRules}
                  sortFunction={handleSortOrder}
                  deleteFunction={handleDeleteItem}
                  editFunction={handleEditItem}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  itensLength={200}
                  totalCount={totalCount}
                  handlePagination={handlePagination}
                  colunaBusca={''}
                  search={''}
                />

              </div>
            </div>
          </Grid>
        </Grid>


      </div>


      <Modal
        size="sm"
        open={modalPerfil}
        close={() => setModalPerfil(false)}
        titulo={'Selecionar usuário'}
      >
        <Grid container spacing={{ xs: 3 }} id="Modal_seach_user">
          <Grid item md={12} xs={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item md={12}>
                <TextField
                  size="small"
                  className="w-200"
                  label="Pesquisa"
                  placeholder='Pesquisa'
                  inputProps={{ maxLength: 50 }}
                  value={searchUserDisponivel}
                  onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                  disabled={promiseInProgress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{backgroundColor: '$cor-primary', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                          aria-label="toggle password visibility"
                          onClick={(e) => handleClickBuscaUserDisponivel(e)}
                          edge="end"
                        >
                          <FilterAltIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <div className='table-container'>
                  <table className='table_styled'>
                    <thead>
                    <tr>
                      <th>
                        <div>
                          <span className='th-title'>Usuário</span>
                        </div>
                      </th>
                      <th><div><span className='th-title'></span></div></th>
                    </tr>
                    </thead>
                    <tbody>
                    {!filterUsers &&
                      <tr><td colSpan={8}><div className='justify-center'>loading...</div></td></tr>
                    }
                    {filterUsers?.length === 0 &&
                      <tr><td colSpan={8}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                    }
                    {filterUsers?.map((item: any, index: number) => (
                      <tr key={item.id}>
                        <td>
                          <div style={{flexDirection: 'column', alignItems: 'start'}}>
                            <span>{item.name}</span>
                            <span style={{fontSize: '12px'}}>{item.email}</span>
                            <span style={{fontSize: '12px'}}>{item.company}</span>
                          </div>
                        </td>
                        <td>
                          <div className='justify-end'>
                            <Button
                              variant="contained" type="button" color="secondary"
                              onClick={() => handleClickAddUser(item.id, item.email, index)}
                              size="small"
                              disabled={promiseInProgress}
                            >
                              <span>Selecionar</span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size='small'
              color="inherit"
              onClick={() => setModalPerfil(false)}
            >
              cancelar
            </Button>
          </Grid>
        </Grid>

      </Modal>




      <Modal
        open={modalEditItem}
        close={closeEditItemModal}
        titulo={'Editar item'}
      >
        <Grid container spacing={{ xs: 3 }} className="sm-min-500">

          <Grid item xs={12} >

            <div className="flex justify-center">
              <TextField
                size='small'
                label='Atributo'
                placeholder='Atributo'
                value={editAttribute}
                onChange={e => setEditAttribute(e.target.value)}
                defaultValue=""
              />
            </div>

          </Grid>

          <Grid item xs={12} >
            <div className="flex justify-center">
              <TextField
                size='small'
                label='Valor'
                placeholder='Valor'
                value={editValue}
                onChange={e => setEditValue(e.target.value)}
                defaultValue=""
              />
            </div>
          </Grid>

          <Grid item xs={12} >
            <TextField
              fullWidth
              required
              size='small'
              className="primary"
              label="Usuário"
              disabled={true}
              inputProps={{ maxLength: 6 }}
              InputLabelProps={{ shrink: true }}
              value={editUserFullName}
            />

          </Grid>


          <Grid item xs={12} >
          <Collapse
            items={[{ key: '1', label: 'Opções avançadas', children: 
            <div>
              <Grid item xs={12} >
                <div className="flex justify-center">
                  <TextField
                    size='small'
                    label='ID Do Dashboard'
                    type='number'
                    value={editDashId}
                    onChange={e => setEditDashId(number(e.target.value))}
                    defaultValue=""
                  />
                </div>
              </Grid>
              <br/>
              <Grid item xs={12} >
                <div className="flex justify-center">
                  <TextField
                    size='small'
                    label='ID Do Ambiente'
                    type='number'
                    value={editEnv}
                    onChange={e => setEditEnv(number(e.target.value))}
                    defaultValue=""
                  />
                </div>
              </Grid>
              
              
            </div>
            
            }]}
          />
            
          </Grid>


          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success"
              type="button"
              size='small'
              //disabled={newName === undefined || newTable === undefined || newColumn === undefined}
              onClick={saveEditedItem}
            >
              Salvar alterações
            </Button>
          </Grid>
        </Grid>
      </Modal>
      
      
      
      
      
      
      

      <Modal
        open={modalNewItem}
        close={closeNewItemModal}
        titulo={'Novo item'}
      >
        <Grid container spacing={{ xs: 3 }} className="sm-min-500">

          <Grid item xs={12} >

            <div className="flex justify-center">
              <TextField
                size='small'
                label='Atributo'
                placeholder='Atributo'
                value={attribute}
                onChange={e => setAttribute(e.target.value)}
                defaultValue=""
              />
            </div>

          </Grid>

          <Grid item xs={12} >
            <div className="flex justify-center">
              <TextField
                size='small'
                label='Valor'
                placeholder='Valor'
                value={value}
                onChange={e => setValue(e.target.value)}
                defaultValue=""
              />
            </div>
          </Grid>

          <Grid item xs={12} >
            <TextField
              fullWidth
              required
              size='small'
              className="primary"
              label="Usuário"
              disabled={true}
              inputProps={{ maxLength: 6 }}
              InputLabelProps={{ shrink: true }}
              value={userFullName}
              InputProps={{
                endAdornment: <>
                  {
                    <InputAdornment position="start" style={{marginRight: '0px'}}>
                      <IconButton
                        style={{padding: '2px', margin: '-8px', borderRadius: '4px'}}
                        className='btn-purple'
                        onClick={() => setModalPerfil(true)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                </>
                ,
              }}
            />

          </Grid>

          <Grid item xs={12} >
            <Collapse
              items={[{ key: '1', label: 'Opções avançadas', children:
                  <div>
                    <Grid item xs={12} >
                      <div className="flex justify-center">
                        <TextField
                          size='small'
                          label='ID Do Dashboard'
                          type='number'
                          value={dashId}
                          onChange={e => setDashId(number(e.target.value))}
                          defaultValue=""
                        />
                      </div>
                    </Grid>
                    <br/>
                    <Grid item xs={12} >
                      <div className="flex justify-center">
                        <TextField
                          size='small'
                          label='ID Do Ambiente'
                          type='number'
                          value={envId}
                          onChange={e => setEnvId(number(e.target.value))}
                          defaultValue=""
                        />
                      </div>
                    </Grid>


                  </div>

              }]}
            />

          </Grid>
          

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success"
              type="button"
              size='small'
              //disabled={newName === undefined || newTable === undefined || newColumn === undefined}
              onClick={createNewRuleItem}
            >
              Salvar novo item
            </Button>
          </Grid>
        </Grid>
      </Modal>

    </div>
  )
}

export default DashboardFilterRules
