import * as React from 'react';
import { useState, useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import MenuIcon from '@mui/icons-material/Menu';

import {
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  IconButton
} from '@mui/material'

import { IEnvironment } from '../../interfaces/index'
import './style.scss'

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IEnviriomentsListComponent {
  environments: Array<IEnvironment> | []
  activeEnvironmentId: number | null | undefined
  activeEnvironmentName: string | null | undefined
  children?: any
  changeActiveEnvironment: any
}

const EnviriomentsList = (props: IEnviriomentsListComponent) => {
  const { environments, activeEnvironmentId, changeActiveEnvironment, activeEnvironmentName } = props
  const { promiseInProgress } = usePromiseTracker()
  
  const [filterEmpresas, setFilterEmpresas] = useState([...environments])
  const [search, setSearch] = useState('')
  const [state, setState] = React.useState({
    right: false
  });

  const anchor = 'right'
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      className="enviriomentsList_container"
    >
      <ListItem disablePadding className="enviriomentsList_title" onClick={toggleDrawer(anchor, false)}>
        <ArrowBackIcon /> <ListItemText primary={'Selecione a opção:'} />
      </ListItem>
      <ListItem>
        <TextField
          size='small'
          className='btn-rounded'
          placeholder='Busca'
          inputProps={{ maxLength: 100 }}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </ListItem>
      <List onClick={toggleDrawer(anchor, false)}>
        {filterEmpresas.map((item: IEnvironment, index: number) => (
          <ListItem key={item.id} disablePadding 
            className={`${item.id === activeEnvironmentId ? 'active' : ''}`}
          >
            <ListItemButton 
              onClick={() => changeActiveEnvironment(item)} 
              disabled={promiseInProgress}
            >
              <ListItemText primary={item.name} /> <CheckIcon />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    if (environments.length) {
      if (search.length) {
        setFilterEmpresas([...environments.filter(item => item.name.toUpperCase().includes(search.toUpperCase()))])
      } else {
        setFilterEmpresas([...environments])
      }
    }
  }, [search, environments])

  return (
    <div>
      <React.Fragment key={anchor}>
        <div className='btn-open-envirioments'>
          {/* icon mobile */}
          <IconButton 
            className='btn-open-envirioments_icon' 
            size="small" aria-label="upload picture" 
            component="label" 
            onClick={toggleDrawer(anchor, true)}
            disabled={promiseInProgress}
            // variant="contained"
          >
            <MenuIcon />
          </IconButton>
          {/* icon desktop */}
          <Button className='btn-open-envirioments_text' 
            onClick={toggleDrawer(anchor, true)}
            disabled={promiseInProgress}
          >
            {activeEnvironmentName}
          </Button>
        </div>
        
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          // ModalProps={{
          //   keepMounted: true, // Better open performance on mobile.
          // }}
          // variant="temporary"
          // PaperProps={{onClick: handleClose}}
          // onClick={toggleDrawer(anchor, true)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default EnviriomentsList;