import { Popconfirm, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import moment from 'moment/moment'
import services from '../../services'
import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { IDashboardFilterRule, IDashboardRule } from '../../interfaces'
import { DeleteOutline, Download } from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'


interface ITableDashboardFilterRule {
  data: IDashboardFilterRule[],
  sortFunction: any
  deleteFunction: any
  editFunction: any
}

const TableDashboardFilterRule: React.FC<ITableDashboardFilterRule> = ({ data, sortFunction, deleteFunction, editFunction }) => {
  const columns: TableColumnsType<IDashboardFilterRule> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'ascend',
      key: 'id'
    },

    {
      title: "Atributo",
      sorter: true,
      key: 'Attribute',
      render: (_, record) => record.attribute
    },

    {
      title: "Valor",
      sorter: true,
      key: 'Value',
      render: (_, record) => record.value
    },
    {
      title: "Usuário",
      sorter: true,
      key: 'Column',
      render: (_, record) => record.user.name
    },
    {
      title: "ID do dashboard",
      sorter: true,
      key: 'Column',
      render: (_, record) => record.dashboardId
    },
    {
      title: "ID do ambiente",
      sorter: true,
      key: 'Column',
      render: (_, record) => record.environmentId
    },
    {
      title: "Adicionado em",
      sorter: true,
      key: 'CreatedAt',
      render: (_, record) => {
        const higienizadoEm = moment(record.createdAt);
        return higienizadoEm.format('DD/MM/YYYY') === '01/01/0001' ? '---' : higienizadoEm.format('DD/MM/YYYY');
      }
    },
    {
      title: 'Ações',
      render: (_, record) =>
        <div>

          <IconButton
            size="small" aria-label="editar usuário" component="label"
            onClick={() => editFunction(record.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          <Popconfirm title="Deseja deletar este item?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => deleteFunction(record.id)}>
            <IconButton
              size="small" aria-label="editar usuário" component="label"
              //disabled={promiseInProgress}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Popconfirm>
          
        </div>

    }
  ]


  const handleChange = (pagination: any, filters: any, sorter: any) => {
    sortFunction(sorter)
  }

  return (
    <>
      <Table
        locale={{
          triggerDesc: 'Ordenação descendente',
          triggerAsc: 'Ordenação ascendente',
          cancelSort: 'Cancelar ordenação',
          emptyText: 'Nenhuma regra'
        }}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.id!.toString()}
        onChange={handleChange}
        dataSource={data}
      />

    </>
  );
}


export default TableDashboardFilterRule;