import { useState, useEffect } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { cpf } from 'cpf-cnpj-validator'

import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useAuth } from '../../../hooks/useAuth'
import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import { IRecruitmentReasonData } from '../../../interfaces/index'
import services from '../../../services/index.js'

interface IRecrutadores {
  employees: IRecruitmentReasonData[] | []
  remove: any
  add: any
  ticketDataId?: number
  ticketStatus?: string
  indexLocal?: number
  indexFuncao?: number
  quantidade?: number | string
  edicaoMode?: boolean
}

function Recrutadores(props: IRecrutadores) {
  const { 
    employees, remove, add, ticketDataId, ticketStatus,
    indexLocal, indexFuncao, quantidade, edicaoMode
  } = props;
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const { notify } = useNotification()
  const { promiseInProgress } = usePromiseTracker();

  const [modalAddRecruitment, setModalAddRecruitment] = useState<boolean>(false)
  const [recruitmentNome, setRecruitmentNome] = useState<string>('')
  const [recruitmentCpf, setRecruitmentCpf] = useState<string>('')

  const [blockEdicao, setBlockEdicao] = useState<boolean>(true)

  const resetModal = () => {
    setModalAddRecruitment(false)
    setRecruitmentNome('')
    setRecruitmentCpf('')
  }

  const adicionar = async () => {
    if (!recruitmentNome) {
      notify('Informe o nome', { variant: 'warning' })
      return false
    }
    if (!recruitmentCpf) {
      notify('Informe o CPF', { variant: 'warning' })
      return false
    }
    if (services.mask.unMaskCpf(recruitmentCpf).length < 11) {
      notify('Informe um CPF válido', { variant: 'warning' })
      return false
    }

    if(!cpf.isValid(services.mask.unMaskCpf(recruitmentCpf))) {
      notify('Informe um CPF válido', { variant: 'warning' })
      return false
    }

    const sameDocument = employees.filter(item => services.mask.unMaskCpf(item.cpf) === services.mask.unMaskCpf(recruitmentCpf));
    if (sameDocument.length) {
      notify('Já foi incluido um funcionário com este CPF', { variant: 'warning' })
      return false
    }

    if(ticketDataId) {
      // tela de edição
      try {
        const res = await trackPromise(services.api.addEmployee(recruitmentNome, services.mask.unMaskCpf(recruitmentCpf), ticketDataId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível adicionar o funcionário'
          )
        }
        add(services.mask.unMaskCpf(recruitmentCpf), recruitmentNome, res.data.id)
        resetModal()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      add(services.mask.unMaskCpf(recruitmentCpf), recruitmentNome, 0, indexLocal, indexFuncao)
      resetModal()
    }
  }

  const handleRemove = async (index: number) => {
    if(ticketDataId) {
      try {
        const res = await trackPromise(services.api.deleteEmployee(employees[index].id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível remover o beneficio'
          )
        }
        remove(index)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      remove(index, indexLocal, indexFuncao)
    }
  }
  
  useEffect(() => {
    if (!ticketStatus) {
      setBlockEdicao(false)
      return
    }
    if(
      (
        userRoles.includes('ticket_res_update') ||
        userRoles.includes('ticket_res_update_any')
      ) &&
      (ticketStatus === 'IN_PROGRESS' || ticketStatus === 'IN_REVISION')
    ) {
      setBlockEdicao(false)
      return
    }
    setBlockEdicao(true)
  }, [ticketStatus])

  return (
    <>
      <div className="flex gap-10 justify-between items-center mb-10" style={{borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px'}}>
        <span>
        Funcionarios a serem substituidos</span>
        {quantidade && 
          <span style={{color: `${employees?.length === Number(quantidade) ? 'green' : 'red'}`, fontWeight: 'bold'}}>
            {employees?.length}/{quantidade}
          </span>
        }
      </div>

      <div className='table-container'>
        <table className='table_styled'>
          <thead>
            <tr>
              <th>
                <div>
                  <span className="th-title">CPF</span>
                </div>
              </th>
              <th>
                <div>
                  <span className="th-title">Nome</span>
                </div>
              </th>
              {
                !edicaoMode &&
                <th>
                  <div className='flex justify-end'>
                    {Number(quantidade) > 0 && Number(quantidade) > employees?.length &&
                      <Tooltip title="Adicionar Funcionário">
                        <span>
                          <IconButton
                            style={{ color: 'white', marginRight: '6px',  borderRadius: '4px'}}
                            onClick={() => setModalAddRecruitment(true)}
                            edge="end"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    }
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {employees?.length === 0 ? 
              <tr><td colSpan={!edicaoMode ? 3 : 2}><div className='flex justify-center'>Não ha funcionarios</div></td></tr> : ''
            }
            {
              employees?.map((employee, index) => (
                <tr key={index}>
                  <td><div>{services.mask.cpf(employee.cpf)}</div></td>
                  <td><div>{employee.fullName}</div></td>
                  {
                    !edicaoMode &&
                    <td>
                      <div className='flex justify-end'>
                        <Tooltip title="Excluir funcionário">
                          <span>
                            <IconButton
                              style={{backgroundColor: 'red', color: 'white', marginRight: '0px',  borderRadius: '4px'}}
                              onClick={() => handleRemove(index)}
                              edge="end"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      {/* modal add recrutador */}
      <Modal
        size='sm'
        open={modalAddRecruitment}
        close={() => setModalAddRecruitment(false)}
        titulo={'Adicionar Funcionário'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size='small'
              label="Nome"
              inputProps={{ maxLength: 120 }}
              value={recruitmentNome}
              onChange={e => 
                setRecruitmentNome(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              fullWidth
              required
              size='small'
              label="CPF"
              inputProps={{ maxLength: 120 }}
              value={recruitmentCpf ? services.mask.cpf(recruitmentCpf) : ''}
              onChange={e => 
                setRecruitmentCpf(services.mask.unMaskCpf(e.target.value))
              }
            />
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size='small'
              color="inherit"
              onClick={() => setModalAddRecruitment(false)}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size='small'
              color="secondary" 
              disabled={promiseInProgress}
              onClick={adicionar}
            >
              adicionar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default Recrutadores
