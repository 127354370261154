import { useState, useCallback, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'

import {
  Grid,
} from '@mui/material'
import { PageTitle } from '../../../components/PageElements'

import { useNotification } from '../../../hooks/useNotification.js'
import { useAuth } from '../../../hooks/useAuth'
import { IBusca, IClientRecruter } from '../../../interfaces/index'

import TabelaUsuariosAll from './TabelaUsuariosAll'
import services from '../../../services/index.js'
import Tabs from '../../../components/Tabs'

import '../style.scss'

function ClientesAll() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [abaActive, setAbaActive] = useState(0)
  const [tabsList, setTabsList] = useState<any[] | []>([])

  const [step, setStep] = useState(0)

  const [usuarios, setUsuarios] = useState<IClientRecruter[] | []>([])
  const [usuariosResponsaveis, setUsuariosResponsaveis] = useState<IClientRecruter[] | []>([])

  const [objBuscaUsuarios, setObjBuscaUsuarios] = useState<any>(
    [
      {
        label: 'ID do usuário',
        value: '',
        searchRef: 'id',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'fullname',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'E-mail',
        value: '',
        searchRef: 'email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Adicionado em',
        value: '',
        searchRef: 'createdAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Empresa',
        value: '',
        searchRef: 'company',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [objBuscaUsuariosRes, setObjBuscaUsuariosRes] = useState<any>(
    [
      {
        label: 'ID do usuário',
        value: '',
        searchRef: 'id',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'fullname',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'E-mail',
        value: '',
        searchRef: 'email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Adicionado em',
        value: '',
        searchRef: 'createdAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Empresa',
        value: '',
        searchRef: 'company',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [currentPageUsers, setCurrentPageUsers] = useState(1)
  const [totalPagesUsers, setTotalPagesUsers] = useState(1)
  const [pageSizeUsers, setPageSizeUsers] = useState(20)
  const [totalCountUsers, setTotalCountUsers] = useState(0)

  const [currentPageResponsavel, setCurrentPageResponsavel] = useState(1)
  const [totalPagesResponsavel, setTotalPagesResponsavel] = useState(1)
  const [pageSizeResponsavel, setPageSizeResponsavel] = useState(20)
  const [totalCountResponsavel, setTotalCountResponsavel] = useState(0)

  //////////////////////////////////////////////////////////////////////////////////

  const getUsuarios = useCallback(async (_objBusca?: IBusca) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPageUsers
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSizeUsers

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBuscaUsuarios)

      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const res = await trackPromise(services.api.getRecruitmentCustomer(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setUsuarios(res.data.data)
      setCurrentPageUsers(res.data.pageIndex)
      setPageSizeUsers(res.data.pageSize)
      setTotalPagesUsers(res.data.totalPages)
      setTotalCountUsers(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaginationUsers = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getUsuarios({page: newPage,  pageSize:itensPerPage, busca: objBuscaUsuarios })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objBuscaUsuarios])

  const handleBuscaUsers = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getUsuarios({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //////////////////////////////////////////////////////////////////////////////////

  const getUsuariosReponsible = useCallback(async (_objBusca?: IBusca) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPageResponsavel
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSizeResponsavel

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBuscaUsuariosRes)
     
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const resR = await trackPromise(services.api.getRecruitmentUsers(paramns, pagina, tamanhoPagina))
      if (resR.fail) {
        throw new Error(
          resR.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setUsuariosResponsaveis(resR.data.data)
      setCurrentPageResponsavel(resR.data.pageIndex)
      setPageSizeResponsavel(resR.data.pageSize)
      setTotalPagesResponsavel(resR.data.totalPages)
      setTotalCountResponsavel(resR.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaginationUsersReponsible = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getUsuariosReponsible({page: newPage, pageSize: itensPerPage, busca: busca })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBuscaUsersReponsible = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getUsuariosReponsible({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getUsuarios(objBuscaUsuarios)
    getUsuariosReponsible(objBuscaUsuariosRes)
  }, [])

  useEffect(() => {
    let itemsTab = [];
    if (
      userRoles.includes("recruitermaps_read") ||
      userRoles.includes("recruitermaps_read_any")
    ) {
      itemsTab.push({id: 0, label: 'Atendentes'})
    }
    if (
      userRoles.includes("crusermap_read") ||
      userRoles.includes("crusermap_read_any")
    ) {
      itemsTab.push({id: 1, label: 'Clientes'})
    }
    if (itemsTab.length) {
      setAbaActive(itemsTab[0].id)
    }
    setTabsList(itemsTab)
  }, [])

  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
          <PageTitle subText={''}>
            <div> Clientes </div>
          </PageTitle>

          <Grid container justifyContent="center" spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <Tabs tabs={tabsList} active={abaActive} onChange={setAbaActive} disabled={step===1} />
              <div className='panel'>
                <div className='panel-body'>
                  {
                    (
                      userRoles.includes("recruitermaps_read") ||
                      userRoles.includes("recruitermaps_read_any")
                    ) &&
                    abaActive === 0 &&
                    <TabelaUsuariosAll 
                      titleTable={''}
                      type={'UserReponsavel'}
                      mainList={usuariosResponsaveis} 
                      handlePagination={handlePaginationUsersReponsible}
                      handleBusca={handleBuscaUsersReponsible}
                      currentPage={currentPageResponsavel}
                      totalPages={totalPagesResponsavel}
                      pageSize={pageSizeResponsavel}
                      totalCount={totalCountResponsavel}
                      objBusca={objBuscaUsuariosRes}
                      setObjBusca={setObjBuscaUsuariosRes}
                      step={step}
                      setStep={setStep}
                    />
                  }

                  {
                    (
                      userRoles.includes("crusermap_read") ||
                      userRoles.includes("crusermap_read_any")
                    ) &&
                    abaActive === 1 &&
                    <TabelaUsuariosAll 
                      titleTable={''}
                      type={'UserRegular'}
                      mainList={usuarios} 
                      handlePagination={handlePaginationUsers}
                      handleBusca={handleBuscaUsers}
                      currentPage={currentPageUsers}
                      totalPages={totalPagesUsers}
                      pageSize={pageSizeUsers}
                      totalCount={totalCountUsers}
                      objBusca={objBuscaUsuarios}
                      setObjBusca={setObjBuscaUsuarios}
                      step={step}
                      setStep={setStep}
                    />
                  }
                </div>
              </div>
            </Grid>
          </Grid>
      </div>
      
    </div>
  )
}

export default ClientesAll
