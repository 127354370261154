import { useState, Fragment } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import moment from 'moment'

import {
  Grid,
  Button,
  Drawer,
  IconButton,
  TextField
} from '@mui/material'

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';

import { useNotification } from '../../../hooks/useNotification.js'
import ChatItem from '../../../components/Chat'
import services from '../../../services/index.js'
import { useAuth } from '../../../hooks/useAuth'

interface IMensagens {
  chamadoId: number | undefined
  status: string | undefined
}

function Mensagens(props: IMensagens) {
  const { chamadoId, status } = props;
  const { promiseInProgress } = usePromiseTracker();
  const { notify } = useNotification()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [messageTypeText, setMessageTypeText] = useState<boolean>(true)
  const [newMessage, setNewMessage] = useState<string>('')
  const [mensagensChamado, setMensagensChamado] = useState<any>(null)

  const [drawerMensagens, setDrawerMensagens] = useState(false);
  
  const [formatosPermitido] = useState('.jpeg, .csv, .png, .doc, .gif, .pdf')
  const [tamanhoMaximo] = useState(3 * 1000000)

  const [returnFormat, setReturnFormat] = useState('');
  const [returnName, setReturnName] = useState('');
  const [file, setFile] = useState<any>(null);

  const toggleDrawer = () => {
      setDrawerMensagens(state => !state);
  };

  const handleopenMensagens = async () => {
    try {
      const res = await trackPromise(services.api.getChamadoMensagens(chamadoId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar mensagens'
        )
      }
      setMensagensChamado(res.data)
      setDrawerMensagens(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const enviarMensagem = async () => {
    if (messageTypeText) {
      enviarMsg()
    } else {
      enviarFile()
    }
  }

  const enviarFile = async () => {
    //const fileInput = document.getElementById('mensagemInputFile').value;
    if (!file) {
      notify('Selecione um arquivo', { variant: 'warning' })
      return false
    }
    console.log('file', file)
    console.log('file.name', newMessage);
    try {
      const res = await trackPromise(services.api.sendfileurl(chamadoId, newMessage))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível enviar anexo'
        )
      }
      const newNamefile = `${res?.data?.fileName}`
      const newFile = new File([file], newNamefile);
      // file.name = res?.data?.fileName + file.name.substr(file.name.length - 4)
      console.log("file newFile", newFile)
      const resUp = await trackPromise(services.api.sendfile(chamadoId, newFile, res?.data?.url))
      if (resUp.fail) {
        throw new Error(
          resUp.error || 'Não foi possível enviar anexo'
        )
      }

      const resReport = await trackPromise(services.api.uploadReport(chamadoId, newNamefile))
      if (resReport.fail) {
        throw new Error(
          resReport.error || 'Não foi possível enviar anexo'
        )
      }
      handleopenMensagens()
      resetFile()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const enviarMsg = async () => {
    if (!newMessage) {
      notify('Escreva uma mensagem válida', { variant: 'warning' })
      return false
    }
    try {
      const res = await trackPromise(services.api.postChamadoMensagem(chamadoId, newMessage))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível enviar mensagem'
        )
      }
      handleopenMensagens()
      // setMensagensChamado((prevState: any) => [...prevState, res.data])
      setNewMessage('')
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const resetFile = () => {
    setNewMessage(``)
    setFile(null)
    setMessageTypeText(true)
  }

  const getFile = (event: any) => {
    setNewMessage(``)
    if (!event.target.files.length) {
      setFile(null)
      setMessageTypeText(true)
      return false
    }

    const file = event.target.files[0]
    const fileName = file.name
    const fileSize = file.size
    const fileFormat = fileName.substr(fileName.length - 4)
    // const fileNameFormat = fileName.substr(0, fileName.length - 4)

    if (!formatosPermitido.includes(fileFormat)) {
      setFile(null)
      setMessageTypeText(true)
      notify(`Extensão de arquivo inválida, formatos aceitos: ${formatosPermitido}`, { variant: 'error' })
      return false
    }

    if (tamanhoMaximo < fileSize) {
      setFile(null)
      setMessageTypeText(true)
      notify(`Arquivo muito grande, limite permitido de 3Mb`, { variant: 'error' })
      return false
    }

    setMessageTypeText(false)
    setFile(file)
    setReturnFormat(fileFormat)
    setReturnName(fileName)
    setNewMessage(`${fileName}`)

  }
  
  return (
    <>
      {chamadoId && 
        <Button
          variant="contained"
          type="button"
          size='small'
          disabled={promiseInProgress}
          color="secondary" 
          onClick={handleopenMensagens}
        >
          Ver Mensagens
        </Button>
      }

      <Drawer
        anchor={'right'}
        open={drawerMensagens}
        onClose={toggleDrawer}
      >
        <div style={{maxWidth: '500px'}}>
          <div className='flex gap-10 items-center' style={{borderBottom: '2px solid $cor-primary', paddingBottom: '10px', margin: '20px 20px 0 20px'}}>
            <span>
              <strong>Mensagens do Chamado</strong>
            </span>
            <IconButton
              aria-label="close"
              onClick={toggleDrawer}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <div className='chamados_box m-20'>
            <Grid container spacing={{ xs: 3 }}>
              <Grid item xs={12}>
                {!mensagensChamado &&
                  <div className='justify-center' style={{textAlign: 'center', padding: '12px 20px', background:'#f4f4f4', borderRadius: '10px' }}>
                    Loadding...
                  </div>
                }
                {mensagensChamado?.length === 0 && 
                  <div className='justify-center' style={{textAlign: 'center', padding: '12px 20px', background:'#f4f4f4', borderRadius: '10px' }}>
                    Não ha nenhuma mensagem no momento
                  </div>
                }
                {
                  mensagensChamado?.map((mensagem: any) => (
                    <Fragment key={mensagem.id}>
                      <ChatItem 
                        msg={mensagem.messageData} ownerName={mensagem.user?.name}
                        ownerId={mensagem.user?.id}
                        id={mensagem.id}
                        date={moment(mensagem.createdAt).format('DD/MM/YYYY - HH:mm')} 
                        messageType={mensagem.messageType}
                      />
                    </Fragment>
                  ))
                }
              </Grid>
              {
                (
                  userRoles.includes("ticket_messages_create") ||
                  userRoles.includes("ticket_messages_create_any") 
                ) && 
                <>
                  {
                    status !== 'FINISHIED' && status !== 'CANCELED' &&
                    <>
                      <Grid item xs={12}>
                        <label className="labelAllis">
                          <span>Mensagem</span>
                        </label>
                        <TextField
                          InputProps={{
                            startAdornment:
                            <>
                              {
                                messageTypeText ? 
                                <></> :
                                <CancelIcon onClick={resetFile} />
                              }
                            </>,
                          }}
                          fullWidth
                          required
                          multiline
                          maxRows={4}
                          size='small'
                          inputProps={{ maxLength: 300 }}
                          value={newMessage}
                          disabled={!messageTypeText}
                          onChange={e => 
                            setNewMessage(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="flex justify-end gap-6">
                        <div className='flex'>
                          <label
                            htmlFor='mensagemInputFile'
                            id={`mensagemInputFile_label`}
                            className="file"
                            style={{
                              "background": "$cor-primary",
                              "color": "white",
                              "padding": "5px 10px",
                              "borderRadius": "4px",
                              "fontSize": "14px",
                              "fontWeight": "bold",
                              "display": "flex"
                            }}
                          >
                            <AttachFileIcon style={{fontSize: "16px"}} /> Anexar arquivo
                          </label>
                          <input
                            style={{display:'none'}}
                            id='mensagemInputFile'
                            type="file"
                            accept={formatosPermitido}
                            onChange={getFile}
                          />
                        </div>
                        <Button
                          variant="contained"
                          type="button"
                          size='small'
                          color="secondary"
                          onClick={enviarMensagem}
                          disabled={promiseInProgress}
                        >
                          Enviar Mensagem
                        </Button>
                      </Grid>
                    </>
                  }
                </>
              }
            </Grid>
          </div>
        </div>
      </Drawer>

    </>
  )
}

export default Mensagens
