import React, { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  Grid,
  TextField,
  Button,
  Tooltip,
  MenuItem,
  FormControl,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Select
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { PageTitle } from '../../components/PageElements'
import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import SubMenuAdmin from '../../components/SubMenuAdmin/SubMenuEnvironments'
import { useAuth } from '../../hooks/useAuth'
import { IDashboardGroup, IEnvironment, IDashboard, IBusca } from '../../interfaces/index'
import TabelaDashboards from './TabelaDashboards'
import BuscaVisualizadoresNew from './BuscaVisualizadoresNew'

import services from '../../services/index.js'

import './style.scss'

enum EditMode {
  novo,
  edicao
}

function Funcionalidades() {
  const { promiseInProgress } = usePromiseTracker();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [modalDash, setModalDash] = useState(false)
  const [modalNovoEmpresa, setModalNovoEmpresa] = useState(false)
  const [empresas, setEmpresas] = useState<IEnvironment[] | []>([])
  const [dashboards, setDashboards] = useState<IDashboard[] | []>([])
  const [dashActive, setDashActive] = useState<IDashboard>(
    {
      name: "",
      order: null,
      autoRefreshTime: null,
      reportPath: '',
      enableFilters: false,
      enableNavigationBar: false,
      active: false,
    }
  )
  const [nomeEmpresa, setNomeEmpresa] = useState('')
  const [empresaMode, setEmpresaMode] = useState<EditMode>(EditMode.novo)
  const [idEmpresaEdicao, setIdEmpresaEdicao] = useState<any>()
  const [nameEmpresaAtiva, setNameEmpresaAtiva] = useState<string>('')
  const [idEmpresaAtiva, setIdEmpresaAtiva] = useState<any>()
  const [modalRemoverEmpresa, setModalRemoverEmpresa] = useState<boolean>(false)
  const [dashMode, setDashMode] = useState<EditMode>(EditMode.novo)

  const [dashboardGroups, setDashboardGroups] = useState<IDashboardGroup[] | []>([])
  const [modalDashGroup, setModalDashGroup] = useState<boolean>(false)
  const [modalDashGroupExcluir, setModalDashGroupExcluir] = useState<boolean>(false)

  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const [dashDetalheTeams, setDashDetalheTeams]  = useState<any>([])
  const [dashDetalheUsers, setDashDetalheUsers]  = useState<any>([])
  const [tipoBuscaVinculoDash, setTipoBuscaVinculoDash]  = useState<string>('user')
  const [podeAtivarDesativarEditar, setPodeAtivarDesativarEditar] = useState<boolean>(false);

  const [objBusca, setObjBusca] = useState<any>(
    [
      {
        label: 'Nome',
        value: '',
        searchRef: 'name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Status',
        value: '',
        searchRef: 'active',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Ativo'},
          {id: 'false', descrition: 'Inativo'},
        ],
        type: 'string'
      },
      {
        label: 'Ambientes',
        value: '',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Filtros',
        value: '',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const getEmpresas = useCallback(async () => {
    try {
      let res: any
      if (userRoles.includes("bi_environments_read_any")) {
        res = await trackPromise(services.api.getEnvironmentsAll())
      } else {
        res = await trackPromise(services.api.environmentsRelatedAll())
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Empresas'
        )
      }

      setEmpresas(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getDashboards = useCallback(async (_objBusca?: IBusca) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize
      
      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''
      
      let res = await trackPromise(services.api.getDashboardAll(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Dashboards'
        )
      }
      setDashboards(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDashByEmpresa = useCallback(async (_objBusca?: IBusca, idEmpresa?: number) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize
      
      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''
      paramns.filterBy = 'DashboardGroups@Group@EnvironmentId'
      paramns.filterValue = idEmpresa

      let res = await trackPromise(services.api.getDashboardAll(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Dashboards dessa empresa'
        )
      }
      setIdEmpresaAtiva(idEmpresa)
      setDashboards(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number, idParent?: number) => {
    if (idParent) {
      getDashByEmpresa({page: newPage, pageSize: itensPerPage, busca: objBusca}, idParent)
    } else {
      getDashboards({page: newPage, pageSize: itensPerPage, busca: objBusca})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objBusca])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean, idParent?: number) => {
    if (idParent) {
      getDashByEmpresa({page:1,  pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc}, idParent)
    } else {
      getDashboards({page:1,  pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewDash = useCallback(async () => {
    setDashActive(
      {
        name: "",
        order: null,
        autoRefreshTime: null,
        reportPath: '',
        enableFilters: false,
        enableNavigationBar: false,
        active: false
      }
    )
    setDashMode(EditMode.novo)
    setDashDetalheTeams([])
    setModalDash(true)
  }, [])

  const handleNewEmpresa = useCallback(async () => {
    setNomeEmpresa('')
    setIdEmpresaEdicao('')
    setEmpresaMode(EditMode.novo)
    setModalNovoEmpresa(true)
  },[])

  const handleSelectEmpresa = useCallback(async (empresa: IEnvironment, updateTimes?: boolean | undefined) => {
    setNameEmpresaAtiva(empresa.name)
    if (empresa?.id) {
      getDashByEmpresa({}, empresa.id)
      setIdEmpresaAtiva(empresa.id)
    } else {
      setIdEmpresaAtiva(0)
      getDashboards()
    }
    if (updateTimes) {
      getEmpresas()
    }
  },[getDashByEmpresa, getEmpresas, getDashboards])


  const criarEmpresa = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()
    
    if (empresaMode === EditMode.edicao) {
      try {
        if (!idEmpresaEdicao) {
          throw new Error(
            'Não foi possível Editar a Empresa, id não localizado'
          )
        }
        const res = await trackPromise(services.api.editarEnvironment(nomeEmpresa, idEmpresaEdicao))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Editar a Empresa'
          )
        }
        notify('Empresa Alterada com Sucesso', { variant: 'success' })
        setModalNovoEmpresa(false)
        setNomeEmpresa('')
        setIdEmpresaEdicao('')
        setEmpresaMode(EditMode.novo)
        getEmpresas()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.criarEnvironment(nomeEmpresa))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Criar Empresa'
          )
        }
        notify('Empresa Criada com Sucesso', { variant: 'success' })
        setModalNovoEmpresa(false)
        setNomeEmpresa('')
        setIdEmpresaEdicao('')
        getEmpresas()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
  }
  
  const editEmpresa = useCallback(async (empresa: IEnvironment) => {
    setModalNovoEmpresa(true)
    setNomeEmpresa(empresa.name)
    setIdEmpresaEdicao(empresa.id)
    setEmpresaMode(EditMode.edicao)
  }, [])

  const removeEmpresa = useCallback(async (empresa: IEnvironment) => {
    setModalRemoverEmpresa(true)
    setNomeEmpresa(empresa.name)
    setIdEmpresaEdicao(empresa.id)
  }, [])

  const removerEmpresa = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (!idEmpresaEdicao) {
        throw new Error(
          'Id não localizado'
        )
      }
      const res = await trackPromise(services.api.deleteEnvironment(idEmpresaEdicao))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível Deletar a Empresa'
        )
      }
      notify('Empresa Deletada com Sucesso', { variant: 'success' })
      setModalRemoverEmpresa(false)
      setIdEmpresaEdicao('')
      getEmpresas()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleReloadDashboards = async () => {
    if (idEmpresaAtiva) {
      getDashByEmpresa({}, idEmpresaAtiva)
    } else {
      getDashboards()
    }
    getEmpresas()
  }

  useEffect(() => {
    getEmpresas()
    getDashboards()
  }, [getEmpresas, getDashboards])

  useEffect(() => {
    const getPermissaoAtivar = async () => {
      try {
        const res:any = await trackPromise(services.api.isadmin(idEmpresaAtiva))
        if (res.fail) {
          throw new Error(res.error || 'Não foi possível consultar permissão empresa')
        }
        setPodeAtivarDesativarEditar(true)
      } catch (error: any) {
        setPodeAtivarDesativarEditar(false)
      }
    }
    if (idEmpresaAtiva && userRoles.includes("bi_environments_read")) {
      getPermissaoAtivar()
    } else if (!idEmpresaAtiva && userRoles.includes("bi_environments_read")) {
      setPodeAtivarDesativarEditar(false)
    } else if (userRoles.includes("bi_environments_read_any")) {
      setPodeAtivarDesativarEditar(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEmpresaAtiva])


  const getDetalheDash = useCallback(async (dashId: any) => {
    try {
      let res: any
      if (userRoles.includes("bi_dashboards_read_any")) {
        res = await trackPromise(services.api.getDetalheDash(dashId))
      } else {
        res = await trackPromise(services.api.getDetalheDashRelated(dashId))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Dashboard'
        )
      }
      if (res.data) {
        setDashDetalheTeams(res.data.dashboardTeams)
        setDashDetalheUsers(res.data.dashboardUsers)
      } else {
        setDashDetalheTeams([])
        setDashDetalheUsers([])
      }
      setDashMode(EditMode.edicao)
      setModalDash(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const vincularEntidadeAoDash = useCallback(async (entidade: any) => {
    try {
      // valida se já tem vinculo
      if (tipoBuscaVinculoDash === 'user') {
        let hasUser = dashDetalheUsers.filter((item:any) => item.id === entidade.id)
        if (hasUser.length) {
          notify('Usuário já vinculado', { variant: 'warning' })
          return
        }
      } else {
        let hasEquipe = dashDetalheTeams.filter((item:any) => item.id === entidade.id)
        if (hasEquipe.length) {
          notify('Equipe já vinculado', { variant: 'warning' })
          return
        }
      }

      // cria novo vinculo
      let res: any
      if (tipoBuscaVinculoDash === 'user') {
        res = await trackPromise(services.api.vincularUserDash(dashActive.id, entidade.id))
      } else {
        res = await trackPromise(services.api.vincularEquipeDash(dashActive.id, entidade.id))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular item ao Dashboard'
        )
      }

      // atualiza listagem na tela
      if (tipoBuscaVinculoDash === 'user') {
        notify('Usuário vinculado sucesso', { variant: 'success' })
        setDashDetalheUsers((prevState:any) => [...prevState, {id: entidade.id, name: entidade.name}])
      } else {
        notify('Equipe vinculada sucesso', { variant: 'success' })
        setDashDetalheTeams((prevState:any)  => [...prevState, {id: entidade.id, name: entidade.name}])
      }

    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashActive, tipoBuscaVinculoDash, dashDetalheTeams, dashDetalheUsers])

  const desvincularEquipe = async (id: any, index:number) => {
    try {
      const res = await trackPromise(services.api.desvincularEquipeDash(dashActive.id, id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi desvincular Equipe'
        )
      }
      notify('Equipe desvinculada', { variant: 'success' })

      setDashDetalheTeams((prevState:any)  => [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ])
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const desvincularUser = async (id: any, index:number) => {
    try {
      const res = await trackPromise(services.api.desvincularUserDash(dashActive.id, id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi desvincular Equipe'
        )
      }
      notify('Usuário desvinculado', { variant: 'success' })

      setDashDetalheUsers((prevState:any) => [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ])
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const updateUserAdminNaEmpresa = async (empresaEditada:any,) => {
    if (empresaEditada?.id) {
      setEmpresas((prev) =>
        prev.map((userScore) => {
          if (userScore.id === empresaEditada.id) {
            return {
              ...userScore,
              environmentAdmins: empresaEditada.environmentAdmins
            };
          } else {
            return userScore;
          }
        })
      );
    }
  }

  const handleChangeFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDashActive(prevState => ({
      ...prevState,
      enableFilters: e.target.checked
    }))
  };

  const handleChangeNavigationBar = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDashActive(prevState => ({
      ...prevState,
      enableNavigationBar: e.target.checked
    }))
  };

  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDashActive(prevState => ({
      ...prevState,
      active: e.target.checked
    }))
  };

  return (
    <div className='wrapper-container-content flex flex-nowrap'>
      <SubMenuAdmin 
        mainList={empresas}
        actionEdit={editEmpresa} 
        actionRemove={removeEmpresa}
        actionSelect={handleSelectEmpresa}
        title={'Ambientes'} 
        subTitle={'AMBIENTES'} 
        actionAdd={handleNewEmpresa}
        idItemAtivo={idEmpresaAtiva}
        updateDashUsers={updateUserAdminNaEmpresa}
        classNameContainer={'float-left'}
      />
      <div className='wrapper-page-content wrapper-page-divided' id='wrapper-page-content'>
        <PageTitle subText={'Criar e editar dashboards'}>
          <div>{`Dashboards > ${nameEmpresaAtiva ? nameEmpresaAtiva : 'Todos'}`}</div>
        </PageTitle>
        
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={12} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                <TabelaDashboards 
                  mainList={dashboards} 
                  idParentAtivo={idEmpresaAtiva}
                  podeAtivarDesativarEditar={false}
                  newRegister={handleNewDash}
                  handleReloadDashboards={handleReloadDashboards}
                  handlePagination={handlePagination}
                  handleBusca={handleBusca}
                  objBusca={objBusca}
                  setObjBusca={setObjBusca}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  totalCount={totalCount}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>



      {/* modal empresa criar / edição */}
      <Modal
        size='sm'
        open={modalNovoEmpresa}
        close={() => setModalNovoEmpresa(false)}
        titulo={empresaMode === EditMode.edicao ? 'Edição do Ambiente' : 'Novo Ambiente'}
      >
        <form onSubmit={criarEmpresa}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nome</span>
                <Tooltip title="Nome da empresa">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                inputProps={{ maxLength: 120 }}
                value={nomeEmpresa}
                onChange={e => 
                  setNomeEmpresa(prevState => e.target.value)
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalNovoEmpresa(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal empresa remover */}
      <Modal
        size='sm'
        open={modalRemoverEmpresa}
        close={() => setModalRemoverEmpresa(false)}
        titulo={'Remover Empresa'}
      >
        <form onSubmit={removerEmpresa}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className='center'>
                Deseja mesmo remover os itens selecionados? <br />
                Esta ação não poderá ser desfeita
              </p>
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalRemoverEmpresa(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="error"
                disabled={promiseInProgress}
              >
                remover
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>



    </div>
  )
}

export default Funcionalidades