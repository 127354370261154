import { useState, useEffect } from 'react'

import {
  Grid,
} from '@mui/material'

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';

interface IResumoSolicitacao {
  refObj: string,
  list: any
}

function ResumoSolicitacao(props: IResumoSolicitacao) {
  const { 
    refObj, list
  } = props;

  const [locaisQuantidade, setLocaisQuantidade] = useState<number>(0)
  const [posicoesQuantidade, setPosicoesQuantidade] = useState<number>(0)
  const [funcoesQuantidade, setFuncoesQuantidade] = useState<number>(0)
  
  useEffect(() => {
    if (refObj === 'byLocal' && list) {
      setLocaisQuantidade(list.length)

      setFuncoesQuantidade(list.reduce((total:number, item:any) => {
        return total + item.funcoes.length
      }, 0))
      
      setPosicoesQuantidade(list.reduce((total:number, item:any) => {
        let quantidadePerFunction = item.funcoes.reduce((totalA:number, funcao:any) => {
          return totalA + (funcao?.quantity ? Number(funcao.quantity) : 0)
        }, 0)
        return total + quantidadePerFunction
      }, 0))
    } else {
      setLocaisQuantidade(list.reduce((total:number, item:any) => {
        return total + item.locais.length
      }, 0))

      setFuncoesQuantidade(list.length)

      setPosicoesQuantidade(list.reduce((total:number, item:any) => {
        let quantidadePerFunction = item.locais.reduce((totalA:number, funcao:any) => {
          return totalA + (funcao?.quantity ? Number(funcao.quantity) : 0)
        }, 0)
        return total + quantidadePerFunction
      }, 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refObj, list])

  return (
    <Grid container spacing={{ xs: 2 }} className='mb-30'>
      <Grid item xs={12}>
        <p className="m-0" style={{borderBottom: '4px solid rgb(228 228 228)', paddingBottom: '8px'}}>
          Resumo da Solicitação
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <div className='resumo_solicitacao_box'>
          <div><BusinessCenterIcon fontSize='large' /></div>
          <div>
            <div className='resumo_solicitacao_box_title'>{funcoesQuantidade} Funções</div>
            <div>Funções Cadastradas</div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <div className='resumo_solicitacao_box'>
          <div><PeopleIcon fontSize='large' /></div>
          <div>
            <div className='resumo_solicitacao_box_title'>{posicoesQuantidade} Posições</div>
            <div>Posições Cadastradas</div>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <div className='resumo_solicitacao_box'>
          <div><ApartmentIcon fontSize='large' /></div>
          <div>
            <div className='resumo_solicitacao_box_title'>{locaisQuantidade} Locais</div>
            <div>Locais Cadastrados</div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default ResumoSolicitacao
