import { useState, useEffect } from 'react'
import moment from 'moment'

import {
  Grid,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  Select
} from '@mui/material'

import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { useAuth } from '../../../hooks/useAuth'
import InputCurrency from '../../../components/InputMask/InputCurrency/InputCurrency'
import { IChamado, IRazaoContratacao } from '../../../interfaces/index'
import services from '../../../services/index.js'

import { appConfig } from '../../../configs/env-config.js'
const FastrhURL = appConfig.REACT_APP_FastrhURL

interface IDadosVaga {
  chamado: IChamado
  razoesContratacao: IRazaoContratacao[] | []
  tiposContratacao?: any[] | []
  setChamado: any
  edicaoMode?: boolean
  ticketStatus?: string
  indexFuncao?:number
  indexLocal?:number
  ocultarCamposDataQuantidade?: boolean
  escalasList?: any
  disabledSubstituicao: boolean
}

function DadosVaga(props: IDadosVaga) {
  const { 
    chamado, setChamado, razoesContratacao, tiposContratacao, 
    edicaoMode, ticketStatus, indexFuncao, indexLocal, disabledSubstituicao,
    ocultarCamposDataQuantidade, escalasList
  } = props;
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  //console.log('data', moment().add(7, 'days').format('YYYY-MM-DD')) // 2024-01-10
  const minDateInicial = moment().add(7, 'days').format('YYYY-MM-DD')

  const [blockEdicao, setBlockEdicao] = useState<boolean>(true)

  const handleSelectRazaoContratacao = async(razaoId: string | number) => {
    let isEmployeeReplace = false
    const razaoObjSelectd = razoesContratacao.filter(item => item.id === razaoId)
    if (razaoObjSelectd.length) {
      let contratoDescricao = razaoObjSelectd[0].type || ''
      if (contratoDescricao.toUpperCase().includes('SUBSTITUIÇÃO TRANSITÓRIA')) {
        isEmployeeReplace = true
      }
    }
    setChamado(indexLocal, indexFuncao, 'recruitmentDemandHiringReasonId', razaoId)
    setChamado(indexLocal, indexFuncao, 'isEmployeeReplace', isEmployeeReplace)
  }

  const handleSelectTipoContratacao = async(tipo: string) => {
    setChamado(indexLocal, indexFuncao, 'recruitmentDemandHiringReasonId', '')
    setChamado(indexLocal, indexFuncao, 'isEmployeeReplace', '')
    setChamado(indexLocal, indexFuncao, 'typeHiringReason', tipo)
  }

  const openVagaFastRH = (vagaId: any) => {
    window.open(`${FastrhURL}Vagas/Visualizar/${vagaId}`)
  }

  useEffect(() => {
    if (!ticketStatus) {
      setBlockEdicao(false)
      return
    }
    if(
      (
        userRoles.includes('ticket_res_update') ||
        userRoles.includes('ticket_res_update_any')
      ) &&
      (ticketStatus === 'IN_PROGRESS' || ticketStatus === 'IN_REVISION')
    ) {
      setBlockEdicao(false)
      return
    }
    setBlockEdicao(true)
  }, [ticketStatus])

  return (
    <Grid container spacing={{ xs: 3 }}>
      <Grid item xs={12}>
        <p className="m-0 mt-30">
          Dados da Função
        </p>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          required
          size='small'
          label="Função"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.functionName ? chamado.functionName : ''}
          onChange={e => 
            setChamado(indexLocal, indexFuncao, 'functionName', e.target.value)
          }
          disabled={edicaoMode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <InputCurrency
          index={''}
          id="remuneracao"
          label="Remuneração"
          value={chamado.remuneration ? chamado.remuneration : ''}
          change={(value: any) => 
            setChamado(indexLocal, indexFuncao, 'remuneration', value)
          }
          classInput={''}
          classContainer={''}
          disabled={edicaoMode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        {/* <TextField
          fullWidth
          required
          size='small'
          label="Escala"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.scale || ''}
          onChange={e => 
            setChamado(indexLocal, indexFuncao, 'scale', e.target.value)
          }
          disabled={blockEdicao}
        /> */}
        <FormControl>
          <InputLabel shrink>Escala</InputLabel>
          <Select
            fullWidth
            required
            notched
            size="small"
            label="Escala"
            value={chamado.scale || ''}
            disabled={edicaoMode}
            onChange={e => 
              setChamado(indexLocal, indexFuncao, 'scale', e.target.value)
            }
          >
            <MenuItem value={""} >
              Selecione
            </MenuItem>
            {
              escalasList?.map((tipo: any, index: number) => (
                <MenuItem value={tipo} key={index}>
                  {tipo}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          required
          size='small'
          label="Gestor Responsavel"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.customerManager ? chamado.customerManager : ''}
          onChange={e => 
            setChamado(indexLocal, indexFuncao, 'customerManager', e.target.value)
          }
          disabled={edicaoMode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FormControl>
          <InputLabel id="chamado_input_periculosidade-label">Periculosidade</InputLabel>
          <Select
            labelId="chamado_input_periculosidade-label"
            id="chamado_input_periculosidade"
            fullWidth
            required
            size="small"
            label="Periculosidade"
            value={chamado.dangerousness || false}
            onChange={e => {
              let value = e.target.value === 'true' ? true : false;
              if(value) {
                setChamado(indexLocal, indexFuncao, 'dangerousness', true)
                setChamado(indexLocal, indexFuncao, 'unhealthy', false)
                setChamado(indexLocal, indexFuncao, 'unhealthyRate', null)
              } else {
                setChamado(indexLocal, indexFuncao, 'dangerousness', false)
              }
            }}
            disabled={edicaoMode}
          >
            <MenuItem value="">Selecione</MenuItem>
            <MenuItem value="true">Sim</MenuItem>
            <MenuItem value="false">Não</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FormControl>
          <InputLabel id="chamado_input_insalubridade-label">Insalubridade</InputLabel>
          <Select
            labelId="chamado_input_insalubridade-label"
            id="chamado_input_insalubridade"
            fullWidth
            required
            size="small"
            label="Insalubridade"
            value={chamado.unhealthyRate ? chamado.unhealthyRate : chamado.unhealthy || false}
            onChange={e => {
              let value = e.target.value
              if(value === '20%' || value === '40%') {
                setChamado(indexLocal, indexFuncao, 'unhealthy', true)
                setChamado(indexLocal, indexFuncao, 'unhealthyRate', value)
                setChamado(indexLocal, indexFuncao, 'dangerousness', false)
              } else {
                setChamado(indexLocal, indexFuncao, 'unhealthy', false)
                setChamado(indexLocal, indexFuncao, 'unhealthyRate', null)
              }
            }}
            disabled={edicaoMode}
          >
            <MenuItem value="">Selecione</MenuItem>
            <MenuItem value="20%">20%</MenuItem>
            <MenuItem value="40%">40%</MenuItem>
            <MenuItem value="false">Não</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          required
          size='small'
          type="time"
          label="Horário trabalho inicial"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.jobStartHour ? chamado.jobStartHour : ''}
          onChange={e => 
            setChamado(indexLocal, indexFuncao, 'jobStartHour', e.target.value)
          }
          disabled={edicaoMode}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          required
          size='small'
          type="time"
          label="Horário trabalho final"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.jobFinishHour ? chamado.jobFinishHour : ''}
          onChange={e => 
            setChamado(indexLocal, indexFuncao, 'jobFinishHour', e.target.value)
          }
          disabled={edicaoMode}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FormControl>
          <InputLabel id="chamado_input_PCD-label">PCD</InputLabel>
          <Select
            labelId="chamado_input_PCD-label"
            id="chamado_input_PCD"
            fullWidth
            required
            size="small"
            label="PCD"
            value={chamado.pcd || false}
            onChange={e => {
              setChamado(indexLocal, indexFuncao, 'pcd', e.target.value  === 'true' ? true : false)
              setChamado(indexLocal, indexFuncao, 'pcdRestriction', null)
            }}
            disabled={edicaoMode}
          >
            <MenuItem value="" >
              Selecione
            </MenuItem>
            <MenuItem value="true" >
              Sim
            </MenuItem>
            <MenuItem value="false" >
              Não
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {chamado.pcd === true || chamado.pcd === 'true' ?
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
              fullWidth
              required
              size='small'
              label="Critério PCD"
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{ shrink: true }}
              value={chamado.pcdRestriction  || ''}
              onChange={e => 
                setChamado(indexLocal, indexFuncao, 'pcdRestriction', e.target.value)
              }
              disabled={edicaoMode}
            />
        </Grid> :
        ""
      }
      
      {!ocultarCamposDataQuantidade ?
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            required
            size='small'
            label="Quantidade de vagas"
            inputProps={{ maxLength: 5 }}
            InputLabelProps={{ shrink: true }}
            value={chamado.quantity || ''}
            onChange={e => 
              setChamado(indexLocal, indexFuncao, 'quantity', services.mask.number(e.target.value))
            }
            disabled={edicaoMode}
          />
        </Grid> : '' 
      }
      {edicaoMode && !userRoles.includes("ticket_res_create") &&
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            required
            size='small'
            className="primary"
            label="Cód da vaga no FastRH"
            inputProps={{ maxLength: 6 }}
            InputLabelProps={{ shrink: true }}
            value={chamado.fastJobId || ''}
            onChange={e => 
              setChamado(indexLocal, indexFuncao, 'fastJobId', services.mask.number(e.target.value))
            }
            disabled={blockEdicao}
            InputProps={{
              endAdornment: <>
                {
                  !userRoles.includes('ticket_res_create') ? 
                  <InputAdornment position="start" style={{marginRight: '0px'}}>
                    <IconButton
                      style={{padding: '2px', margin: '-8px', borderRadius: '4px'}}
                      className='btn-purple'
                      onClick={() => openVagaFastRH(chamado.fastJobId)}
                      disabled={!chamado.fastJobId}
                    >
                      <InsertLinkIcon />
                    </IconButton>
                  </InputAdornment>
                  : ''
                }
              </>
              ,
            }}
          />
        </Grid>
      }

      {!ocultarCamposDataQuantidade ?
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            required
            size='small'
            type='date'
            label="Data de início"
            inputProps={{ maxLength: 120, min: minDateInicial }}
            InputLabelProps={{ shrink: true }}
            value={chamado.startDate ? moment(chamado.startDate).format('YYYY-MM-DD') : ''}
            onChange={e => 
              setChamado(indexLocal, indexFuncao, 'startDate', e.target.value)
            }
            disabled={edicaoMode}
          />
        </Grid>: ''
      }
      
      {
        chamado.isTemporary ? 
        <>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              size='small'
              label="Duração em dias"
              inputProps={{ maxLength: 6 }}
              InputLabelProps={{ shrink: true }}
              value={chamado.duration || ''}
              onChange={e => 
                setChamado(indexLocal, indexFuncao, 'duration', services.mask.number(e.target.value))
              }
              disabled={edicaoMode}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink>Tipo do motivo</InputLabel>
              <Select
                fullWidth
                notched
                //required
                size="small"
                label="Tipo do motivo"
                value={chamado.typeHiringReason ? chamado.typeHiringReason : ''}
                onChange={e => handleSelectTipoContratacao(e.target.value)}
                disabled={edicaoMode}
              >
                <MenuItem value="" >
                  Selecione
                </MenuItem>
                {
                  tiposContratacao?.map(tipoContratacao => (
                    <MenuItem value={tipoContratacao} key={tipoContratacao} disabled={tipoContratacao === "Substituição Transitória" && disabledSubstituicao ? true : false}>
                      {tipoContratacao}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink>Motivo da contratação</InputLabel>
              <Select
                fullWidth
                notched
                required
                size="small"
                label="Motivo da contratação"
                value={chamado.recruitmentDemandHiringReasonId ? chamado.recruitmentDemandHiringReasonId : ''}
                onChange={e => handleSelectRazaoContratacao(e.target.value)}
                disabled={edicaoMode || !chamado.typeHiringReason}
              >
                <MenuItem value="" >
                  Selecione
                </MenuItem>
                {
                  razoesContratacao.filter(item => item.type === chamado.typeHiringReason).map(razaoContratacao => (
                    <MenuItem value={razaoContratacao.id} key={razaoContratacao.id} >
                      {razaoContratacao.description}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid> 
        </>
        
        : ''
      }

      
    </Grid>
  )
}

export default DadosVaga
