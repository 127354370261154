import axios from 'axios'
import Cookies from 'js-cookie'

import { appConfig } from '../../configs/env-config.js'

import * as storage from '../storage.js'
// import * as utils from '../utils.js'

const apiRoute = appConfig.REACT_APP_UrlApi

const CancelToken = axios.CancelToken
let cancel

const axiosInstance = axios.create({
  validateStatus: function (status) {
    return status !== 404 && status < 500  // Retorna verdadeiro para códigos de status 2xx
  },
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const token = Cookies.get('TradeTalentosTokenPAT')
    // Do something before request is sent
    let newConfig = {}
    if (config) {
      newConfig = config
    }
    if (newConfig.url.startsWith(apiRoute)) {
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`
      }
    }
    return newConfig
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
axiosInstance.interceptors.response.use(
  function (response) {
    let newResponse = {}
    if (response) {
      newResponse = response
    }
    return newResponse
  },
  function (error) {
    //const statusError = [403, 401]
    // if (statusError.includes(error.response.status)) {
    //   storage.clear()
    //   return
    // }
    return Promise.reject(error)
  }
)

// export const sendWithAxiosCancelPrev = async (url, method, payload) => {
//   if (cancel !== undefined) {
//     cancel()
//   }
//   try {
//     const result = await axios({
//       url,
//       method,
//       data: payload,
//       cancelToken: new CancelToken(function executor(c) {
//         cancel = c
//       })
//     })
//     if (result.status >= 300) {
//       return {
//         data: false,
//         fail: true,
//         status: result.status,
//         error: result.data.message
//       }
//     }
//     return { data: result.data, fail: null, status: result.status }
//   } catch (error) {
//     const errors = parseError(error)
//     return { data: false, fail: true, errors }
//   }
// }

export const sendWithAxios = async (url, method, payload) => {
  try {
    const result = await axiosInstance({
      url,
      method,
      data: payload
    })
    if (result.status === 400) {

      console.log(result)
      if(result.data.errors){
        return {
          data: false,
          fail: true,
          status: result.status,
          error: result.data.errors.toString()
        }
      }

      if(result.data){
        let message= ""
        Object.values(result.data).forEach(array => {
          array.forEach(item => {
            message += item + "||"
          });
        });

        return {
          data: false,
          fail: true,
          status: result.status,
          error: message
        }
      }

    }
    if (result.status === 401) {
      return {
        data: false,
        fail: true,
        status: result.status,
        error: result.data.message
      }
    }
    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        error: result.data.message
      }
    }
    return { data: result.data, fail: null, status: result.status }
  } catch (error) {
    const errors = parseError(error)
    const status = error?.response?.status
    if (status === 400) {
      return {
        data: false,
        fail: true,
        status: status,
        error: error?.response?.data ? JSON.stringify(error.response.data) : `Error: ${status} `
        //error: error?.response?.data ? error.response.data : `Error: ${status} `
      }
    }
    const errorString = error?.response?.data?.message
    return { data: false, fail: true, error: errorString ? errorString : errors, status: status }
  }
}

export const sendWithAxiosString = async (url, method, payload) => {
  try {
    const result = await axios({
      url,
      method,
      headers: { 'Content-type': 'application/json' },
      data: payload
    })
    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        error: result.data.message
      }
    }
    return { data: result.data, fail: null, status: result.status }
  } catch (error) {
    const errors = parseError(error)
    return { data: false, fail: true, error: errors }
  }
}
export const sendWithAxiosBlob = async (url, method, payload) => {
  try {
    // var formData = new FormData();
    // formData.append("file", payload);
    let result;
    if (method === 'POST') {
      result = await axios({
        url,
        method,
        data: payload, 
        headers: {
          'Content-Type': "application/octet-stream"
        }
      })
    } else {
      result = await axios({
        url,
        method,
        data: payload, 
        headers: {
          'Content-Type': "application/octet-stream"
        }
      })
    }
    
    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        error: result.data.message
      }
    }
    return { data: result.data, fail: null, status: result.status }
  } catch (error) {
    const errors = parseError(error)
    return { data: false, fail: true, error: errors }
  }
}

export const parseError = errors => {
  try {
    let error = ''
    const { response, isAxiosError } = errors
    const { data } = response
    
    if (data) {
      let msg = ''
      if (data.error) {
        msg = data.error
      } else if (data.errors) {
        msg = data.errors[Object.keys(data.errors)[0]]    
      }
      error = msg ? msg : ''
      return error
    } else if (isAxiosError) {
      throw new Error()
    }
    throw new Error()

  } catch (error) {
    return 'Falha na comunicação com o servidor'
  }
}
