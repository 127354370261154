import React, {  useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Cookies from "js-cookie";
import { debounce } from 'lodash'
import moment from 'moment'

import {
  Menu,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  Button,
  Tooltip,
  FormGroup,
  Switch,
  Radio,
  IconButton
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import TabelaVinculos from './TabelaVinculos'
import Modal from '../../../components/Modal'
import services from '../../../services'
import { Pagination, TabelaSearchColumn } from '../../../components/Table'
import { useAuth } from '../../../hooks/useAuth'
import { useNotification } from '../../../hooks/useNotification.js'

interface ITableaUsuario {
  mainList: any[] | [] | null | undefined
  type: string
  editRegistro?: any
  handlePagination: any
  handleBusca:any
  titleTable: string
  removeRegistro?: any
  currentPage: number
  totalPages: number
  pageSize: number
  totalCount:number
  setAsPrincipal?: any
  setAsSupervisor?: any 
  setAsManager?: any 
  setAsAddedbyjob?: any
  objBusca: any
  setObjBusca: any
  step: number
  setStep: any
}

const tablesVisibles = ["ID do usuário", "Nome", "E-mail", "Adicionado em", "Empresa", "Ações"];

function TabelaHeaderTh(props: any) {
  const { indexRef, visibleColumn, paramns, setSearchChange, ordeby, type } = props;
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
            <th className={paramns.label === 'Principal' && type === 'UserRegular' ? 'hide' : ''}>
              <div>
                <div>
                  {
                    paramns?.showOrderby &&
                    <div style={{display:'flex', flexDirection: 'column'}}>
                      <IconButton  
                        size="small" 
                        onClick={() => ordeby(false, paramns.searchRef)}
                        style={{padding:'2px'}}
                        disabled={promiseInProgress}
                      >
                        <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                      </IconButton>
                      <IconButton  
                        size="small" 
                        onClick={() => ordeby(true, paramns.searchRef)}
                        style={{padding:'2px'}}
                        disabled={promiseInProgress}
                      >
                        <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                      </IconButton>
                    </div>
                  }
                  <span className='th-title'>{paramns.label}</span>
                </div>
                {
                  paramns?.showSearch &&
                  <div>
                    <SearchIcon fontSize='small' className='tableSeachIccon' 
                      onClick={() => 
                        setSearchChange((prevState:any) => [
                          ...prevState.slice(0, indexRef),
                          {...prevState[indexRef], inputVisible: true},
                          ...prevState.slice(indexRef + 1),
                        ])
                      }
                    />
                  </div>
                }
              </div>
            </th>
        </>
      }
    </>
  )
}

function TabelaUsuariosAll(props: ITableaUsuario) {
  const { 
    mainList, handleBusca, pageSize, titleTable,  
    currentPage, totalPages, handlePagination, type, 
    totalCount, objBusca, setObjBusca,
    step, setStep
  } = props;

  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [modalVinculosUsuario, setModalVinculosUsuario] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>({})

  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])

  const [filterList, setFilterList] = useState<any>(null)
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('nome&&&')

  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null);
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel);
  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null);
  };

  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')

  //////////////////////////////////////////////////////////////////////////////////

  const [currentPageVin, setCurrentPageVin] = useState(1)
  const [totalPagesVin, setTotalPagesVin] = useState(1)
  const [pageSizeVin, setPageSizeVin] = useState(20)
  const [totalCountVin, setTotalCountVin] = useState(0)
  const [vinculos, setVinculos] = useState<any[] | []>([])
  const [objBuscaVin, setObjBuscaVin] = useState<any>(
    [
      {
        label: 'Grupo',
        value: '',
        searchRef: 'protheusCttSa1@xnGrup',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Servico',
        value: '',
        searchRef: 'protheusCttSa1@afkDescri',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Descricao do CR',
        value: '',
        searchRef: 'protheusCttSa1@desc01',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Principal',
        value: '',
        searchRef: 'recruiterMap@principal',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Criado em',
        value: '',
        searchRef: 'recruiterMap@createdAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Principal automatico',
        value: '',
        searchRef: 'recruiterMap@automaticPrincipal',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Gerente',
        value: '',
        searchRef: 'recruiterMap@manager',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Supervisor',
        value: '',
        searchRef: 'recruiterMap@supervisor',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Adicionado automaticamente',
        value: '',
        searchRef: 'recruiterMap@addedByJob',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const getVinculosUser = useCallback(async (_objBusca?: any, userIdAtivo?: number, ) => {
    try {
      const userId = userIdAtivo ? userIdAtivo : selectedItem.id
      const pagina = _objBusca?.page ? _objBusca?.page : currentPageVin
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSizeVin

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBuscaVin)
      
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      let resR
      if (type === 'UserReponsavel') {
        resR = await trackPromise(services.api.getVinculosUserRes(paramns, userId, pagina, tamanhoPagina))
      } else {
        resR = await trackPromise(services.api.getVinculosUser(paramns, userId, pagina, tamanhoPagina))
      }
      if (resR.fail) {
        throw new Error(
          resR.error || 'Não foi possível consultar vinculos do usuário'
        )
      }
      let formatData = []
      if (type === 'UserReponsavel') {
        formatData = resR.data.data
      } else {
        formatData = resR.data.data.map((item: any) => {
          let itemformat = {
            protheusCttSa1: item.protheusCttSa1,
            recruiterMap: item.customerUserMap
          }
          return itemformat
        })
      }
      setVinculos(formatData)
      setCurrentPageVin(resR.data.pageIndex)
      setPageSizeVin(resR.data.pageSize)
      setTotalPagesVin(resR.data.totalPages)
      setTotalCountVin(resR.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem.id])

  const handlePaginationUserVinculos = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getVinculosUser({page: newPage, pageSize: itensPerPage, busca: busca }, selectedItem.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedItem.id])

  const handleBuscaUserVinculos = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getVinculosUser({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc }, selectedItem.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem.id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca, pageSize)
  }, 800),  [pageSize])

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(objBusca, pageSize, column, direction)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item);
    // console.log(event.target.checked, item, indexItem, visibleColumnsControl)
    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState:any) => ([
        ...prevState,
        item
      ]
      ))
    } else {
      setVisibleColumnsControl((prevState:any) => ([
        ...prevState.slice(0, indexItem),
        ...prevState.slice(indexItem + 1)
      ]
      ))
    }
  };

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_Usuario2_${type}_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }

  const handleAccessUser = async (user: any) => {
    setSelectedItem(user)
    getVinculosUser(null, user.id)
    setStep(1)
    //setModalVinculosUsuario(true)
  }
  
  useEffect(() => {
    setFilterList(mainList?.map((item:any) => {
      item.selecionado = false
      return item
    }))
  }, [mainList])

  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_Usuario2_${type}_${user?.id}`);
      if (!tableCookieString) {
        Cookies.set(`Table_Usuario2_${type}_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  return (
    <>
      <div className='wrapper-page-content-interna'>
        {step === 0 &&
          <>
            <div className='flex items-center justify-between gap-10'>
              <div className='flex gap-10'><h3>{titleTable}</h3></div>

              <div className='flex items-center gap-10'>
                <div className='flex items-center gap-6' id={`ref_table_clientes2_${type}`}>
                  <Tooltip title="Visualização Colunas">
                    <IconButton 
                      className='btn-purple'
                      size="small" aria-label="upload picture" 
                      onClick={handleOpenModalVisibleColumns}
                      disabled={promiseInProgress}
                    >
                      <SettingsIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className='divider' style={{opacity: 0}}></div>
            <div className='table-container'>
              <table className='table_styled'>
                <thead>
                  <tr>
                    {
                      objBusca?.map((item: any, index: number) => (
                        <TabelaSearchColumn 
                          key={index}
                          visibleColumn={visibleColumns.includes(item.label)} 
                          indexRef={index}
                          paramns={item}
                          objBusca={objBusca}
                          setSearchChange={setObjBusca}
                          handleChangeBusca={handleChangeBusca}
                        />
                      ))
                    }
                  </tr>
                  <tr>
                    {
                      objBusca?.map((item: any, index: number) => (
                        <TabelaHeaderTh 
                          key={index}
                          ordeby={ordeby}
                          visibleColumn={visibleColumns.includes(item.label)} 
                          indexRef={index}
                          paramns={item}
                          setSearchChange={setObjBusca}
                          type={type}
                        />
                      ))
                    }
                  </tr>
                  
                </thead>
                <tbody>
                  {!filterList && 
                    <tr><td colSpan={visibleColumns.length + 1}><div className='justify-center'>loading...</div></td></tr>
                  }
                  {filterList?.length === 0 && 
                    <tr><td colSpan={visibleColumns.length + 1}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                  }
                  {filterList?.map((item: any, index: number) => (
                    <tr key={item.id}>
                      {visibleColumns.includes('ID do usuário') &&  <td><div>{item.id}</div></td> }
                      {visibleColumns.includes('Nome') && <td><div>{item.name}</div></td> }
                      {visibleColumns.includes('E-mail') && <td><div>{item.email}</div></td>}
                      {visibleColumns.includes('Adicionado em') && <td><div>{moment(item.createdAt).format('DD/MM/YYYY - HH:mm')}</div></td>}
                      {visibleColumns.includes('Empresa') && <td><div>{item.company}</div></td>}
                      
                      <td>
                        <div className='justify-end flex gap-8 flex-nowrap'>
                          <Tooltip title="Visualizar vinculos">
                            <span>
                              <IconButton
                                size="small"
                                style={{backgroundColor: '$cor-primary', color: 'white', marginRight: '0px',  borderRadius: '4px'}}
                                onClick={() => handleAccessUser(item)} 
                                edge="end"
                                disabled={promiseInProgress}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages}
                pageSize={pageSize}
                handlePagination={handlePagination}
                colunaBusca={colunaBusca}
                search={search}
                itensLength={filterList?.length}
                totalCount={totalCount}
              />

            </div>
          </>
        }
        {step === 1 &&
          <>
            <div>
              <div className='flex items-center'>
                <IconButton 
                  size='small'
                  color="inherit"
                  onClick={() => setStep(0)}
                >
                  <ArrowBackIcon fontSize='small'/>
                </IconButton>
                <span>Usuário: {selectedItem.name} - Empresa: {selectedItem.company}  </span>
              </div>

              <TabelaVinculos 
                userId={selectedItem.id}
                titleTable={''}
                type={type}
                mainList={vinculos} 
                handlePagination={handlePaginationUserVinculos}
                handleBusca={handleBuscaUserVinculos}
                currentPage={currentPageVin}
                totalPages={totalPagesVin}
                pageSize={pageSizeVin}
                totalCount={totalCountVin}
                objBusca={objBuscaVin}
                setObjBusca={setObjBuscaVin}
              />
            </div>
          </>
        }
      </div>

      {/* modal usuário vinculos */}
      <Modal
        size='sm'
        open={modalVinculosUsuario}
        close={() => setModalVinculosUsuario(false)}
        titulo={'Vinculos Usuário'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <TabelaVinculos 
              titleTable={''}
              type={type}
              mainList={vinculos} 
              handlePagination={handlePaginationUserVinculos}
              handleBusca={handleBuscaUserVinculos}
              currentPage={currentPageVin}
              totalPages={totalPagesVin}
              pageSize={pageSizeVin}
              totalCount={totalCountVin}
              objBusca={objBuscaVin}
              setObjBusca={setObjBuscaVin}
            />
          </Grid>
        </Grid>
      </Modal>

      {/* show hide columns */}
      <Modal
        size='sm'
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
          <FormGroup>
            {tablesVisibles.filter(item => item !== 'Ações').map((item, index) => (
              <FormControlLabel 
                className={item === 'Principal' && type === 'UserRegular' ? 'hide' : ''}
                key={index} 
                control={
                  <Switch 
                    checked={visibleColumnsControl.includes(item)} 
                    onChange={(e) => handleChangeVisibleColumn(e, item)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } 
                label={item} 
              />
            ))}
          </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary" 
              type="button"
              size='small'
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>


      {/* menu opções busca user disponivel*/}
      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>
    </>
  )
}

export default TabelaUsuariosAll
