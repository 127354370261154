import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useEffect, useState } from 'react'
import { Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import './style.scss'
import { IGroupOverview } from '../../interfaces'
import { Button, IconButton } from '@mui/material'
import { Save } from '@mui/icons-material'
import moment from 'moment/moment'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

interface TableGroupsProps{
  data: IGroupOverview[]
  deleteGroup: any
  editGroup: any
  setGroupOrder: any
}

const TableGroups: React.FC<TableGroupsProps> = ({ data, deleteGroup, editGroup, setGroupOrder}) => {

  const [dataSource, setDataSource] = useState<IGroupOverview[]>([]);
  const [orderChanged, setOrderChanged] = useState<boolean>(false);

  useEffect(() => {
    // Example: Fetch initial data
    setDataSource(data);
  }, [data]);

  const columns: ColumnsType<IGroupOverview> = [
    {
      title: 'Nome',
      dataIndex: 'groupName',
    },
    {
      title: 'Dashboards',
      render: (_, record) => {
        if (record.dashboardsCount === 0) {
          return ("Nenhum dashboard");
        }
        else if(record.dashboardsCount === 1){
          return ("1 Dashboard")
        }
        else {
          return (`${record.dashboardsCount} Dashboards`)
        }
      },
    },
    {
      title: 'Criado em',
      render: (_, record) =>{
        return (moment(record?.createdAt).format('DD/MM/YYYY - HH:mm'))
      }
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <div style={{textAlign: 'right'}}>
          <IconButton
            className='btn-purple mr-6 content-end'
            disabled={false}
            onClick={() => editGroup(record.groupName, record.groupId)}
          >
            <SettingsIcon fontSize='small' />
          </IconButton>

          <Popconfirm title="Deseja deletar o grupo?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => deleteGroup(record.groupId)}>
            <IconButton className='btn-purple mr-6 content-end' disabled={false}>
              <DeleteForeverIcon fontSize='small' />
            </IconButton>
          </Popconfirm>
        </div>
      ),
    },
  ];


  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  async function SetNewOrder() {
    setOrderChanged(false)
    let list = dataSource.map(item => item.groupId);
    await setGroupOrder(list)
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev!.findIndex((i) => i.groupId === active.id);
        const overIndex = prev!.findIndex((i) => i.groupId === over?.id);
        setOrderChanged(true)
        return arrayMove(prev!, activeIndex, overIndex);
      });
    }
  };


  return (
    <div style={{textAlign: "right"}}>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.groupId)!}
          strategy={verticalListSortingStrategy}
        >
          <Table
            locale={{ emptyText:
                ' Nenhum grupo'
            }}
            components={{
              body: {
                row: Row,
              },
            }}
            pagination={false}
            rowKey="groupId"
            columns={columns}
            dataSource={dataSource}
          />
        </SortableContext>
      </DndContext>

      <div style={{textAlign: "right", marginTop: "10px"}}>
        <Button
          variant="contained"
          className='btn-purple mr-6 content-end mb-12'
          type="button"
          size='small'
          color="inherit"
          onClick={SetNewOrder}
          disabled={!orderChanged}
        >
          <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar ordem
        </Button>
      </div>

    </div>


  );
};

export default TableGroups;