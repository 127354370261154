import { appConfig } from '../../configs/env-config.js'
import { cpf } from '../mask.js';

import * as apiConfig from './apiConfig.js'

const apiRoute = appConfig.REACT_APP_UrlApi
const apiRouteAuth = appConfig.REACT_APP_UrlApiAuth
const pageSizeDefault = 20;

/////////////////////////////////////////////////////////////////////
// login
/////////////////////////////////////////////////////////////////////

export const login = async (login, password) => {
  const data = {
    username: login,
    rawPassword: password
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth`, 'POST', data)
}

export const loginWithMfa = async (login, password, mfaType, mfaCode) => {
  const data = {
    username: login,
    rawPassword: password,
    mfaType: mfaType,
    mfaCode: mfaCode
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/mfa`, 'POST', data)
}

export const getTotpConfiguration = async (login, password) => {
  const data = {
    username: login,
    rawPassword: password,
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/mfa/totp/requestconfig`, 'POST', data)
}

export const finishTotpConfiguration = async (login, password, key, code) => {
  const data = {
    username: login,
    rawPassword: password,
    key: key,
    totpCode: code
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/mfa/totp/setconfig`, 'POST', data)
}

export const requestMfaEmailCode = async (username) => {
  const data = {
    username: username
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/mfa/requestemail`, 'POST', data)
}

export const recoveryRequest = async (username) => {
  const data = {
    username
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/startrecovery`, 'POST', data)
}

export const changePasswordByEmail = async (email, secret, secretId, newPassword) => {
  const data = {
    username: email,
    secret: secret,
    secretId: secretId,
    newPassword: newPassword
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/recoverypassword`, 'POST', data)
}

export const changePasswordExpired = async (email, newPassword, oldSenha) => {
  const data = {
    username: email,
    rawPassword: oldSenha,
    newPassword: newPassword
  }
  return await apiConfig.sendWithAxios(`${apiRouteAuth}auth/resetpassword`, 'POST', data)
}

/////////////////////////////////////////////////////////////////////
// environment
/////////////////////////////////////////////////////////////////////

export const environmentsRelatedAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/all/related`,
    'GET'
  )
}

export const getEnvironmentById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${id}`,
    'GET'
  )
}

export const getEnvironmentsAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/all`,
    'GET'
  )
}

export const getAllEnvGroups = async (envId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${envId}/groups/all`,
    'GET'
  )
}

export const getEnvOverview = async (envId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${envId}/overview`,
    'GET'
  )
}

export const criarEnvironment = async (name) => {
  const data = {
    name: name
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments`,
    'POST',
    data
  )
}
export const editarEnvironment = async (name, id) => {
  const data = {
    name: name,
    id: id
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments`,
    'PUT',
    data
  )
}

  export const addEnvGroup = async (name, id) => {
    const data = {
      name: name,
      order: 0,
      environmentId: id
    }
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/group`,
    'POST',
    data
  )
}

  export const updateEnvGroup = async (name, id) => {
    const data = {
      name: name,
      id: id
    }
    return await apiConfig.sendWithAxios(
      `${apiRoute}environments/group`,
      'PUT',
      data
    )
  }

  export const deleteEnvGroup = async (id) => {
    return await apiConfig.sendWithAxios(
      `${apiRoute}environments/group/${id}`,
      'DELETE'
    )
  }

export const setGroupOrder = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/group/setorder`,
    'PUT',
    data
  )
}

export const addFilterToDashboard = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/filters`,
    'POST',
    data
  )
}

export const updateFilterToDashboard = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/filters`,
    'PUT',
    data
  )
}

export const addDashboardUsers = async (data, dashboardGroupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/user/dashboardgroup/${dashboardGroupId}`,
    'PUT',
    data
  )
}

export const addDashboardTeams = async (data, dashboardGroupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/team/dashboardgroup/${dashboardGroupId}`,
    'PUT',
    data
  )
}

export const removeFilterFromDashboard = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/filters/${id}`,
    'DELETE',
  )
}

export const removeUserFromDashboardGroup = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/user/${id}`,
    'DELETE',
  )
}

export const removeTeamFromDashboardGroup = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/team/${id}`,
    'DELETE',
  )
}


export const deleteEnvironment = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${id}`,
    'DELETE'
  )
}

export const getDashboardById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${id}`,
    'GET'
  )
}

export const getDashboardEnvOverview = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${id}/envoverview`,
    'GET'
  )
}

export const addDashboardGroup = async (dashboardId, groupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashboardId}/group/${groupId}`,
    'POST'
  )
}

export const updateDashboardGroupGroup = async (dashboardGroupId, groupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/dashboardgroupid/${dashboardGroupId}/group/${groupId}`,
    'PUT'
  )
}

export const updateDashboardGroupRule = async (dashboardGroupId, ruleId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/dashboardgroupid/${dashboardGroupId}/rule/${ruleId}`,
    'PUT'
  )
}

export const deleteDashboardGroup = async (dashboardId, groupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashboardId}/group/${groupId}`,
    'DELETE'
  )
}

export const getAllUsersFromDashGroupId = async (dashboardGroupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/user/dashboardgroup/${dashboardGroupId}`,
    'GET'
  )
}

export const getAllTeamsFromDashGroupId = async (dashboardGroupId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/access/team/dashboardgroup/${dashboardGroupId}`,
    'GET'
  )
}


export const getDashboardAll = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getDashboardAllRelated = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/all/related/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getDashboardByEmpresa = async (empresaId, page = 1, dashboardName, coluna, desc,  pageSize = pageSizeDefault) => {
  let query = `?page=${page}&pageSize=${pageSize}`
  if (dashboardName) {
    query += `&dashboardName=${dashboardName}`
  }
  if (coluna) {
    query += `&orderBy=${coluna}`
  }
  if (desc) {
    query += `&descending=${desc}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/all/environment/${empresaId}/paged${query}`,
    'GET'
  )
}

export const dashboardgroupsByEnvironment = async (idEnvironment) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardgroups/all/environment/${idEnvironment}`,
    'GET'
  )
}

export const dashboardgroupsRelatedByEnvironment = async (idEnvironment) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${idEnvironment}/related`,
    'GET'
  )
}

export const criarDashboard = async (data) => {
  if (data.autoRefreshTime === '') {
    data.autoRefreshTime = null
  }
  if (data.order === '') {
    data.order = null
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards`,
    'POST',
    data
  )
}

export const editDashboard = async (data) => {
  if (data.AutoRefreshTime === '') {
    data.AutoRefreshTime = null
  }
  if (data.Order === '' || undefined) {
    data.Order = null
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards`,
    'PUT',
    data
  )
}

export const setDashboardFilterOrder = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/filters/setorder`,
    'PUT',
    data
  )
}

export const getGrupoById = async (idGrupo) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardgroups/${idGrupo}`,
    'GET'
  )
}

export const criarGrupo = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardgroups`,
    'POST',
    data
  )
}

export const deleteGrupo = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardgroups/${id}`,
    'DELETE'
  )
}

export const editGrupo = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardgroups`,
    'PUT',
    data
  )
}

export const powerbiKey = async (dashboardId, envId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}powerbi/token/${dashboardId}/${envId}`,
    'GET'
  )
}



export const getTeamsAllRelated = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/all/related`,
    'GET'
  )
}

export const getTeamsAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/all`,
    'GET'
  )
}

export const getAllTeamsPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/all/paged/${query}${queryComplement}`,
    'GET'
  )
}



// export const getUsersAllAdmins = async () => {
//   return await apiConfig.sendWithAxios(
//     `${apiRoute}users/customeradmins/all`,
//     'GET'
//   )
// }

export const getUsersAll = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''

  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getUsersAllRelated = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''

  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/all/related${queryComplement}`,
    'GET'
  )
}

export const getUsersByEquipe = async (params, page = 1, idEquipe, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/${idEquipe}/members/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}


export const criarUsuario = async (user) => {
  let data = {
    name: user.name,
    username: user.username,
    email: user.email,
    company: user.company,
    internal: user.internal,
    role: user.role,
    password: user.password,
    expirationDays: user.expirationDays > 0 ? user.expirationDays : null,
    updatePasswordOnLogin: user.updatePasswordOnLogin
  }
  if (user.phone) {
    data.phone = user.phone
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users`,
    'POST',
    data
  )
}

export const editUsuario = async (user) => {
  let data = {
    id: user.id,
    name: user.name,
    username: user.username,
    email: user.email,
    company: user.company,
    internal: user.internal,
    role: user.role,
    expirationDays: user.expirationDays > 0 ? user.expirationDays : null,
    updatePasswordOnLogin: user.updatePasswordOnLogin
  }
  data.active = user.active
  if (user.phone) {
    data.phone = user.phone
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users`,
    'PUT',
    data
  )
}

export const editUsuarioMe = async (user, oldSenha) => {
  let data = {
    username: user.email,
    rawPassword: oldSenha,
    newPassword: user.password
  }
  return await apiConfig.sendWithAxios(
    `${apiRouteAuth}auth/resetpassword`,
    'POST',
    data
  )
}
export const editSenha = async (password) => {
  let data = {
    password,
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/me/newpassword`,
    'PUT',
    data
  )
}


export const criarEquipe = async (name) => {
  const data = {
    name: name
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams`,
    'POST',
    data
  )
}
export const vicularUsuarioComEquipe = async (userId, equipeId) => {
  const data = {
    userId: userId,
    teamId: equipeId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/members`,
    'POST',
    data
  )
}
export const vicularUsuarioComEquipeMult = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/members/multi`,
    'POST',
    data
  )
}
export const vicularUsuarioAdminComDash = async (userId, empresaId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${empresaId}/admins/${userId}`,
    'PUT'
  )
}
export const desvicularUsuarioAdminComDash = async (userId, empresaId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${empresaId}/admins/${userId}`,
    'DELETE'
  )
}
export const editarEquipe = async (name, id) => {
  const data = {
    name: name,
    id: id
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams`,
    'PUT',
    data
  )
}
export const deleteEquipe = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/${id}`,
    'DELETE'
  )
}

export const inativeUsers = async (listOfUsersIds) => {
  const data = listOfUsersIds
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/inactivate`,
    'PUT',
    data
  )
}

export const deleteUsersLote = async (
  usuarios
) => {
  const retExclusao = await Promise.all(
    usuarios.map(async user => {
      return apiConfig.sendWithAxios(`${apiRoute}users/${user.id}`, 'DELETE')
    })
  )
  const error = retExclusao.filter(x => x.fail)
  if (error.length) {
    return {
      fail: true,
      data: null,
      errors: { Message: usuarios.length > 1 ? 'Um ou mais usuários não puderam ser removidos' : 'Não foi possível remover o usuário' }
    }
  }
  const formatExclusao = {
    fail: false,
    data: retExclusao.map(x => {
      return x.data
    })
  }
  return formatExclusao
}

export const removerUsersEquipeLote = async (
  teamId,
  usuarios
) => {
  const retRemover = await Promise.all(
    usuarios.map(async user => {
      return apiConfig.sendWithAxios(`${apiRoute}teams/${teamId}/${user.id}`, 'DELETE')
    })
  )
  const error = retRemover.filter(x => x.fail)
  if (error.length) {
    return {
      fail: true,
      data: null,
      errors: { Message: usuarios.length > 1 ? 'Um ou mais usuários não puderam ser removidos' : 'Não foi possível remover o usuário' }
    }
  }
  const formatRemover = {
    fail: false,
    data: retRemover.map(x => {
      return x.data
    })
  }
  return formatRemover
}

export const ativeDashLote = async (
  dashboards
) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/activate`,
    'PUT',
    dashboards
  )
}

export const deleteDashboard = async (
  dashboardId
) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashboardId}`,
    'DELETE'
  )
}

export const dashEditOrder = async (
  dashId,
  newOrder
) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashId}/order/${newOrder}`,
    'PUT'
  )
}

export const inativeDashLote = async (
  dashboards
) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/inactivate`,
    'PUT',
    dashboards
  )
}

export const deleteDashboardsLote = async (
  dashboards
) => {
  const retExclusao = await Promise.all(
    dashboards.map(async user => {
      return apiConfig.sendWithAxios(`${apiRoute}dashboards/${user.id}`, 'DELETE')
    })
  )
  const error = retExclusao.filter(x => x.fail)
  if (error.length) {
    return {
      fail: true,
      data: null,
      errors: { Message: dashboards.length > 1 ? 'Um ou mais dashboards não puderam ser removidos' : 'Não foi possível remover o dashboards' }
    }
  }
  const formatExclusao = {
    fail: false,
    data: retExclusao.map(x => {
      return x.data
    })
  }
  return formatExclusao
}
export const isadmin = async (idEmpresa) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${idEmpresa}/isadmin/me`,
    'GET'
  )
}

export const getDetalheDash = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${id}`,
    'GET'
  )
}
export const getUserMe = async () => {
  return await apiConfig.sendWithAxios(
    // `${apiRouteAuth}users/me`,
    `${apiRoute}users/me`,
    'GET'
  )
}
export const getDetalheDashRelated = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/related/${id}`,
    'GET'
  )
}

export const vincularUserDash = async (dashId, userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashId}/users/${userId}`,
    'PUT'
  )
}

export const vincularEquipeDash = async (dashId, equipeId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashId}/teams/${equipeId}`,
    'PUT'
  )
}

export const desvincularUserDash = async (dashId, userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashId}/users/${userId}`,
    'DELETE'
  )
}

export const desvincularEquipeDash = async (dashId, equipeId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboards/${dashId}/teams/${equipeId}`,
    'DELETE'
  )
}

export const getIsEnvironmentOnRpaAutomatorList = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}rpaautomator/environment/${id}`,
    'GET'
  )
}

export const startRpaReprocess = async (dashboardId, year, month) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}rpaautomator/reprocess/dashboard/${dashboardId}/${year}/${month}`,
    'POST'
  )
}

export const dashboardCompaniesAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardcompanies/all`,
    'GET'
  )
}

/////////////////////////////////////////////////////////////////////
// Clientes
/////////////////////////////////////////////////////////////////////

export const getEmpresasCliente = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}protheus/crs/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getEmpresas = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/empresas/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getEmpresasMe = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}branchusermaps/all/me`,
    'GET'
  )
}

export const getLocaisById = async (cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}protheus/crs/recno/${cttId}`,
    'GET'
  )
}

export const getLocais = async (temporary) => {
  let query = ''
  if (temporary) {
    query = '?temporary=true'
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentCustomerUserMaps/all/me${query}`,
    'GET'
  )
}

export const tipoContrato = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentCustomerUserMaps/allcontracts/me`,
    'GET'
  )
}

export const getUnidades = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/empresas/filiais/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getRecruiters = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const multiMapRecruiters = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/multi`,
    'POST',
    data
  )
}

export const multiMapRecruitersUser = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/multi`,
    'POST',
    data
  )
}




export const getRecruitmentCustomerUser = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}


export const getVinculosUserRes = async (params, userId, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/user/${userId}/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}
export const getVinculosUser = async (params, userId, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/user/${userId}/all/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getRecruitmentUsers = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/allusers/res/recruiter/paged/${pagination}${queryComplement}`,
    'GET'
  )
}
export const getRecruitmentCustomer = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery, filterBy, filterValue} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/allusers/res/customer/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const incluirUserIntoUnidade = async (userId, cttId) => {
  const data = {
    userId: userId,
    cttId: cttId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps`,
    'POST',
    data
  )
}

export const incluirResponsavelIntoUnidade = async (userId, cttId, principal) => {
  const data = {
    userId: userId,
    cttId: cttId,
    principal: principal
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps`,
    'POST',
    data
  )
}

export const setUserAsResponsible = async (userId, cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/cttId/${cttId}/userId/${userId}/principal`,
    'PUT',
  )
}

export const setUserAsSupervisor = async (userId, cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/cttId/${cttId}/userId/${userId}/supervisor`,
    'PUT',
  )
}
export const setUserAsManager = async (userId, cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/cttId/${cttId}/userId/${userId}/manager`,
    'PUT',
  )
}
export const setUserAsAddedbyjob = async (userId, cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/cttId/${cttId}/userId/${userId}/principal`,
    'PUT',
  )
}

export const excluirUserIntoUnidade = async (mapId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentcustomerusermaps/mapId/${mapId}`,
    'DELETE'
  )
}

export const excluirResponsavelIntoUnidade = async (userId, cttId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitermaps/cttId/${cttId}/userid/${userId}`,
    'DELETE'
  )
}

// export const getUsersDisponiveis = async (params, page = 1, pageSize = pageSizeDefault) => {
//   const {search, orderByColuna, desc, filterBy, filterValue} = params
//   let query = `${page}/${pageSize}`
//   let queryComplement = ''
//   if (search) {
//     queryComplement += `${queryComplement ? '&' : '?'}${search}`
//   }
//   if (orderByColuna && !desc) {
//     queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
//   }
//   if (orderByColuna && desc) {
//     queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
//   }
//   if (filterBy && filterValue) {
//     queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
//   }
//   return await apiConfig.sendWithAxios(
//     `${apiRoute}recruitmentcustomerusermaps/allusers/paged/${query}${queryComplement}`,
//     'GET'
//   )
// }




/////////////////////////////////////////////////////////////////////
// Chamados
/////////////////////////////////////////////////////////////////////

export const getOverview = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/res/overview`,
    'GET'
  )
}

export const getOverviewRelated = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/res/relatedoverview`,
    'GET'
  )
}

export const getChamados = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getChamadosRes = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/all/res/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const getComptradeEstById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/id/${id}`,
    'GET'
  )
}

export const getComptradeChannels = async (type) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/attributes/all?type=${type}`,
    'GET'
  )
}

export const addComptradeChannel = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/attributes`,
    'POST', data
  )
}

export const refreshEstFederalData = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/id/${id}/refresh`,
    'PUT'
  )
}

export const aproveComptradeEst = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/id/${id}`,
    'PUT'
  )
}

export const updateComptradeEst = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est`,
    'PUT', data
  )
}

export const getComptradeEstPaged = async (page = 1, pageSize = pageSizeDefault, orderBy = "id", commonSearch, overviewFilter, desc = false) => {
  let params = ""
  let pagination = `${page}/${pageSize}`
  if(orderBy !== undefined){
    if(desc)
      params += "?orderBy=" + orderBy;
    else
      params += "?orderByDesc=" + orderBy;
  }
  if(commonSearch !== undefined){
    if(params !== ""){
      if(commonSearch !== "")
        params+= "&commonSearch=" + commonSearch
    }
  }
  if(overviewFilter !== undefined){
    if(params === "")
      params += "?" + overviewFilter
    else
      params += "&" + overviewFilter
  }

  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/paged/${pagination}${params}`,
    'GET'
  )
}


export const getDashboardRulesPaged = async (page = 1, pageSize = pageSizeDefault, orderBy = "id", commonSearch, desc = false) => {
  let params = ""
  let pagination = `${page}/${pageSize}`
  if(orderBy !== undefined){
    if(desc)
      params += "?orderBy=" + orderBy;
    else
      params += "?orderByDesc=" + orderBy;
  }
  if(commonSearch !== undefined){
    if(params !== ""){
      if(commonSearch !== "")
        params+= "&commonSearch=" + commonSearch
    }
  }

  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/all/paged/${pagination}${params}`,
    'GET'
  )
}

export const getAllDashboardRules = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/all`,
    'GET'
  )
}

export const getDashboardFilterRulesPaged = async (page = 1, pageSize = pageSizeDefault, orderBy = "id", commonSearch, desc = false, id) => {
  let params = "?DashboardRuleId=" + id
  let pagination = `${page}/${pageSize}`
  if(orderBy !== undefined){
    if(desc)
      params += "&orderBy=" + orderBy;
    else
      params += "&orderByDesc=" + orderBy;
  }
  if(commonSearch !== undefined){
    if(params !== ""){
      if(commonSearch !== "")
        params+= "&commonSearch=" + commonSearch
    }
  }

  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/filterrule/all/paged/${pagination}${params}`,
    'GET'
  )
}

export const getDashboardRule = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/${id}`,
    'GET'
  )
}

export const getDashboardFilterRule = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/filterrule/id/${id}`,
    'GET'
  )
}

export const deleteDashboardFilterRule = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/filterrule/id/${id}`,
    'DELETE'
  )
}

export const createNewDashboardRule = async (item) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules`,
    'POST',
    item
  )
}

export const createNewDashboardRuleItem = async (item) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/item`,
    'POST',
    item
  )
}

export const updateDashboardRuleItem = async (item) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/item`,
    'PUT',
    item
  )
}

export const updateDashboardRule = async (item) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules`,
    'PUT',
    item
  )
}

export const deleteDashboardRule = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}dashboardrules/id/${id}`,
    'DELETE'
  )
}


export const getEstOverview = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est/overview`,
    'GET'
  )
}

export const getChamadosResRelated = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {orderByColumn, desc, adicionalQuery} = params
  let pagination = `${page}/${pageSize}`
  let queryComplement = adicionalQuery ? adicionalQuery : ''
  if (orderByColumn && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColumn}`
  }
  if (orderByColumn && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColumn}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/all/related/res/paged/${pagination}${queryComplement}`,
    'GET'
  )
}

export const newResChamadoData = async (ticketData) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/res`,
    'POST',
    ticketData
  )
}

export const createNewEstItem = async (estItem) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}comptrade/est`,
    'POST',
    estItem
  )
}


export const newChamado = async (ticketType, branchId) => {
  const data = {
    ticketType, branchId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets`,
    'POST',
    data
  )
}

export const newChamadoData = async (ticketData, ticketId) => {
  const data = {...ticketData, ticketId: ticketId}
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/data/res`,
    'POST',
    data
  )
}

export const editChamadoData = async (ticketData) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/data/res`,
    'PUT',
    ticketData
  )
}

export const getDemandreasons = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}res/demandreasons/all`,
    'GET'
  )
}

export const getChamadoById = async (idChamado) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/res/id/${idChamado}`,
    'GET'
  )
}

export const addPerfil = async (recruitmentTicketDataId, name, description, mandatory) => {
  const data = {
    recruitmentTicketDataId: recruitmentTicketDataId,
    name: name,
    description: description,
    mandatory: mandatory
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/profileitems`,
    'POST',
    data
  )
}

export const deletePerfil = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/profileitems/id/${id}`,
    'DELETE'
  )
}

export const addBenefits = async (name, value, ticketDataId) => {
  const data = {
    benefitName: name,
    benefitValue: value,
    recruitmentTicketDataId: ticketDataId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/benefits`,
    'POST',
    data
  )
}

export const deleteBenefits = async (benefitId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/benefits/id/${benefitId}`,
    'DELETE'
  )
}

export const addEmployee = async (name, cpf, ticketDataId) => {
  const data = {
    recruitmentTicketDataId: ticketDataId,
    cpf: cpf,
    fullName: name
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/employeeData`,
    'POST',
    data
  )
}

export const deleteEmployee = async (benefitId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/employeeData/id/${benefitId}`,
    'DELETE'
  )
}

/// finish ticket
export const finishTicket = async (ticketId, comment) => {
  const data = {
    ticketId: ticketId,
    finishComment: comment
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/finishrequest`,
    'POST',
    data
  )
}
export const finishTicketAprove = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/id/${ticketId}/finishrequest/aprove`,
    'POST'
  )
}
export const finishTicketAproveEmLote = async (ticketIds) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/finishrequest/aprove`,
    'PUT',
    ticketIds
  )
}
export const finishTicketCancel = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/id/${ticketId}/finishrequest/cancel`,
    'POST'
  )
}

/// cancel ticket
export const cancelTicket = async (ticketId, comment, partialCancelation = false, satisfactionRate) => {
  const data = {
    ticketId: ticketId,
    cancelationComment: comment,
    partialCancelation: partialCancelation,
    satisfactionRate: satisfactionRate
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/cancelrequests`,
    'POST',
    data
  )
}
export const cancelTicketAprove = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/cancelrequests/ticketid/${ticketId}/aprove`,
    'PUT'
  )
}
export const requestReview = async (ticketId, motivo) => {
  const data = {
    reviewComment: motivo,
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/finishrequest/requestreview`,
    'PUT',
    data
  )
}
export const cancelTicketCancel = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/cancelrequests/ticketid/${ticketId}/archieve`,
    'PUT'
  )
}

// aprove ticket
export const aproveTicket = async (ticketId, comment) => {
  const data = {
    ticketId: ticketId,
    finishComment: comment
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/finishrequest`,
    'POST',
    data
  )
}
export const aproveTicketAprove = async (ticketId, nota, coment) => {
  const data = {
    satisfactionRate: nota,
    satisfactionComment: coment
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/finishrequest/aprove`,
    'PUT',
    data
  )
}
export const aproveTicketCancel = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/finishrequest/cancel`,
    'PUT'
  )
}
export const getCancelRequests = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/cancelrequests/all/ticketid/${ticketId}`,
    'GET'
  )
}


/////////////////////////////////////////////////////////////////////
// chamado menasgnes
/////////////////////////////////////////////////////////////////////

export const getChamadoMensagens = async (idChamado) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/messages/ticketid/${idChamado}`,
    'GET'
  )
}

export const postChamadoMensagem = async (idChamado, msg) => {
  const data = {
    messageData: msg,
    ticketId: idChamado
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/messages`,
    'POST',
    data
  )
}

/////////////////////////////////////////////////////////////////////
// chamado attendants
/////////////////////////////////////////////////////////////////////

export const getAttendants = async (empresaId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/assignableattendants/${empresaId}`,
    'GET'
  )
}

export const setAttendants = async (ticketId, newAtetendantId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/setattendant/${newAtetendantId}`,
    'PUT'
  )
}

/////////////////////////////////////////////////////////////////////
// chamado logs
/////////////////////////////////////////////////////////////////////

export const getLogsChamado = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/logs/ticketid/${ticketId}`,
    'GET'
  )
}

/////////////////////////////////////////////////////////////////////
// chamado observer
/////////////////////////////////////////////////////////////////////

export const getObservers = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/observers/ticketid/${ticketId}`,
    'GET'
  )
}

export const postObserver = async (ticketId, userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/observers/ticketid/${ticketId}/userid/${userId}`,
    'POST'
  )
}

export const deleteObserver = async (ticketId, userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/observers/ticketid/${ticketId}/userid/${userId}`,
    'DELETE'
  )
}


/////////////////////////////////////////////////////////////////////
// chamado perfis
/////////////////////////////////////////////////////////////////////

export const getCursosLevels = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/cursos/levels`,
    'GET'
  )
}

export const getCursosAllPaged = async (nivelCursoId, nomeCurso, page = 1, pageSize = pageSizeDefault) => {
  let query = ''
  if (nivelCursoId) {
    query += `?nivelCursoId=${nivelCursoId}`
  }
  if (nomeCurso) {
    if (query) {
      query += `&nome=${nomeCurso}`
    } else {
      query += `?nome=${nomeCurso}`
    }
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/cursos/all/paged/${page}/${pageSize}${query}`,
    'GET'
  )
}

export const recruitmentCustomerUserMe = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}recruitmentCustomerUserMaps/all/me`,
    'GET'
  )
}


export const startTicket = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/startticket`,
    'PUT'
  )
}

export const startReview = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/startrevision`,
    'PUT'
  )
}

export const startAtendimento = async (ticketId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/id/${ticketId}/setinprogress`,
    'PUT'
  )
}

export const getJobData = async (idFast) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}tickets/getjobdata/${idFast}`,
    'GET'
  )
}

export const getBeneficiosList = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/beneficios`,
    'GET'
  )
}

export const getEscalasList = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}fastrh/escala`,
    'GET'
  )
}

export const getNotificationsRead = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}notifications/readed`,
    'GET'
  )
}

export const getNotificationsUnread = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}notifications/unreaded`,
    'GET'
  )
}

export const getNotificationsAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}notifications/all`,
    'GET'
  )
}

export const readNotifications = async (data) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}notifications/setreaded`,
    'PUT',
    data
  )
}

export const downloadFileMsg = async (messageId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/messages/message/${messageId}/download`,
    'GET'
  )
}


export const sendfileurl = async (ticketId, fileName) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/messages/ticket/${ticketId}/upload`,
    'POST',
    {fileName: fileName}
  )
}
export const sendfile = async (ticketId, file, url) => {
  return await apiConfig.sendWithAxiosBlob(
    url,
    'PUT',
    file
  )
}
export const uploadReport = async (ticketId, fileName) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}ticket/messages/ticket/${ticketId}/upload/report`,
    'POST',
    {fileName: fileName}
  )
}

export const removeUserFromTeam = async (teamId, userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/${teamId}/${userId}`,
    'DELETE'
  )
}

export const getTeamsByUser = async (userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}teams/user/${userId}`,
    'GET'
  )
}

export const alterarSenhaUser = async (userId, password) => {
  const data = {
    Password: password
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/${userId}/newpassword`,
    'PUT',
    data
  )
}

// TRCT METHODS

export const uploadTrct = async (file, fileType) => {
  // Cria um FormData com o nome do arquivo
  const formData = new FormData();
  formData.append('frontFileName', file.name);
  formData.append('fileType', fileType)

  try {
    // Solicita o link de upload do backend
    const response = await apiConfig.sendWithAxios( 
      `${apiRoute}trct/uploadlink`,
      'POST',
      
        formData,
      
    );
    
    return response.data

  } catch (error) {
    console.error("There was an error uploading the file!", error);
    throw error;
  }
}

export const uploadSingleTrct = async (file, fileType, cpf) => {
  // Cria um FormData com o nome do arquivo
  const formData = new FormData();
  formData.append('frontFileName', file.name);
  formData.append('fileType', fileType)
  formData.append('cpf', cpf)


  try {
    // Solicita o link de upload do backend
    const response = await apiConfig.sendWithAxios( 
      `${apiRoute}trct/uploadlinksingle`,
      'POST',
      
        formData,
      
    );
    
    return response.data

  } catch (error) {
    console.error("There was an error uploading the file!", error);
    throw error;
  }
}

 
export const uploadS3 = async(file, uploadUrl) =>{

  const newFormData = new FormData()
  console.log(file)
  newFormData.append('fileName', file.name);

  await apiConfig.sendWithAxiosBlob(
    uploadUrl,
    'PUT',
    file
  );

}
export const triggerWorker = async(file) =>{
  
  const newFormData = new FormData()
  console.log(file)
  newFormData.append('fileName', file.name);

  await apiConfig.sendWithAxios(
    `${apiRoute}trct/worker`,
    'POST',
    newFormData
  );
}

export const deleteFile = async (folder, fileName) => {
  await apiConfig.sendWithAxios(
    `${apiRoute}trct/deletefile`,
    'POST',
    {
      Folder: folder,
      FileName: fileName
    }
  );
};
export const deleteDocument = async (folder, fileName) => {
  await apiConfig.sendWithAxios(
    `${apiRoute}trct/deletedocument`,
    'POST',
    {
      Folder: folder,
      FileName: fileName
    }
  );
};

export const downloadTrct = async (fileName, folder) => {
  const formData = new FormData();
  fileName = folder +fileName
  formData.append('fileName', fileName);
  try {
    const response = await apiConfig.sendWithAxios(
      `${apiRoute}trct/downloadlink`,
      'POST',
      formData
    );

    const downloadUrl = response.data.url;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("There was an error uploading the file!", error);
    throw error;
  }
}
export const listDocuments = async (page, pageSize, orderAsc, commonSearch) => {
  try {
    let search = ""
    if(commonSearch !== null || commonSearch !== undefined){
      search = `&commonSearch=${commonSearch}`
    }

    let ascObj = ""
    if(orderAsc === true){
      ascObj = "?orderBy=Cpf"
    }
    else{
      ascObj = "?orderByDesc=Cpf"
    }
    if(page == null || page == undefined ){
      page = 1
      pageSize = 10
    }
    
    const response = await apiConfig.sendWithAxios(
      `${apiRoute}trct/listdocuments/paged/${page}/${pageSize}${ascObj}${search}`,
      'GET'
    );
    return response.data;
  } catch (error) {
    console.error("There was an error listing the files!", error);
    throw error;
  }
}
export const listUploads = async (page, pageSize, orderAsc, commonSearch) => {
  try {
    let search = ""
    if(commonSearch !== null && commonSearch !== undefined && commonSearch !== ""){
      search = `&fileName=${commonSearch}`
    }

    let ascObj = ""
    if(orderAsc === true){
      ascObj = "?orderBy=createdAt"
    }
    else{
      ascObj = "?orderByDesc=createdAt"
    }
    if(page == null || page == undefined ){
      page = 1
      pageSize = 10
    }
    
    const response = await apiConfig.sendWithAxios(
      `${apiRoute}trct/listuploads/page/${page}/${pageSize}${ascObj}${search}`,
      'GET'
    );
    return response.data;
  } catch (error) {
    console.error("There was an error listing the files!", error);
    throw error;
  }
}


export const getUploadsStatus = async()=>{
  try{
    const response = await apiConfig.sendWithAxios(
      `${apiRoute}trct/uploads`,
      'GET'
    )
    return response.data
  } catch(error){
    console.error("Error at getting uploads status",error);
    throw error;
  }

}

// export const searchFilesInFolder = async (folderName, fileName, orderDesc) => {
//   try {
//     const response = await apiConfig.sendWithAxios(
//       `${apiRoute}trct/searchfiles?folderName=${encodeURIComponent(folderName)}&fileName=${encodeURIComponent(fileName)}&orderDesc=${encodeURIComponent(orderDesc)}`,
//       'GET'
//     );
//     return response.data;
//   } catch (error) {
//     console.error("There was an error searching the files!", error);
//     throw error;
//   }
// };

// export const searchDocuments = async (cpf, orderDesc) => {
//   try {
//     const response = await apiConfig.sendWithAxios(
//       `${apiRoute}trct/searchdocuments?fileName=${encodeURIComponent(cpf)}&orderDesc=${encodeURIComponent(orderDesc)}`,
//       'GET'
//     );
//     return response.data;
//   } catch (error) {
//     console.error("There was an error searching the files!", error);
//     throw error;
//   }
// };
// export const searchLogs = async (cpf, orderDesc) => {
//   try {
//     const response = await apiConfig.sendWithAxios(
//       `${apiRoute}trct/searchlogs?cpf=${encodeURIComponent(cpf)}&orderDesc=${encodeURIComponent(orderDesc)}`,
//       'GET'
//     );
//     return response.data;
//   } catch (error) {
//     console.error("There was an error searching the files!", error);
//     throw error;
//   }
// };

export const listChatbotLogs = async (page, pageSize, orderAsc, commonSearch) => {
  try {
    let search = ""
    if(commonSearch !== null && commonSearch !== undefined && commonSearch !== ""){
      search = `&cpf=${commonSearch}`
    }

    let ascObj = ""
    if(orderAsc === true){
      ascObj = "?orderBy=createdAt"
    }
    else{
      ascObj = "?orderByDesc=createdAt"
    }
    if(page == null || page == undefined ){
      page = 1
      pageSize = 10
    }
    
    const response = await apiConfig.sendWithAxios(
      `${apiRoute}trct/log/page/${page}/${pageSize}${ascObj}${search}`,
      'GET'
    );
    return response.data;
  } catch (error) {
    console.error("There was an error listing the files!", error);
    throw error;
  }
}

