import moment from 'moment'

import {
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface IDadosSolicitante {
  name: string
  email: string
  data: string
}

function DadosSolicitante(props: IDadosSolicitante) {
  const { 
    name, email, data
  } = props;

  return (
    <Grid container spacing={{ xs: 3 }} className='mb-30'>
      <Grid item xs={12}>
        <p className="m-0 ">
          Dados do solicitante
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TextField
          fullWidth
          size='small'
          label="Nome"
          value={name || ''}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          size='small'
          label="E-mail"
          value={email || ''}
          disabled={true}
        />
      </Grid>
      
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          fullWidth
          size='small'
          label="Data da solicitação"
          value={data ? `${moment(data).format('DD/MM/YYYY')}` : ''}
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarMonthIcon />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DadosSolicitante
