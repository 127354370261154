import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { } from 'react'
import { Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import './style.scss'
import { IDashboardEnvOverview } from '../../interfaces'
import {IconButton } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { redirect } from 'react-router-dom'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

interface TableEnvironmentsProps{
  dataSource: IDashboardEnvOverview[]
  deleteDashboardGroup: any
  dashboardId: number
}

const TableEnvironments: React.FC<TableEnvironmentsProps> = ({ deleteDashboardGroup, dashboardId, dataSource}) => {

  const columns: ColumnsType<IDashboardEnvOverview> = [
    {
      title: 'Ambiente',
      dataIndex: 'environmentName',
    },
    {
      title: 'Filtros',
      render: (_, record) => {
        if (record.filtersCount === 0) {
          return ("Nenhum filtro");
        }
        else if(record.filtersCount === 1){
          return ("1 Filtro")
        }
        else {
          return (`${record.filtersCount} Filtros`)
        }
      },
    },
    {
      title: 'Equipes',
      render: (_, record) => {
        if (record.teamsCount === 0) {
          return ("Nenhuma equipe");
        }
        else if(record.teamsCount === 1){
          return ("1 Equipe")
        }
        else {
          return (`${record.filtersCount} Equipes`)
        }
      },
    },
    {
      title: 'Grupo',
      dataIndex: 'groupName',
    },
    {
      title: 'Usuários',
      render: (_, record) => {
        if (record.usersCount === 0) {
          return ("Nenhum usuário");
        }
        else if(record.usersCount === 1){
          return ("1 Usuário")
        }
        else {
          return (`${record.usersCount} Usuários`)
        }
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <div style={{textAlign: 'right'}}>
          <IconButton
            className='btn-purple mr-6 content-end'
            disabled={false}
            onClick={() => window.location.href = `/app/dash_management/dashboard/${dashboardId}/environment/${record.environmentId}`}
          >
            <SettingsIcon fontSize='small' />
          </IconButton>

          <Popconfirm title="Deseja remover este dashboard do ambiente?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => deleteDashboardGroup(dashboardId, record.groupId)}>
          <IconButton className='btn-purple mr-6 content-end' disabled={false}>
            <DeleteForeverIcon fontSize='small' />
          </IconButton>
          </Popconfirm>
        </div>
      ),
    },
  ];


  return (
    <div style={{textAlign: "right"}}>
      <Table
        columns={columns}
        dataSource={dataSource}
        locale={{ emptyText:
            ' Nenhum ambiente'
        }}
        pagination={false}
      />;

    </div>


  );
};

export default TableEnvironments;