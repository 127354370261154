import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid
} from '@mui/material'
import { number } from '../../services/mask';
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification'
import { Save } from '@mui/icons-material'
import { Form } from 'antd'
import { trackPromise } from 'react-promise-tracker'
import services from '../../services'

function DashboardNew() {
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardOrder, setDashboardOrder] = useState<number | null>(null);
  const [dashboardActive, setDashboardActive] = useState<boolean | undefined>(true);
  const [dashboardEnableFilters, setDashboardEnableFilters] = useState<boolean | undefined>(false);
  const [dashboardEnableNavigationBar, setDashboardEnableNavigationBar] = useState<boolean | undefined>(false);
  const [dashboardReportPath, setDashboardReportPath] = useState("");
  const [dashboardRefreshTime, setDashboardRefreshTime] = useState<number | null>(null);
  const [dashboardChanged, setDashboardChanged] = useState<boolean>(false);


  const { notify } = useNotification()


  const HandleNewDashboard = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()
    let dashboard = {
      Name: dashboardName,
      ReportPath: dashboardReportPath,
      Order: dashboardOrder,
      Active: dashboardActive,
      AutoRefreshTime: dashboardRefreshTime,
      EnableFilters: dashboardEnableFilters,
      EnableNavigationBar: dashboardEnableNavigationBar
    };
    let result = await trackPromise(services.api.criarDashboard(dashboard))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    window.location.href = `/app/dash_management/dashboard/${result.data.id}`
  }


  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle subText={`Novo Dashboard`}>
          <div>Novo Dashboard</div>
        </PageTitle>
          <Grid container justifyContent="center" spacing={{ xs: 3 }}>
            <Grid item md={11} xs={12}>
              <div className='panel'>
                <div className='panel-body'>
                  <h3>Dados do dashboard</h3>
                  <br />
                  <Grid container style={{paddingLeft: '20px'}} spacing={{ xs: 3 }}>
                    <form style={{display: 'contents'}} onSubmit={HandleNewDashboard}>
                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="Nome"
                          inputProps={{ maxLength: 120 }}
                          InputLabelProps={{ shrink: true }}
                          value={dashboardName}
                          onChange={e =>
                            setDashboardName(e.target.value)}
                        />
                      </Grid>
                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="Ordem"
                          inputProps={{ maxLength: 120 }}
                          InputLabelProps={{ shrink: true }}
                          value={dashboardOrder === null ? '' : dashboardOrder.toString()}
                          onChange={e => setDashboardOrder(number(e.target.value))}
                        />
                      </Grid>
                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                          fullWidth
                          size='small'
                          label="Auto refresh - segundos"
                          inputProps={{ maxLength: 120 }}
                          InputLabelProps={{ shrink: true }}
                          value={dashboardRefreshTime === null ? '' : dashboardRefreshTime.toString()}
                          onChange={e => setDashboardRefreshTime(number(e.target.value))}
                        />
                      </Grid>
                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={2}>
                        <FormControl>
                          <InputLabel>Ativo</InputLabel>
                          <Select
                            fullWidth
                            required
                            notched
                            size="small"
                            label="Ativo"
                            value={dashboardActive === undefined ? "" : dashboardActive.toString()}
                            onChange={e => setDashboardActive(e.target.value === "true")}
                          >
                            <MenuItem value={"true"} >
                              Sim
                            </MenuItem>
                            <MenuItem value={"false"} >
                              Não
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid className={'p-10'} item xs={12} sm={12} md={12} lg={8}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="Report Path"
                          inputProps={{ maxLength: 220 }}
                          InputLabelProps={{ shrink: true }}
                          value={dashboardReportPath}
                          onChange={e => setDashboardReportPath(e.target.value)}
                        />
                      </Grid>

                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={2}>
                        <FormControl>
                          <InputLabel>Ativar filtro PowerBI</InputLabel>
                          <Select
                            fullWidth
                            required
                            notched
                            size="small"
                            label="Ativar filtro PowerBI"
                            value={dashboardEnableFilters === undefined ? "" : dashboardEnableFilters.toString()}
                            onChange={e => setDashboardEnableFilters(e.target.value === "true")}
                          >
                            <MenuItem value={"true"} >
                              Sim
                            </MenuItem>
                            <MenuItem value={"false"} >
                              Não
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid className={'p-10'} item xs={12} sm={6} md={6} lg={2}>
                        <FormControl>
                          <InputLabel>Barra de navegação PowerBI</InputLabel>
                          <Select
                            fullWidth
                            required
                            notched
                            size="small"
                            label="Barra de navegação PowerBI"
                            value={dashboardEnableNavigationBar === undefined ? "" : dashboardEnableNavigationBar.toString()}
                            onChange={e => setDashboardEnableNavigationBar(e.target.value === "true")}
                          >
                            <MenuItem value={"true"} >
                              Sim
                            </MenuItem>
                            <MenuItem value={"false"} >
                              Não
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <br />
                      <Grid item xs={12} className="flex justify-end gap-6">
                        <div style={{textAlign: "right", marginTop: "10px"}}>
                          <Button
                            variant="contained"
                            className='btn-purple mr-6 content-end mb-12'
                            type="submit"
                            color="inherit"
                            //onClick={UpdateDashboardData}
                          >
                            <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar novo dashboard
                          </Button>
                        </div>
                      </Grid>

                    </form>


                  </Grid>


                </div>
              </div>
            </Grid>
          </Grid>
      </div>
    </div>
  )
}

export default DashboardNew;