import React from "react";
import { Popconfirm, Table, TableColumnsType, Tooltip } from "antd";
import { ITrct } from "../../interfaces";
import { Grid, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import {downloadTrct } from "../../services/api";
import './style.scss'


interface ITableTrct {
    data: ITrct[];
    sortFunction: any;
    loading: boolean;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    currentPage: number;
    handlePagination: (page: number, pageSize?: number) => void;
    handleDelete:(file:string) =>void;
}


const TableTrct: React.FC<ITableTrct> = ({
    data,
    sortFunction,
    loading,
    pageSize,
    totalPages,
    totalCount,
    currentPage,
    handlePagination,
    handleDelete
}) => {


    const onDownload = (fileName: string) => {
        downloadTrct(fileName, "TrctWorker/");
    };

    // Agrupando os arquivos por CPF, ignorando duplicados
    const groupedData = data.reduce((acc, current) => {
        const cpf = current.cpf;
        if (!acc[cpf]) {
            acc[cpf] = [];
        }
        acc[cpf].push(current);
        return acc;
    }, {} as { [key: string]: ITrct[] });

    const columns: TableColumnsType<any> = [
        {
            title: "Name",
            key: "name",
            render: (_, record) => record.files[0].name
        },
        {
            title: "CPF",
            key: "cpf",
            render: (_, record) => record.cpf
        },
        {
            title: "Quantidade de Arquivos",
            key: "fileCount",
            render: (_, record) => record.files.length
        }
    ];
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        sortFunction(sorter);
        handlePagination(pagination.current, pagination.pageSize);
    };

    return (
        <Table
            locale={{
                triggerDesc: 'Ordenação descendente',
                triggerAsc: 'Ordenação ascendente',
                cancelSort: 'Cancelar ordenação',
                emptyText: 'Nenhum chamado'
            }}
            pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                showSizeChanger: true,
                // onChange: handlePagination
            }}
            columns={columns}
            dataSource={Object.keys(groupedData).map(cpf => ({
                cpf,
                files: groupedData[cpf]
            }))}
            rowKey={(record) => record.cpf}
            onChange={handleChange}
            loading={loading}
            expandable={{
                expandedRowRender: (record) => (
                    <div className="file-container">
                        {record.files.map((file: ITrct, index: number) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                <div className="file-item" style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                                    <p style={{ marginLeft: 15 }}>
                                        {file.fileName} - {file.createdAt}
                                    </p>
                                    <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                                        <Tooltip title="Download">
                                            <IconButton onClick={() => onDownload(file.fileName)}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir">
                                        <Popconfirm title="Deseja excluir o arquivo??" cancelText={"Cancelar"} okText={"Excluir"} onConfirm={() => handleDelete(file.fileName)}>
                                            <IconButton>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Popconfirm>

                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </div>
                ),
                rowExpandable: (record) => record.files.length > 0,
                expandedRowClassName: () => 'file-container'
            }}
            
        />
    );
};

export default TableTrct;
