import { useCallback } from "react";
import { isExpired } from 'react-jwt'
import { Outlet, Navigate } from "react-router-dom";

import Cookies from 'js-cookie'

import { useNotification } from '../hooks/useNotification.js'

import LateralHeader from "./LateralHeader.js";
// import Denied from '@view/Denied/Denied'


const RequireAuth = (props: any) => {
  const { roles } = props
  const { notify } = useNotification()

  // verifica se o usuário esta autenticado
  const authenticated = useCallback(() => {
    const token = Cookies.get('TradeTalentosTokenPAT')
    if(token) {
      const isMyTokenExpired = isExpired(token)
      if (!isMyTokenExpired) {
        return true
      } else {
        notify('Sua sessão expirou', { variant: 'warning' })
        Cookies.remove('TradeTalentosTokenPAT')
        Cookies.remove('TradeTalentosUserPAT')
        return false
      }
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // verifica se o usuário tem acesso a pagina
  const autorized = useCallback(() => {
    const userObj:any = Cookies.get('TradeTalentosUserPAT')
    const myDecodedToken = JSON.parse(userObj)
    
    const userRoles = myDecodedToken?.role
      ? myDecodedToken.role.map((x: any) => x)
      : []
    
    let hasPermition = false

    // console.log('-----', userPortalRole, userRoles)
    // pagina não possui restrições de acesso
    if (!roles) return true

    // usuario não possui permissões de acesso configuradas
    if (!userRoles.length) return false
    
    // verfica se as permissoes do usuario batem com a restrição da pagina
    roles.forEach((pageRestrition: string) => {
      if (userRoles.includes(pageRestrition)) {
        hasPermition = true
      }
    })
    
    return hasPermition
  }, [roles]) // rest

  if (!authenticated()) {
    return (
      <Navigate
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return autorized() ? (
    <LateralHeader>
      <Outlet />
    </LateralHeader>
  ) : (
    <Navigate to="/nao-autorizado" />
  );
};

export default RequireAuth;
