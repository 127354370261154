import React, { useState, useCallback, useRef } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import moment from 'moment'
import { debounce } from 'lodash'

import {
  Grid,
  Drawer,
  IconButton,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  ButtonGroup,
  ClickAwayListener,
  Popper,
  TextField,
  Button
} from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useNotification } from '../../../hooks/useNotification.js'
import services from '../../../services/index.js'

interface IModelComponent {
  chamadoId: number | undefined
}

const options = ['Ordenar por nome', 'Ordenar por data'];

function Logs(props: IModelComponent) {
  const { chamadoId } = props;
  const { promiseInProgress } = usePromiseTracker();
  const { notify } = useNotification()
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [modal, setModal] = useState<boolean>(false)
  const [logs, setLogs] = useState<any>(null)
  const [logsFilter, setLogsFilter] = useState<any>(null)
  const [search, setSearch] = useState('')
  const [orderbyData, setOrdebyData] = useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca)
  }, 800),  [logs])

  const handleBusca = useCallback(async (busca: string) => {
    setLogsFilter(logs.filter((item: any) => item.changeMessage.toUpperCase().includes(busca.toUpperCase())))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs, orderbyData])

  const handleBuscaOrdeby = useCallback(async () => {
    setLogsFilter(
      services.utils.orderbyArrayOfObjects('createdAt', logs.filter((item: any) => item.changeMessage.toUpperCase().includes(search.toUpperCase())), !orderbyData)
    )
    setOrdebyData(!orderbyData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbyData, search, logs])


  const handleopen = async () => {
    setModal(true)
    try {
      const res = await trackPromise(services.api.getLogsChamado(chamadoId))
      if (res.fail) {
        setLogs([])
        setLogsFilter([])
        throw new Error(
          res.error || 'Não foi possível consultar Logs'
        )
      }
      setLogs(res.data)
      setLogsFilter(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }
  
  const getColor = (status: string) => {
    let classCor = ''
    if (status.includes('pediu o cancelamento do chamado') || status.includes('aprovou o cancelamento do chamado')) {
      classCor = 'red'
    }
    if (status.includes('solicitou aprovação de conclusão') || status.includes('aprovou o pedido de cancelamento')) {
      classCor = 'orange'
    }
    if (status.includes('aprovou a conclusão do chamado')) {
      classCor = 'green'
    }
    return classCor
  }

  return (
    <>
      {chamadoId && 
        <Button
          variant="contained"
          type="button"
          size='small'
          disabled={promiseInProgress}
          color="secondary" 
          onClick={handleopen}
        >
          Ver Logs
        </Button>
      }

      <Drawer
        anchor={'right'}
        open={modal}
        onClose={() => setModal(false)}
      >
        <div style={{maxWidth: '500px'}}>
          <div className='flex gap-10 items-center' style={{borderBottom: '2px solid $cor-primary', paddingBottom: '10px', margin: '20px 20px 0 20px'}}>
            <span>
              <strong>Logs do Chamado</strong>
            </span>
            <IconButton
              aria-label="close"
              onClick={() => setModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <div className='chamados_box m-20'>
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={12}>
                <div className='flex flex-nowrap'>
                  <TextField
                    size='small'
                    label='Pesquisa'
                    placeholder='Pesquisa'
                    inputProps={{ maxLength: 50 }}
                    value={search}
                    onChange={e => handleChangeBusca(e.target.value)}
                    disabled={promiseInProgress}
                  />
                  <Button
                    variant="contained"
                    type="button"
                    size='small'
                    style={{minWidth: '90px'}}
                    color="secondary" 
                    onClick={handleBuscaOrdeby}
                    disabled={promiseInProgress}
                  >
                    Ordenar 
                    {orderbyData ? <ArrowUpwardIcon style={{fontSize: '12px'}}  /> : <ArrowDownwardIcon style={{fontSize: '12px'}}  />}
                  </Button>
                  {/* <ButtonGroup variant="contained"  ref={anchorRef} aria-label="split button">
                    <Button variant="contained" type="button" style={{minWidth: '160px'}}
                    size='small' onClick={handleBuscaOrdeby}>
                      {options[selectedIndex]}
                      {orderbyData ? <ArrowUpwardIcon style={{fontSize: '12px'}}  /> : <ArrowDownwardIcon style={{fontSize: '12px'}}  />}
                    </Button>
                    <Button
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  disabled={index === 2}
                                  selected={index === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper> */}
                </div>
              </Grid>
              <Grid item xs={12}>
                {!logsFilter && 
                  <div className='justify-center'>loading...</div>
                }
                {logsFilter?.length === 0 && 
                  <div className='justify-center'>Nenhum registro encontrado</div>
                }   
                {logsFilter?.map((item: any, index: number) => (
                  <div className={`logItem ${getColor(item.changeMessage)}`} key={item.id}>
                    <div className='logItem_title'>{item?.user?.fullName}</div>
                    <div className='logItem_text'>{ services.utils.parseLines(item?.changeMessage)}</div>
                    <div className='logItem_date'>{item?.createdAt ? `${moment(item.createdAt).format('DD/MM/YYYY HH:mm')}` : ''} </div>
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>

    </>
  )
}

export default Logs
