
import { PageTitle } from '../../components/PageElements'

function NotFound() {
  return (
    <div>
      <PageTitle text={'404 Página não encontrada'} />
    </div>
  )
}

export default NotFound
