import React from "react";
import { Table, TableColumnsType, Tooltip } from "antd";
import { ITrctPackage } from "../../interfaces";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { deleteFile, downloadTrct } from "../../services/api";
import { Popconfirm } from "antd/lib";

// Interface para as props do componente
interface ITableTrct {
    data: ITrctPackage[],
    sortFunction:any;
    loading: boolean;
    pageSize: number;
    totalCount: number;
    currentPage: number;
    handlePagination: (page: number, pageSize?: number) => void;
    handleDelete:(file:string) =>void;

}

const onDownload = (fileName: string) => {
    downloadTrct(fileName, "Uploads/");
}

// Componente TableTrct
const TableTrct: React.FC<ITableTrct> = ({
    data,
    sortFunction,
    loading,
    pageSize,
    totalCount,
    currentPage,
    handlePagination,
    handleDelete
}) => {
    
    const columns: TableColumnsType<ITrctPackage> = [
        {    
            title: "Nome do arquivo",
            dataIndex: 'fileName',
            key: 'fileName',
            sorter: false,
            render: (text) => text.substring(9)
        },
        {
            title: "Data de Criação",
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            defaultSortOrder: 'ascend'
        },
        {
            title: "Sucesso",
            dataIndex: 'success',
            key: 'success',
            sorter: false,
            defaultSortOrder: 'ascend',
            render: (_, record) => {
                let badgeClass = "";
                let badgeText = "";
                switch(record.success) {
                    case "Sucesso": // Sucesso
                        badgeClass = "badge-success";
                        badgeText = "Sucesso";
                        break;
                    case "Errp": // Erro
                        badgeClass = "badge-error";
                        badgeText = "Erro";
                        break;
                    case "Em fila": // Em fila
                        badgeClass = "badge-warning";
                        badgeText = "Em fila";
                        break;
                    default:
                        badgeClass = "badge-default";
                        badgeText = "Desconhecido";
                        break;
                }

                return (
                    <span className={`badge ${badgeClass}`}>
                        {badgeText}
                    </span>
                );
            }
        },
        {
            title: "Ações",
            key: "actions",
            render: (_, record) => (
                <>
                    <Tooltip title="Download">
                        <IconButton onClick={() => onDownload(record.fileName)}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                        <Popconfirm title="Deseja excluir o arquivo??" cancelText={"Cancelar"} okText={"Excluir"} onConfirm={() => handleDelete(record.fileName)}>
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                        </Popconfirm>
                    </Tooltip>
                </>
            )
        }
    ];

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        if (sorter.order) {
            sortFunction(sorter);
        }
        handlePagination(pagination.current, pagination.pageSize);
    };

    return (
        <Table
            locale={{
                triggerDesc: 'Ordenação descendente',
                triggerAsc: 'Ordenação ascendente',
                cancelSort: 'Cancelar ordenação',
                emptyText: 'Nenhum dado'
            }}
            pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                showSizeChanger: true,
                onChange: handlePagination
            }}
            columns={columns}
            rowKey={(record) => record.fileName}
            onChange={handleChange}
            loading={loading}
            dataSource={data}
        />
    );
}

export default TableTrct;
