import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid, TextField
} from '@mui/material'
import { useParams } from 'react-router-dom';

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification'
import { IDashboardFilter, IEnvironmentOverview } from '../../interfaces'
import { number } from '../../services/mask'
import { trackPromise } from 'react-promise-tracker'
import services from '../../services'
import TableGroups from './TableGroups'
import { Divider } from 'antd'
import { Save } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Modal from '../../components/Modal'
import DropdownSearch from '../../components/DropdownSearch'
import { getEnvOverview } from '../../services/api'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'


function EnvironmentEdit() {

  const [environmentOverview, setEnvironmentOverview] = useState<IEnvironmentOverview | undefined>(undefined);
  const [envName, setEnvName] = useState<string | undefined>(undefined);
  const [newGroupName, setNewGroupName] = useState<string | undefined>(undefined);
  const [editGroupName, setEditGroupName] = useState<string | undefined>(undefined);
  const [editGroupId, setEditGroupId] = useState<number | undefined>(undefined);
  const [envChanged, setEnvChanged] = useState<boolean>(false);
  const [newGroupModal, setNewGroupModal] = useState<boolean>(false);
  const [editGroupModal, setEditGroupModal] = useState<boolean>(false);
  const [envChanges, setEnvChanges] = useState<number>(0);

  const { id } = useParams();
  const { notify } = useNotification()


  async function GetEnvironmentData(id: number) {
    let envData = await trackPromise(services.api.getEnvOverview(id));
    if (envData.fail) {
      notify("Não foi possivel carregar os dados do ambiente", { variant: 'error' })
      return
    }
    else if(envData.status === 204){
      notify("Ambiente inexistente", { variant: 'error' })
      return
    }
    setEnvironmentOverview(envData.data)
    setEnvName(envData.data.environmentName)
  }

  async function UpdateEnvName() {
    let result = await trackPromise(services.api.editarEnvironment(envName, id))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      setEnvChanges(2)
      setEnvChanged(false)
      return
    }
    notify("Ambiente atualizado com exito", { variant: 'success' })
    setEnvChanges(2)
    setEnvChanged(false)
  }

  async function HandleNewGroup() {
    setNewGroupModal(false)
    let result = await trackPromise(services.api.addEnvGroup(newGroupName, id))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      setEnvChanges(2)
      setEnvChanged(false)
      return
    }
    notify("Grupo criado com sucesso", { variant: 'success' })
    setNewGroupName("")
    await GetEnvironmentData(number(id))
  }

  async function HandleEditGroup() {
    setEditGroupModal(false)
    let result = await trackPromise(services.api.updateEnvGroup(editGroupName, editGroupId))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    notify("Grupo atualizado com sucesso", { variant: 'success' })
    await GetEnvironmentData(number(id))
  }

  async function HandleSetGroupOrder(ids: number[]) {
    let result = await trackPromise(services.api.setGroupOrder(ids))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    notify("Ordem dos grupos salva com sucesso.", { variant: 'success' })
  }

  async function HandleDeleteGroup(groupId: number) {
    let result = await trackPromise(services.api.deleteEnvGroup(groupId))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    notify("Grupo removido com sucesso", { variant: 'warning' })
    await GetEnvironmentData(number(id))
  }

  const OpenEditGroupModal = async (groupName: string, groupId: number) => {
    setEditGroupModal(true)
    setEditGroupName(groupName)
    setEditGroupId(groupId)
  }


  useEffect(() => {
    const init = async () => {
      await GetEnvironmentData(number(id));
      console.log(environmentOverview)
    };
    init().then()
  }, [id]);

  useEffect(() => {
    if (envChanges > 1) {
      setEnvChanged(true);
    }
    setEnvChanges(envChanges + 1)
  }, [envName]);

  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle subText={`Editando o ambiente #${id}`}>
          <div>{envName ? `Ambientes > ${envName}` : 'Ambientes > ...'}</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={11} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowBackIcon sx={{ fontSize: 40, marginRight: '10px', cursor: 'pointer' }} onClick={() => window.location.href = `/app/dash_management/functionalities`} />
                  <h2>Dados do ambiente</h2>
                </div>
                <br />

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label="Nome do ambiente"
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ shrink: true }}
                    value={envName}
                    onChange={e =>
                      setEnvName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} className="flex justify-end gap-6">
                  <div style={{textAlign: "right", marginTop: "10px"}}>
                    <Button
                      variant="contained"
                      className='btn-purple mr-6 content-end mb-12'
                      type="button"
                      size='small'
                      color="inherit"
                      disabled={!envChanged}
                      onClick={UpdateEnvName}
                    >
                      <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar alterações
                    </Button>
                  </div>
                </Grid>


                <Divider></Divider>
                <h3>Grupos</h3>
                <div style={{textAlign: "right"}}>
                  <Button
                    variant="contained"
                    className='btn-purple mr-6 content-end mb-12'
                    type="button"
                    size='small'
                    color="inherit"
                    onClick={() => setNewGroupModal(true)}
                  >
                    <AddCircleOutlineIcon style={{marginRight: '5px'}} fontSize='small' />  Novo grupo
                  </Button>
                </div>
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableGroups
                      data={environmentOverview?.groups || []}
                      deleteGroup={HandleDeleteGroup}
                      editGroup={OpenEditGroupModal}
                      setGroupOrder={HandleSetGroupOrder}/>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>

        <Modal size='sm' open={newGroupModal} close={() => setNewGroupModal(false)} titulo={'Novo grupo'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome do grupo"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={newGroupName}
                onChange={e =>
                  setNewGroupName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} className="flex justify-end gap-6">
              <br/>
              <Button
                variant="contained"
                className='btn-purple mr-6 content-end mb-12'
                type="button"
                size='small'
                color="secondary"
                disabled={newGroupName === undefined || newGroupName.length < 3}
                onClick={()=> HandleNewGroup()}
              >
                Adicionar
              </Button>


            </Grid>

          </Grid>
        </Modal>

        <Modal size='sm' open={editGroupModal} close={() => setEditGroupModal(false)} titulo={'Editar grupo'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome do grupo"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={editGroupName}
                onChange={e =>
                  setEditGroupName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} className="flex justify-end gap-6">
              <br/>
              <Button
                variant="contained"
                className='btn-purple mr-6 content-end mb-12'
                type="button"
                size='small'
                color="secondary"
                disabled={editGroupName === undefined || editGroupName.length < 3}
                onClick={()=> HandleEditGroup()}
              >
                Salvar
              </Button>


            </Grid>

          </Grid>
        </Modal>

      </div>
    </div>
  )
}

export default EnvironmentEdit;
