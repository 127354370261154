import { Popconfirm, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import moment from 'moment/moment'
import services from '../../services'
import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { IDashboardRule } from '../../interfaces'
import { DeleteOutline } from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React from 'react'


interface ITableDashboardRule {
  data: IDashboardRule[],
  sortFunction: any
}

const TableEst: React.FC<ITableDashboardRule> = ({ data, sortFunction }) => {
  const columns: TableColumnsType<IDashboardRule> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'ascend',
      key: 'id'
    },

    {
      title: "Nome",
      sorter: true,
      key: 'Name',
      render: (_, record) => record.name
    },

    {
      title: "Tabela",
      sorter: true,
      key: 'Table',
      render: (_, record) => record.table
    },
    {
      title: "Coluna",
      sorter: true,
      key: 'Column',
      render: (_, record) => record.column
    },
    {
      title: "Adicionado em",
      sorter: true,
      key: 'CreatedAt',
      render: (_, record) => {
        const higienizadoEm = moment(record.createdAt);
        return higienizadoEm.format('DD/MM/YYYY') === '01/01/0001' ? '---' : higienizadoEm.format('DD/MM/YYYY');
      }
    },
    {
      title: "Ações",
      render: (_, record) =>
        <div className="justify-end">
          <IconButton
            size="small" aria-label="editar usuário" component="label"
            onClick={() => window.location.href = `/app/dash_management/rules/${record.id}`}
            //disabled={promiseInProgress}
          >
            <RemoveRedEyeIcon fontSize="small" />
          </IconButton>
        </div>
    }
  ];


  const handleChange = (pagination: any, filters: any, sorter: any) => {
    sortFunction(sorter)
  }

  return (
    <>
      <Table
        locale={{
          triggerDesc: 'Ordenação descendente',
          triggerAsc: 'Ordenação ascendente',
          cancelSort: 'Cancelar ordenação',
          emptyText: 'Nenhuma regra'
        }}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.id!.toString()}
        onChange={handleChange}
        dataSource={data}
      />

    </>
  );
}




export default TableEst;