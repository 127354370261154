import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import {
  Button, FormControl, FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List, ListItem, ListItemText, Menu,
  Paper, Radio, RadioGroup,
  TextField
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import services from '../../services'
import { useNotification } from '../../hooks/useNotification'
import { debounce } from 'lodash'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './style.scss'


  interface TableEnvironmentsProps{
  setSelectedUsers: any
}

  function not(a: readonly any[], b: readonly any[]) {
    // return a.filter((value) => b.indexOf(value) === -1);
    return a.filter((value) => services.utils.findIndexInArray(value.id, b, 'id') === -1);
  }

  function intersection(a: readonly any[], b: readonly any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

const UserSelectList: React.FC<TableEnvironmentsProps> = ({ setSelectedUsers}) => {

  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')
  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')
  const [left, setLeft] = useState<readonly any[]>([]);
  const [right, setRight] = useState<readonly any[]>([]);
  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null);
  const [checked, setChecked] = useState<readonly any[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel);

  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()

  useEffect(() => {
    handleChangeBuscaUserDisponivel("")
  }, []);

  const customList = (items: readonly any[], titulo: string) => (
    <List dense component="div" role="list">
      {items.length === 0 ?
        <div className='flex flex-nowrap gap-8 justify-center items-center'
             style={{fontSize: '14px', margin: '22px'}}
        >
          <ErrorOutlineIcon style={{color: 'red'}} />
          <span style={{maxWidth: 'fit-content'}}>Não ha {titulo}</span>
        </div> : ''
      }
      {items.map((value: any) => {
        const labelId = `transfer-list-item-${value.id}-label`;
        return (
          <ListItem
            className={`${checked.indexOf(value) !== -1 ? 'active' : ''}`}
            style={{flexDirection: 'column', alignItems: 'start'}}
            key={value.id}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemText id={labelId} primary={`${value.name}`} style={{margin: '0px', fontSize: '12px'}} />
            <div style={{fontSize: '10px'}}>{value.email} <br/> {value.company}</div>
          </ListItem>
        );
      })}
    </List>
  );

  useEffect(() => {
    setSelectedUsers(right)
  }, [right]);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChangeBuscaUserDisponivel = (busca:string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }

  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800),  [colunaBuscaUserDisponivel])


  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca

    try {
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 20))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar usuários disponiveis'
        )
      }
      setLeft(res.data.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])


  const handleCheckedRight = async () => {
    let itensRepetido:any = []
    for(let r = 0; r < right.length; r++) {
      for(let l = 0; l < leftChecked.length; l++) {
        if (right[r].id === leftChecked[l].id) {
          itensRepetido.push(leftChecked[l].id)
        }
      }
    }
    let itensUnicos = leftChecked.filter(item => !itensRepetido.includes(item.id))
    setRight(right.concat(itensUnicos));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = async () => {
    let itensRepetido:any = []
    for(let r = 0; r < left.length; r++) {
      for(let l = 0; l < rightChecked.length; l++) {
        try {
          if (right[r].id === rightChecked[l].id) {
            itensRepetido.push(rightChecked[l].id)
          }
        }
        catch{}
      }
    }
    let itensUnicos = rightChecked.filter(item => !itensRepetido.includes(item.id))
    setLeft(left.concat(itensUnicos));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget);
  };

  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null);
  };


  return (

    <div id={"listSelectUser"}>
      <Grid item xs={12} className='center'>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Paper variant="outlined" sx={{ minWidth:300, height: 300, overflow: 'auto' }}>
              <div style={{textAlign: 'center', fontSize: '12px', margin: '10px 0 8px 0px' }}>Usuário Disponíveis</div>
              <div className='p-10'>
                <TextField
                  size='small'
                  className='mb-10'
                  label='Pesquisa'
                  placeholder='Pesquisa'
                  inputProps={{ maxLength: 50 }}
                  value={searchUserDisponivel}
                  onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                  disabled={promiseInProgress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{backgroundColor: '$cor-primary', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                          aria-label="toggle password visibility"
                          onClick={(e) => handleClickBuscaUserDisponivel(e)}
                          edge="end"
                        >
                          <FilterAltIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {customList(left, 'Usuário Disponíveis')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || promiseInProgress}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || promiseInProgress}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper variant="outlined" sx={{minWidth:300, height: 300, overflow: 'auto' }}>
              <div style={{textAlign: 'center', fontSize: '12px', margin: '10px 0 8px 0px' }}>Usuários Selecionados</div>
              {customList(right, 'Usuário Selecionados')}
            </Paper>
          </Grid>
        </Grid>

      </Grid>

      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

      </Menu>


    </div>


  );
}


export default UserSelectList;