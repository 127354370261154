import { Fragment, useState, useEffect } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import {
  MenuItem,
  Select,
  IconButton
} from '@mui/material'

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import services from '../../services'

import './Pagination.scss'

interface IPagination {
  currentPage?: number
  totalPages: number
  pageSize?: number
  colunaBusca?: string
  search?:any
  handlePagination?: any
  itensLength?: number
  totalCount:number
  idRef?: number
}

const Pagination = (props: IPagination) => {
  const { currentPage, totalPages, pageSize, handlePagination, colunaBusca, search, itensLength, totalCount, idRef } = props
  const { promiseInProgress } = usePromiseTracker()

  const [paginacao, setPaginacao] = useState<number[] | []>([])

  useEffect(() => {
    setPaginacao(services.utils.getPaginationItens(totalPages, currentPage))
  }, [totalPages, currentPage])

  const handleChangePage = (page: number, busca: string, itemsPerPage: any) => {
    if (idRef) {
      handlePagination(page, busca, itemsPerPage, idRef)
    } else {
      handlePagination(page, busca, itemsPerPage)
    }
  }

  return (
    <>
      {currentPage && itensLength ?
        <div className='flex justify-between items-center'>
          <span>Total de Itens: {totalCount}</span>
          <div className='flex justify-end items-center gap-10'>
            <div className='table-pagination'>
              <IconButton 
                size="small" aria-label="primeira pagina" component="label" 
                onClick={() => handleChangePage(1, colunaBusca + search, pageSize )}
                disabled={promiseInProgress || currentPage <= 1}
              >
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
              <IconButton 
                size="small" aria-label="pagina anterior" component="label" 
                onClick={() => handleChangePage(currentPage - 1, colunaBusca + search, pageSize )}
                disabled={promiseInProgress || currentPage <= 1}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              {paginacao?.map(page => (
                <Fragment key={page.toString()}>
                  {page === currentPage ? <div className='table-pagination_number active'>{page.toString()}</div> : 
                  <IconButton 
                    className='table-pagination_number'
                    size="small" aria-label="Próxima pagina" component="label" 
                    onClick={() => handleChangePage(page, colunaBusca + search, pageSize )}
                  >
                    {page.toString()}
                  </IconButton>
                  }
                </Fragment>
              ))}
              <IconButton 
                size="small" aria-label="Próxima pagina" component="label" 
                onClick={() => handleChangePage(currentPage + 1, colunaBusca + search, pageSize )}
                disabled={promiseInProgress || currentPage >= totalPages}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton 
                size="small" aria-label="ultima pagina" component="label" 
                onClick={() => handleChangePage(totalPages, colunaBusca + search, pageSize )}
                disabled={promiseInProgress || currentPage >= totalPages}
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </div>
            <Select
              style={{width: '100px'}}
              fullWidth
              size="small"
              value={pageSize || ''}
              onChange={e =>
                handleChangePage(1, colunaBusca + search, e.target.value )
              }
              disabled={promiseInProgress}
            >
              <MenuItem value="10">10 Itens</MenuItem>
              <MenuItem value="20">20 Itens</MenuItem>
              <MenuItem value="50">50 Itens</MenuItem>
              <MenuItem value="100">100 Itens</MenuItem>
            </Select>
          </div> 
        </div>
        : 
        ''
      }
    </>
  )
}

export default Pagination
