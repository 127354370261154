import { useState, useCallback, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useSearchParams } from "react-router-dom";

import {
  Grid,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import { PageTitle } from '../../components/PageElements'

import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IBusca, IClientRecruter, IEmpresa } from '../../interfaces/index'

import TabelaEmpresas from './TabelaEmpresas'
import TabelaUsuarios from './TabelaUsuarios'
import services from '../../services/index.js'
import Tabs from '../../components/Tabs'

import './style.scss'

function Clientes() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [abaActive, setAbaActive] = useState(0)
  const [tabsList, setTabsList] = useState<any[] | []>([])

  const [step, setStep] = useState(0)
  const [empresaSelecionada, setEmpresaSelecionada] = useState<any>({})

  const [empresas, setEmpresas] = useState<IEmpresa[] | []>([])
  const [usuarios, setUsuarios] = useState<IClientRecruter[] | []>([])
  const [usuariosResponsaveis, setUsuariosResponsaveis] = useState<IClientRecruter[] | []>([])
  const [searchParams] = useSearchParams();

  const [objBusca, setObjBusca] = useState<any>(
    [
      {
        label: 'checkbox',
        value: false,
        searchRef: 'res',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'boolean'
      },
      {
        label: 'CR',
        value: '',
        searchRef: 'res',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Descrição do CR',
        value: '',
        searchRef: 'desc01',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Serviço',
        value: '',
        searchRef: 'afkDescri',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Razão Social',
        value: '',
        searchRef: 'nome',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Endereço',
        value: '',
        searchRef: 'end',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Bairro',
        value: '',
        searchRef: 'bairro',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'CEP',
        value: '',
        searchRef: 'cep',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Estado',
        value: '',
        searchRef: 'est',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'CNPJ',
        value: '',
        searchRef: 'cgc',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Grupo do cliente',
        value: '',
        searchRef: 'xnGrup',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Bloqueado',
        value: '',
        searchRef: 'bloq',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: '1', descrition: 'Bloqueado'},
          {id: '2', descrition: 'Desbloqueado'},
        ],
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [objBuscaUsuarios, setObjBuscaUsuarios] = useState<any>(
    [
      {
        label: 'ID do usuário',
        value: '',
        searchRef: 'user@id',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'user@name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'E-mail',
        value: '',
        searchRef: 'user@email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Adicionado em',
        value: '',
        searchRef: 'createdAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Empresa',
        value: '',
        searchRef: 'user@company',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Principal',
        value: '',
        searchRef: 'principal',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [objBuscaUsuariosRes, setObjBuscaUsuariosRes] = useState<any>(
    [
      {
        label: 'ID do usuário',
        value: '',
        searchRef: 'user@id',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'user@name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'E-mail',
        value: '',
        searchRef: 'user@email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Adicionado em',
        value: '',
        searchRef: 'createdAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Empresa',
        value: '',
        searchRef: 'user@company',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Adicionado automaticamente',
        value: '',
        searchRef: 'addedbyjob',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Gerente',
        value: '',
        searchRef: 'manager',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Supervisor',
        value: '',
        searchRef: 'supervisor',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Principal',
        value: '',
        searchRef: 'principal',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'true', descrition: 'Sim'},
          {id: 'false', descrition: 'Não'},
        ],
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )
  
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)

  const [currentPageUsers, setCurrentPageUsers] = useState(1)
  const [totalPagesUsers, setTotalPagesUsers] = useState(1)
  const [pageSizeUsers, setPageSizeUsers] = useState(20)
  const [totalCountUsers, setTotalCountUsers] = useState(0)

  const [currentPageResponsavel, setCurrentPageResponsavel] = useState(1)
  const [totalPagesResponsavel, setTotalPagesResponsavel] = useState(1)
  const [pageSizeResponsavel, setPageSizeResponsavel] = useState(20)
  const [totalCountResponsavel, setTotalCountResponsavel] = useState(0)

  //////////////////////////////////////////////////////////////////////////////////

  const getEmpresas = useCallback(async (_objBusca?: IBusca) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const res = await trackPromise(services.api.getEmpresasCliente(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setEmpresas(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getEmpresas({page: newPage, pageSize: itensPerPage, busca: objBusca })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objBusca])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getEmpresas({page:1,  pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  //////////////////////////////////////////////////////////////////////////////////

  const getUsuarios = useCallback(async (_objBusca?: IBusca, unidadeId?: number) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      
      paramns.filterBy = 'protheusCttid'
      paramns.filterValue = unidadeId ? unidadeId : empresaSelecionada.cttId

      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const res = await trackPromise(services.api.getRecruitmentCustomerUser(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setUsuarios(res.data.data)
      setCurrentPageUsers(res.data.pageIndex)
      setPageSizeUsers(res.data.pageSize)
      setTotalPagesUsers(res.data.totalPages)
      setTotalCountUsers(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handlePaginationUsers = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getUsuarios({page: newPage,  pageSize:itensPerPage, busca: objBuscaUsuarios }, empresaSelecionada.cttId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objBuscaUsuarios, empresaSelecionada.cttId])

  const handleBuscaUsers = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getUsuarios({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc}, empresaSelecionada.cttId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, empresaSelecionada.cttId])

  //////////////////////////////////////////////////////////////////////////////////

  const getUsuariosReponsible = useCallback(async (_objBusca?: IBusca, unidadeId?: number) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
     
      paramns.filterBy = 'protheusCttid'
      paramns.filterValue = unidadeId ? unidadeId : empresaSelecionada.cttId

      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const resR = await trackPromise(services.api.getRecruiters(paramns, pagina, tamanhoPagina))
      if (resR.fail) {
        throw new Error(
          resR.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setUsuariosResponsaveis(resR.data.data)
      setCurrentPageResponsavel(resR.data.pageIndex)
      setPageSizeResponsavel(resR.data.pageSize)
      setTotalPagesResponsavel(resR.data.totalPages)
      setTotalCountResponsavel(resR.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handlePaginationUsersReponsible = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getUsuariosReponsible({page: newPage, pageSize: itensPerPage, busca: busca }, empresaSelecionada.cttId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[empresaSelecionada.cttId])

  const handleBuscaUsersReponsible = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getUsuariosReponsible({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc }, empresaSelecionada.cttId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, empresaSelecionada.cttId])

  //////////////////////////////////////////////////////////////////////////////////

  const handleEditUser = useCallback(async (user:IClientRecruter, type: string) => {
    if (type === 'UserReponsavel') {
      try {
        const res = await trackPromise(services.api.incluirResponsavelIntoUnidade(user.id, empresaSelecionada.cttId, false))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível incluir Usuários'
          )
        }
        getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.incluirUserIntoUnidade(user.id, empresaSelecionada.cttId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível incluir Usuários'
          )
        }
        getUsuarios({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handleSetAsPrincipal = useCallback(async (user:IClientRecruter, type: string) => {
    try {
      const res = await trackPromise(services.api.setUserAsResponsible(user.userId, empresaSelecionada.cttId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível mudar para principal'
        )
      }
      getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handleSetAsSupervisor = useCallback(async (user:IClientRecruter, type: string) => {
    try {
      const res = await trackPromise(services.api.setUserAsSupervisor(user.userId, empresaSelecionada.cttId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível mudar para supervisor'
        )
      }
      getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handleSetAsManager = useCallback(async (user:IClientRecruter, type: string) => {
    try {
      const res = await trackPromise(services.api.setUserAsManager(user.userId, empresaSelecionada.cttId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível mudar para manager'
        )
      }
      getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handleSetAsAddedbyjob = useCallback(async (user:IClientRecruter, type: string) => {
    try {
      const res = await trackPromise(services.api.setUserAsAddedbyjob(user.userId, empresaSelecionada.cttId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível mudar para adicionado automaticamente'
        )
      }
      getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresaSelecionada.cttId)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaSelecionada.cttId])

  const handleRemoveUser = useCallback(async (user:IClientRecruter, type: string) => {
    if (type === 'UserReponsavel') {
      try {
        const res = await trackPromise(services.api.excluirResponsavelIntoUnidade(user.userId, empresaSelecionada.cttId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível incluir Usuários'
          )
        }
        notify('Usuário removido', { variant: 'success' })
        const indexItemAdicionado = services.utils.findIndexInArray(user.id, usuariosResponsaveis, 'id')
        setUsuariosResponsaveis((prevState:any) => ([
            ...prevState.slice(0, indexItemAdicionado),
            ...prevState.slice(indexItemAdicionado + 1)
          ]
        ))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.excluirUserIntoUnidade(user.id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível incluir Usuários'
          )
        }
        notify('Usuário removido', { variant: 'success' })
        const indexItemAdicionado = services.utils.findIndexInArray(user.id, usuarios, 'id')
        setUsuarios((prevState:any) => ([
            ...prevState.slice(0, indexItemAdicionado),
            ...prevState.slice(indexItemAdicionado + 1)
          ]
        ))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarios, usuariosResponsaveis, empresaSelecionada.cttId])

  const goToStep = (stepNumber: number) => {
    setStep(stepNumber)
  }

  const selectEmpresa = async (empresa: any)  => {
    // setCurrentPageUnidades(1)
    // setPageSizeUnidades(20)
    // getUnidades({page: 1, busca: '', pageSize: 20 }, empresa.id)
    setEmpresaSelecionada(empresa)

    if (
      userRoles.includes("recruitermaps_delete") || 
      userRoles.includes("recruitermaps_delete_any")
    ) {
      setCurrentPageResponsavel(1)
      setPageSizeResponsavel(20)
      getUsuariosReponsible({page: 1, busca: '', pageSize: 20 }, empresa.cttId)
    }
    if (
      userRoles.includes("crusermap_delete") || 
      userRoles.includes("crusermap_delete_any")
    ) {
      setCurrentPageUsers(1)
      setPageSizeUsers(20)
      getUsuarios({page: 1, busca: '', pageSize: 20 }, empresa.cttId)
    }

    setStep(2)
  }

  useEffect(() => {
    getEmpresas()
  }, [])

  useEffect(() => {
    let itemsTab = [];
    if (
      userRoles.includes("recruitermaps_read") ||
      userRoles.includes("recruitermaps_read_any")
    ) {
      itemsTab.push({id: 0, label: 'Atendentes'})
    }
    if (
      userRoles.includes("crusermap_read") ||
      userRoles.includes("crusermap_read_any")
    ) {
      itemsTab.push({id: 1, label: 'Clientes'})
    }
    if (itemsTab.length) {
      setAbaActive(itemsTab[0].id)
    }
    setTabsList(itemsTab)
  }, [])

  useEffect(() => {
    const objQuery = {
      cttId: searchParams.get('cttId'),
      res: searchParams.get('res'),
      cgc: searchParams.get('cgc'),
      desc01: searchParams.get('desc01'),
      type: searchParams.get('type')
    }

    if(objQuery.cttId && objQuery.res && objQuery.cgc && objQuery.desc01 && objQuery.type) {
      // setStep(2)
      // setEmpresaSelecionada(
      //   {
      //     res: objQuery.res,
      //     desc01: objQuery.desc01,
      //     cgc: objQuery.cgc,
      //     cttId: objQuery.cttId
      //   }
      // )
      setAbaActive(objQuery.type === 'UserReponsavel' ? 0 : 1)
      selectEmpresa(
        {
          res: objQuery.res,
          desc01: objQuery.desc01,
          cgc: objQuery.cgc,
          cttId: objQuery.cttId
        }
      )
    }
    console.log('query', objQuery);
  }, [])
  

  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
          <PageTitle subText={'Gerencie a relação de usuários'}>
            <div> Clientes </div>
          </PageTitle>

          <Grid container justifyContent="center" spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              {step === 0 && 
                <div className='panel'>
                  <div className='panel-body'>
                    <TabelaEmpresas 
                      mainList={empresas} 
                      editRegistro={selectEmpresa}
                      handlePagination={handlePagination}
                      handleBusca={handleBusca}
                      objBusca={objBusca}
                      setObjBusca={setObjBusca}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      pageSize={pageSize}
                      totalCount={totalCount}
                    />
                  </div>
                </div>
              }
                  
              {step === 2 && 
                <>
                  <Tabs tabs={tabsList} active={abaActive} onChange={setAbaActive} />
                  <div className='panel'>
                    <div className='panel-body'>
                      <div className='flex items-center'>
                        <IconButton 
                          size='small'
                          color="inherit"
                          onClick={() => goToStep(0)}
                        >
                          <ArrowBackIcon fontSize='small'/>
                        </IconButton>
                        <span>Local: {empresaSelecionada.res} - {empresaSelecionada.desc01} - {services.mask.cnpj(empresaSelecionada.cgc)} </span>
                      </div>
                      {
                        (
                          userRoles.includes("recruitermaps_read") ||
                          userRoles.includes("recruitermaps_read_any")
                        ) &&
                        abaActive === 0 &&
                        <TabelaUsuarios 
                          titleTable={''}
                          type={'UserReponsavel'}
                          mainList={usuariosResponsaveis} 
                          editRegistro={handleEditUser}
                          setAsPrincipal={handleSetAsPrincipal}
                          setAsSupervisor={handleSetAsSupervisor}
                          setAsManager={handleSetAsManager}
                          setAsAddedbyjob={handleSetAsAddedbyjob}
                          removeRegistro={handleRemoveUser}
                          handlePagination={handlePaginationUsersReponsible}
                          handleBusca={handleBuscaUsersReponsible}
                          currentPage={currentPageResponsavel}
                          totalPages={totalPagesResponsavel}
                          pageSize={pageSizeResponsavel}
                          totalCount={totalCountResponsavel}

                          objBusca={objBuscaUsuariosRes}
                          setObjBusca={setObjBuscaUsuariosRes}
                        />
                      }

                      {
                        (
                          userRoles.includes("crusermap_read") ||
                          userRoles.includes("crusermap_read_any")
                        ) &&
                        abaActive === 1 &&
                        <TabelaUsuarios 
                          titleTable={''}
                          type={'UserRegular'}
                          mainList={usuarios} 
                          editRegistro={handleEditUser}
                          removeRegistro={handleRemoveUser}
                          handlePagination={handlePaginationUsers}
                          handleBusca={handleBuscaUsers}
                          currentPage={currentPageUsers}
                          totalPages={totalPagesUsers}
                          pageSize={pageSizeUsers}
                          totalCount={totalCountUsers}

                          objBusca={objBuscaUsuarios}
                          setObjBusca={setObjBuscaUsuarios}
                        />
                      }
                    </div>
                  </div>
                </>
              }
            </Grid>
          </Grid>
      </div>
      
    </div>
  )
}

export default Clientes
