import { useState, useEffect, useCallback } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'

import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IChamado, IOverview } from '../../interfaces/index'
import TabelaChamados from './TabelaChamados'
import ChamadosTotais from './ChamadosTotais'
import services from '../../services/index.js'

import avG from '../../assets/img/icon-avatar-g.svg';
import avP from '../../assets/img/icon-avatar-p.svg';
import blG from '../../assets/img/icon-building-g.svg';
import blP from '../../assets/img/icon-building-p.svg';

import './style.scss'

function Chamados() {
  const navigate = useNavigate();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()


  const [modalNewChamado, setModalNewChamado] = useState<boolean>(false)
  const [tipoChamadoSelected, setTipoChamadoSelected] = useState<string | null>()
  const [formaChamadoSelected, setFormaChamadoSelected] = useState<number | string | null>()
  const [tiposContrato, setTiposContrato] = useState<any>([])
  const [formasChamadosOpen, setFormasChamadosOpen] = useState<any>([
    {
      label: 'Um local com múltiplas funções',
      id: 'multi-funcoes-por-local'
    },
    {
      label: 'Uma função para múltiplos locais',
      id: 'multi-locais-por-funcoes'
    }
  ])

  // console.log('userRoless', userRoles)
  const [objBusca, setObjBusca] = useState<any>(
    [
      {
        label: 'Id',
        value: '',
        searchRef: 'id',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Local',
        value: '',
        searchRef: 'recruitmentTicketData@protheusCtt@ctT_DESC01',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'CNPJ do cliente',
        value: '',
        searchRef: 'recruitmentTicketData@protheusSa1@a1_CGC',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'CR',
        value: '',
        searchRef: 'recruitmentTicketData@protheusCtt@ctT_RES',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null
      },

      {
        label: 'Cliente',
        value: '',
        searchRef: 'customerUser@name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'E-mail Cliente',
        value: '',
        searchRef: 'customerUser@email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Nome Atendente',
        value: '',
        searchRef: 'attendant@name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'E-mail Atendente',
        value: '',
        searchRef: 'attendant@email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Filial',
        value: '',
        searchRef: 'recruitmentTicketData@branch@razaoSocial',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Função',
        value: '',
        searchRef: 'recruitmentTicketData@functionName',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Quantidade',
        value: '',
        searchRef: 'recruitmentTicketData@quantity',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Aberto em',
        value: '',
        searchRef: 'recruitmentTicketData@createAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Última atualização',
        value: '',
        searchRef: 'updatedAt',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Data de início',
        value: '',
        searchRef: 'recruitmentTicketData@startDate',
        inputVisible: false,
        showOrderby: true,
        showSearch: false,
        selectOptions: null,
        type: 'date'
      },
      {
        label: 'Nota',
        value: '',
        searchRef: 'satisfaction',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'number'
      },
      {
        label: 'Status',
        value: '',
        searchRef: 'status',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          {id: 'IN_PROGRESS', descrition: 'Em Andamento'},
          {id: 'FINISHIED', descrition: 'Chamados Finalizados'},
          {id: 'CANCELED', descrition: 'Chamados Cancelados'},
          {id: 'AWAITING_CANCELATION_APROVEMENT', descrition: 'Aguardando aprovação de cancelamento'},
          {id: 'AWAITING_FINISH_APROVEMENT', descrition: 'Aguardando aprovação de conclusão'},
          {id: 'AWAITING_START', descrition: 'Aguardando Início'},
          {id: 'AWAITING_ATTENDANT', descrition: 'Aguardando Atendente'},
          {id: 'AWAITING_REVISION', descrition: 'Aguardando revisão'},
          {id: 'IN_REVISION', descrition: 'Em revisão'},
          {id: 'CANCELED_PARTIAL', descrition: 'Cancelado parcial'},
        ],
        type: 'string'
      },
      
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [chamados, setChamados] = useState<IChamado[] | []>([])
  const [overview, setOverview] = useState<IOverview>({})
  const [overviewActive, setOverviewActive] = useState<string>('')

  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)

  const handleCloseConfigSolicitacao = () => {
    setModalNewChamado(false)
    setFormaChamadoSelected('')
    setTipoChamadoSelected('')
  }
  
  const getChamados = useCallback(async (_objBusca?: any) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns:any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const datePikerInputs:any = document.getElementById('datePicker_input')
      const dataInicio:any = services.utils.formatStringDate(datePikerInputs.getElementsByTagName("input")[0]?.value)
      const dataFim:any = services.utils.formatStringDate(datePikerInputs.getElementsByTagName("input")[1]?.value)
      const dataType:any = document.getElementById('input_chamado_data_type') 

      let dataTypeQuery = dataType?.children[0]?.children[1]?.children[1]?.value
      if (dataInicio && dataFim && dataTypeQuery) {
        paramns.adicionalQuery += paramns.adicionalQuery ? `&${dataTypeQuery}At_between=${dataInicio} 23:59:59@${dataFim} 00:00:00` : `?${dataTypeQuery}At_between=${dataInicio} 23:59:59@${dataFim} 00:00:00`
      }
      if (dataInicio && !dataFim && dataTypeQuery) {
        paramns.adicionalQuery += paramns.adicionalQuery ? `&${dataTypeQuery}At_after=${dataInicio} 23:59:59` : `?${dataTypeQuery}At_after=${dataInicio} 23:59:59`
      }
      if (!dataInicio && dataFim && dataTypeQuery) {
        paramns.adicionalQuery += paramns.adicionalQuery ? `&${dataTypeQuery}At_before=${dataFim} 00:00:00` : `?${dataTypeQuery}At_after=${dataInicio} 23:59:59`
      }
      let res:any = null
      if(userRoles.includes('ticket_res_read_any')) {
        res = await trackPromise(services.api.getChamadosRes(paramns, pagina, tamanhoPagina))
      } else {
        res = await trackPromise(services.api.getChamadosResRelated(paramns, pagina, tamanhoPagina))
      }
     
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setChamados(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: any, itensPerPage: number) => {
    getChamados({page: newPage, pageSize:itensPerPage, busca: objBusca })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objBusca])

  const handleBusca = useCallback(async (busca: any, sizePage:number, coluna?: string, desc?: boolean) => {
    getChamados({page:1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const handleNewChamado = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.tipoContrato())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar tipo chamado'
        )
      }
      setTiposContrato(res.data.map((item:any) => {
        return {label: item, id: item }
      }))
      setModalNewChamado(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToNewChamado = async () => {
    if (!formaChamadoSelected) {
      notify('Informe uma Forma de abertura do chamado', { variant: 'warning' })
      return false
    }
    if (!tipoChamadoSelected) {
      notify('Informe um Tipo de Contrato para o chamado', { variant: 'warning' })
      return false
    }
    // const tipoChamdoIndex = services.utils.findIndexInArray(tipoChamadoSelected, tiposContrato, 'id')
    // let isTemporario = '0'
    // if (tiposContrato[tipoChamdoIndex].label.toUpperCase().includes('TEMPORARIA')) {
    //   isTemporario = 'TEMPORARIA'
    // }

    let isTemporario = '0'
    if (tipoChamadoSelected?.toUpperCase().includes('TEMPORARIA')) {
      isTemporario = 'TEMPORARIA'
    }

    navigate(`/app/chamados/novo/${formaChamadoSelected}/${isTemporario}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const handleSelectChamado = useCallback(async (chamado: any) => {
    navigate(`/app/chamados/detalhe/${chamado.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleSelectOverview = useCallback(async (status: string) => {
    setOverviewActive(status)
    let stringBusca = status
    if (status === 'ALL') {
      stringBusca = ''
      setObjBusca((prevState:any) => [
        ...prevState.slice(0, prevState.length - 2),
        {...prevState[prevState.length - 2], value: stringBusca},
        ...prevState.slice(prevState.length - 1),
      ])
      objBusca[objBusca.length - 2].value = stringBusca
      getChamados({page: 1, pageSize: 20, busca: objBusca, orderByColumn: 'id', desc: true})
    } else {
      setObjBusca((prevState:any) => [
        ...prevState.slice(0, prevState.length - 2),
        {...prevState[prevState.length - 2], value: stringBusca},
        ...prevState.slice(prevState.length - 1),
      ])
      objBusca[objBusca.length - 2].value = stringBusca
      getChamados({page: 1, pageSize: 20, busca: objBusca})
    }
    
  },[objBusca])
  
  const getOverview = async () => {
    if (userRoles.includes("ticket_res_overview_any") || userRoles.includes("ticket_res_overview")) {
      try {
        let res = null
        if (userRoles.includes("ticket_res_overview_any")) {
          res = await trackPromise(services.api.getOverview())
        } else {
          res = await trackPromise(services.api.getOverviewRelated())
        }
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Overveiw'
          )
        }
        setOverview(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
  }

  useEffect(() => {
    getChamados()
    getOverview()
  }, [])

  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle subText={'Crie e acompanhe chamados'}>
          <div>Meus Chamados</div>
        </PageTitle>

        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={12} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                {  
                  (
                    userRoles.includes("ticket_res_overview_any") || 
                    userRoles.includes("ticket_res_overview")
                  ) && 
                  <div className='wrapper-page-content-interna'>
                    <div className='flex justify-center'>
                      <ChamadosTotais onSelect={handleSelectOverview} status={'ALL'} value={overview.total} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'IN_PROGRESS'} value={overview.inProgress} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'FINISHIED'} value={overview.finishied} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'CANCELED'} value={overview.canceled} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'AWAITING_CANCELATION_APROVEMENT'} value={overview.awaitingCancelationAprovement} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'AWAITING_FINISH_APROVEMENT'} value={overview.awaitingFinishAprovement} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'AWAITING_START'} value={overview.awaitingStart} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'AWAITING_ATTENDANT'} value={overview.awaitingAttendant} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'AWAITING_REVISION'} value={overview.awaitingRevision} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'IN_REVISION'} value={overview.inRevision} active={overviewActive} />
                      <ChamadosTotais onSelect={handleSelectOverview} status={'CANCELED_PARTIAL'} value={overview.partialCanceled} active={overviewActive} />
                    </div>
                  </div>
                }
                <TabelaChamados 
                  mainList={chamados} 
                  newRegister={handleNewChamado}
                  editRegistro={handleSelectChamado}
                  handlePagination={handlePagination}
                  handleBusca={handleBusca}
                  objBusca={objBusca}
                  setObjBusca={setObjBusca}
                  reloadOverview={getOverview}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  overviewActive={overviewActive}
                />
              </div>
            </div>
          </Grid>
        </Grid>

      </div>

      {/* show hide columns */}
      <Modal
        open={modalNewChamado}
        close={handleCloseConfigSolicitacao}
        titulo={'Configuração da solicitação'}
      >
        <Grid container spacing={{ xs: 3 }} className="sm-min-500">
          <Grid item xs={12} className="flex justify-center">
            <FormControl style={{maxWidth: '590px', marginTop: '20px'}}>
              <InputLabel shrink>Tipo de Contrato</InputLabel>
              <Select
                fullWidth
                required
                notched
                size="small"
                label="Tipo de Contrato"
                value={tipoChamadoSelected || ''}
                onChange={e => 
                  setTipoChamadoSelected(e.target.value)
                }
              >
                <MenuItem value={""} >
                  Selecione
                </MenuItem>
                {
                  tiposContrato.map((typeContract: any) => (
                    <MenuItem value={typeContract.id} key={typeContract.id} >
                      {typeContract.label}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl>
              <InputLabel shrink>Forma de abertura</InputLabel>
              <Select
                fullWidth
                required
                notched
                size="small"
                label="Forma de abertura"
                value={formaChamadoSelected || ''}
                onChange={e => 
                  setFormaChamadoSelected(e.target.value)
                }
              >
                <MenuItem value={""} >
                  Selecione
                </MenuItem>
                {
                  formasChamadosOpen.map((forma: any) => (
                    <MenuItem value={forma.id} key={forma.id} >
                      {forma.label}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} className='flex justify-center gap-20'>
            <div 
              onClick={() => setFormaChamadoSelected('multi-funcoes-por-local')}
              className={`box_type_chamado ${formaChamadoSelected === 'multi-funcoes-por-local' ? 'active' : ''}`}
            >
              <div>
                {
                  formaChamadoSelected === 'multi-funcoes-por-local' ? <img src={blP} width={38} alt=""/> : <img src={blG} width={38} alt=""/>
                }
              </div>
              <div className='flex gap10'>
                {
                  formaChamadoSelected === 'multi-funcoes-por-local' ? 
                  <>
                    <img src={avP} width={20} alt=""/>
                    <img src={avP} width={20} alt=""/>
                    <img src={avP} width={20} alt=""/>
                    <img src={avP} width={20} alt=""/>
                  </>
                  : 
                  <>
                    <img src={avG} width={20} alt=""/>
                    <img src={avG} width={20} alt=""/>
                    <img src={avG} width={20} alt=""/>
                    <img src={avG} width={20} alt=""/>
                  </>
                }
              </div>
              <div>
                Um local com múltiplas funções
              </div>
            </div>
            <div 
              onClick={() => setFormaChamadoSelected('multi-locais-por-funcoes')}
              className={`box_type_chamado ${formaChamadoSelected === 'multi-locais-por-funcoes' ? 'active' : ''}`}
            >
              <div>
                {
                  formaChamadoSelected === 'multi-locais-por-funcoes' ? <img src={avP} width={38} alt=""/> : <img src={avG} width={38} alt=""/>
                }
              </div>
              <div className='flex gap10'>
                {
                  formaChamadoSelected === 'multi-locais-por-funcoes' ? 
                  <>
                    <img src={blP} width={22} alt=""/>
                    <img src={blP} width={22} alt=""/>
                    <img src={blP} width={22} alt=""/>
                    <img src={blP} width={22} alt=""/>
                  </>
                  : 
                  <>
                    <img src={blG} width={22} alt=""/>
                    <img src={blG} width={22} alt=""/>
                    <img src={blG} width={22} alt=""/>
                    <img src={blG} width={22} alt=""/>
                  </>
                }
              </div>
              <div>
                Uma função para múltiplos locais
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success" 
              type="button"
              size='small'
              onClick={goToNewChamado}
            >
              Prosseguir
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  )
}

export default Chamados
