import { memo, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { Link, useLocation } from 'react-router-dom'

import {
  Grid,
  TextField,
  Button,
  Tooltip,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

import { useNotification } from '../../hooks/useNotification.js'
import { IUser } from '../../interfaces/index'
import services from '../../services/index.js'
import Modal from '../Modal'

import { useAuth } from '../../hooks/useAuth'
import { useLoading } from '../../hooks/useLoading'

import './menu.scss'
import DashboardRules from '../../views/DashboardRules'

const SystemMenu = () => {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')

  const { removeLoading } = useLoading()
  const { notify } = useNotification()
  const { promiseInProgress } = usePromiseTracker();

  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [oldSenha, setOldSenha] = useState('')
  const [modalUsuario, setModalUsuario] = useState(false)
  const [userActive, setUserActive] = useState<IUser>(
    {
      name: '',
      phone: '',
      loginBlocked: false,
      updatePasswordOnLogin: false,
      username: '',
      email: '',
      company: '',
      internal: false,
      role: '',
      active: true,
      // disabledByLoginTime: false,
      // unblockDate: false
    }
  )

  useEffect(() => {
    document.body.classList.remove('m-s-o')
    removeLoading()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    let subMenu = document.getElementById("subMenu-chamados")
    if(
      ['chamados'].includes(splitLocation[2])
    ) {
      if (subMenu) subMenu.classList.add('active')
    } else {
      if (subMenu) subMenu.classList.remove('active')
    }

    subMenu = document.getElementById("subMenu-comptrade")
    if(
      ['comptrade'].includes(splitLocation[2])
    ) {
      if (subMenu) subMenu.classList.add('active')
    } else {
      if (subMenu) subMenu.classList.remove('active')
    }

    subMenu = document.getElementById("subMenu-trct")
    if(
      ['trct'].includes(splitLocation[2])
    ) {
      if (subMenu) subMenu.classList.add('active')
    } else {
      if (subMenu) subMenu.classList.remove('active')
    }
    
    subMenu = document.getElementById("subMenu-integracao")
    if(
      ['dash_management'].includes(splitLocation[2])
    ) {
      if (subMenu) subMenu.classList.add('active')
    } else {
      if (subMenu) subMenu.classList.remove('active')
    }

    subMenu = document.getElementById("subMenu-clientes")
    if(
      ['clientes'].includes(splitLocation[2])
    ) {
      if (subMenu) subMenu.classList.add('active')
    } else {
      if (subMenu) subMenu.classList.remove('active')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitLocation])

  const openColapseMenu = (subMenuId: string) => {
    const subMenu = document.getElementById(subMenuId)
    if (subMenu) subMenu.classList.toggle('show')
  }

  const handleAutoCloseMenu = () => {
    if (document.body.clientWidth > 768) {
      closeMainMenu()
    }
  }

  const closeMainMenu = () => {
    document.body.classList.remove("m-l-o");
    document.body.classList.remove("m-s-o");
  }

  const saveUpdateUser = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    async function editarUsuario() {
      try {
        const res = await trackPromise(services.api.editUsuarioMe(userActive, oldSenha))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar cadastro'
          )
        }
        notify('Cadastro editado com sucesso', { variant: 'success' })
        setModalUsuario(false)
        setConfirmarSenha('')
        setOldSenha('')
        setUserActive(prevState => ({
          ...prevState,
          editado: false
        }))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (!oldSenha) {
      notify('Informe sua senha atual', { variant: 'warning' })
      return
    }

    if (userActive.id) {
      if (userActive.password) {
        if (userActive.password.length < 6) {
          notify('Informe uma senha com pelo menos 6 caracteres', { variant: 'warning' })
          return
        }
        if (userActive.password !== confirmarSenha) {
          notify('Senhas informadas não são iguais', { variant: 'warning' })
          return
        }
        editarUsuario()
      } else {
        notify('Informe uma nova senha', { variant: 'warning' })
      }
    }
  }

  const getDadosPessoais = async () => {
    try {
      const res = await trackPromise(services.api.getUserMe())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível carregar daddos'
        )
      }
      
      setModalUsuario(true)
      setOldSenha('')
      setUserActive(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }

  const toggleContainerMenu = () => {
    const menu_colapse = document.body.classList.contains("menu_colapse");
    if (menu_colapse) {
      document.body.classList.remove("menu_colapse");
    } else {
      document.body.classList.add("menu_colapse");
    }
  }

  return (
    <>
      <div className="system-menu-sidebar">
        <div className='system-menu-sidebar_titleMenu_close' style={{fontSize: '14px', textAlign: 'center', fontWeight: 'bold'}}>
          <div>Menu</div> <span onClick={closeMainMenu}><HighlightOffIcon /></span>
        </div>
        <div className='system-menu-sidebar_titleMenu toggle_mainMenu' onClick={toggleContainerMenu} >
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <ul>
          {
            userRoles.includes('showmenu_bi_dashboards') && 
            <li className={splitLocation[2] === 'dashboard' ? 'active' : ''}>
              <Link to="/app/dashboard" onClick={closeMainMenu} className='flex justify-between items-center'>
                <span>Dashboards</span>  
                <DashboardIcon
                  fontSize="small"
                  className="subMenu-icon-arrow"
                />
                <Tooltip title="Dashboards"  className="subMenu-icon"   placement="right">
                  <DashboardIcon 
                    fontSize="small" 
                  />
                </Tooltip>
              </Link>
            </li>
          }
          {
            userRoles.includes('showmenu_bi_dashboards_edit') && 
            <li
              id="subMenu-integracao"
            >
              <button
                className={
                  ['dash_management'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-integracao')}
              >
                <span>
                  Configuração do BI
                </span>
                <ArrowForwardIosIcon
                  fontSize="small"
                  className="subMenu-icon-arrow"
                />
                <Tooltip title="Configurações dos dashs" className="subMenu-icon" onClick={toggleContainerMenu}  placement="right">
                  <DisplaySettingsIcon 
                    fontSize="small" 
                  />
                </Tooltip>
               
              </button>
              <ul className="system-menu-sidebar-inside">

                <li
                  className={
                    ['functionalities'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{ paddingTop: '0px' }}
                >
                  <Link
                    to="/app/dash_management/functionalities"
                    onClick={closeMainMenu}
                  >
                    Dashboards
                  </Link>
                </li>

                <li
                  className={
                    ['users'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    to="/app/dash_management/users"
                    onClick={closeMainMenu}
                  >
                    Equipes
                  </Link>
                </li>


                <li
                  className={
                    ['rules'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    to="/app/dash_management/rules"
                    onClick={closeMainMenu}
                  >
                    Regras
                  </Link>
                </li>

              </ul>
            </li>
          }
          {
            userRoles.includes('showmenu_ticket_res') &&
            <li id="subMenu-chamados">
              <button
                className={
                  ['chamados'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-chamados')}
              >
                <span>Chamados</span>
                <ArrowForwardIosIcon
                  fontSize="small" className="subMenu-icon-arrow"
                />
                <Tooltip title="Chamados" className="subMenu-icon" onClick={toggleContainerMenu} placement="right">
                  <RecentActorsIcon 
                    fontSize="small" 
                  />
                </Tooltip>
              </button>
              <ul className='system-menu-sidebar-inside'>
                <li
                  className={
                    ['meus-chamados'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/chamados/meus-chamados"
                    onClick={closeMainMenu}
                  >
                    Recrutamento e Seleção
                  </Link>
                </li>
              </ul>
            </li>
          }
          {
            userRoles.includes('showmenu_recruitermaps') && 
            <li id="subMenu-clientes">
              <button
                className={
                  ['clientes'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-clientes')}
              >
                <span>Vinculos</span>
                <ArrowForwardIosIcon 
                  fontSize="small" className="subMenu-icon-arrow"
                />
                <Tooltip title="Vinculos" className="subMenu-icon" onClick={toggleContainerMenu} placement="right">
                  <MultipleStopIcon 
                    fontSize="small" 
                  />
                </Tooltip>
              </button>
              <ul className='system-menu-sidebar-inside'>
                <li
                  className={
                    ['listagem'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/clientes/listagem"
                    onClick={closeMainMenu}
                  >
                    Clientes
                  </Link>
                </li>
                <li
                  className={
                    ['usuarios'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/clientes/usuarios"
                    onClick={closeMainMenu}
                  >
                    Usuários
                  </Link>
                </li>
              </ul>
            </li>
          }



          {
            userRoles.includes('comptrade_read_est') &&
            <li id="subMenu-comptrade">
              <button
                className={
                  ['comptrade'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-comptrade')}
              >
                <span>Comptrade</span>
                <ArrowForwardIosIcon
                  fontSize="small" className="subMenu-icon-arrow"
                />
                <Tooltip title="Estabelecimentos" className="subMenu-icon" onClick={toggleContainerMenu} placement="right">
                  <MultipleStopIcon
                    fontSize="small"
                  />
                </Tooltip>
              </button>
              <ul className='system-menu-sidebar-inside'>
                <li
                  className={
                    ['est'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/comptrade/est"
                    onClick={closeMainMenu}
                  >
                    Estabelecimentos
                  </Link>
                </li>
              </ul>
            </li>
          }

          {
            userRoles.includes('showmenu_trct') &&
            <li id="subMenu-trct">
              <button
                className={
                  ['trct'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-trct')}
              >
                <span>Kit de Documentos</span>
                <ArrowForwardIosIcon
                  fontSize="small" className="subMenu-icon-arrow"
                />
                <Tooltip title="Estabelecimentos" className="subMenu-icon" onClick={toggleContainerMenu} placement="right">
                  <MultipleStopIcon
                    fontSize="small"
                  />
                </Tooltip>
              </button>
              <ul className='system-menu-sidebar-inside'>
                <li
                  className={
                    pathname.includes("trct/list")
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/trct/list"
                    onClick={closeMainMenu}
                  >
                    Documentos
                  </Link>
                </li>
                <li
                  className={
                    pathname.includes("trct/include")
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/trct/include"
                    onClick={closeMainMenu}
                  >
                    Importações
                  </Link>
                </li>
                <li
                  className={
                    pathname.includes("trct/log")
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/trct/log"
                    onClick={closeMainMenu}
                  >
                    Chatlog
                  </Link>
                </li>
              </ul>
            </li>
          }


          <li>
            <button className='btn-noStile' onClick={getDadosPessoais}>

              <span>Meus dados</span>
              <PersonIcon
                fontSize="small"
                className="subMenu-icon-arrow"
              />
              <Tooltip title="Meus dados"  className="subMenu-icon"   placement="right">
                <PersonIcon
                  fontSize="small"
                />
              </Tooltip>
            </button>
          </li>
           {/* <UserMenu action={getDadosPessoais} /> */}
        </ul>
      </div>
      <span className="system-menu-sidebar-backdrop" onMouseEnter={handleAutoCloseMenu}></span>

      {/* modal usuário */}
      <Modal
        size='sm'
        open={modalUsuario}
        close={() => setModalUsuario(false)}
        titulo={'Meus dados'}
      >
        <form onSubmit={saveUpdateUser}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="m-0">
                Dados
              </p>
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nome</span>
                <Tooltip title="O nome do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                inputProps={{ maxLength: 400 }}
                value={userActive.name}
                disabled={true}
                // onChange={e => 
                //   setUserActive(prevState => ({
                //     ...prevState,
                //     name: e.target.value,
                //     editado: true
                //   }))
                // }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>E-mail</span>
                <Tooltip title="O e-mail do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                type="email"
                disabled={true}
                inputProps={{ maxLength: 400 }}
                value={userActive.email}
                // onChange={e => 
                //   setUserActive(prevState => ({
                //     ...prevState,
                //     email: e.target.value,
                //     editado: true
                //   }))
                // }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Telefone</span>
                <Tooltip title="O Telefone do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                disabled={true}
                inputProps={{ maxLength: 400 }}
                value={services.mask.phone(userActive.phone)}
                // onChange={e => 
                //   setUserActive(prevState => ({
                //     ...prevState,
                //     phone: services.mask.unMaskPhone(e.target.value),
                //     editado: true
                //   }))
                // }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <label className="labelAllis">
                <span>Login</span>
                <Tooltip title="O UserName do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                disabled={true}
                inputProps={{ maxLength: 400 }}
                value={userActive.username}
                // onChange={e => 
                //   setUserActive(prevState => ({
                //     ...prevState,
                //     username: e.target.value,
                //     editado: true
                //   }))
                // }
              />
            </Grid> */}
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Empresa</span>
                <Tooltip title="O Empresa do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                disabled={true}
                inputProps={{ maxLength: 400 }}
                value={userActive.company}
                // onChange={e => 
                //   setUserActive(prevState => ({
                //     ...prevState,
                //     company: e.target.value,
                //     editado: true
                //   }))
                // }
              />
            </Grid>

            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Senha atual</span>
                <Tooltip title="O Senha atual usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                type="password"
                inputProps={{ maxLength: 20 }}
                value={oldSenha}
                onChange={e => 
                  setOldSenha(e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nova Senha</span>
                <Tooltip title="Nova Senha do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                type="password"
                inputProps={{ maxLength: 20 }}
                value={userActive.password}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    password: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Confirmar Nova Senha</span>
                <Tooltip title="Confirmação da nova senha">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                type="password"
                inputProps={{ maxLength: 20 }}
                value={confirmarSenha}
                onChange={e => 
                  setConfirmarSenha(prevState => e.target.value)
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                alterar senha
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  )
}

export default memo(SystemMenu)
