import { Table } from 'antd'
import type { TableColumnsType } from 'antd';
import moment from 'moment/moment'
import services from '../../services'
import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { IComptradeEst } from '../../interfaces'


interface ITableEst {
  data: IComptradeEst[],
  sortFunction: any
}

const TableEst: React.FC<ITableEst> = ({ data, sortFunction }) => {
  const columns: TableColumnsType<IComptradeEst> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'ascend',
      key: 'id'
    },

    {
      title: "CPF/CNPJ",
      sorter: true,
      key: 'IdInterno',
      render: (_, record) => record.idInterno
    },

    {
      title: "Razão Social",
      sorter: true,
      key: 'RazaoSocial',
      render: (_, record) => record.razaoSocial
    },
    {
      title: "Nome Fantasia",
      sorter: true,
      key: 'NomeFantasia',
      render: (_, record) => record.nomeFantasia
    },

    {
      title: "Estado",
      sorter: true,
      key: 'Estado',
      render: (_, record) => record.estado
    },
    {
      title: "Higienizado",
      sorter: true,
      key: 'Higienizado',
      render: (_, record) =>
        <div>
        <span className={record.higienizado ? `badge badge-success` : `badge badge-warning`}>
          {record.higienizado ? `SIM` : `NÃO`}
        </span>
        </div>

    },
    {
      title: "Data Hig.",
      sorter: true,
      key: 'HigienizadoEm',
      render: (_, record) => {
        const higienizadoEm = moment(record.higienizadoEm);
        return higienizadoEm.format('DD/MM/YYYY') === '01/01/0001' ? '---' : higienizadoEm.format('DD/MM/YYYY');
      }
    },
    {
      title: "Ações",
      render: (_, record) =>
        <div className='justify-end'>
          <IconButton
            size="small" aria-label="editar usuário" component="label"
            onClick={() => window.location.href = `/app/comptrade/est/${record.id}`}
            //disabled={promiseInProgress}
          >
            <RemoveRedEyeIcon fontSize="small" />
          </IconButton>
        </div>
    }
  ];


  const handleChange = (pagination: any, filters: any, sorter: any) => {
    sortFunction(sorter)
  };

  return (
    <>
      <Table
        locale={{
          triggerDesc: 'Ordenação descendente',
          triggerAsc: 'Ordenação ascendente',
          cancelSort: 'Cancelar ordenação',
          emptyText: 'Nenhum chamado'
        }}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.id!.toString()}
        onChange={handleChange}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: 0 }}>
              <p><strong>Bandeira: </strong>{record.bandeira}</p>
              <p><strong>Rede: </strong>{record.rede}</p>
              <p><strong>Filial: </strong>{record.filial}</p>
              <p><strong>Canal: </strong>{record.canal}</p>
              <p><strong>Logradouro: </strong>{record.logradouro}</p>
              <p><strong>Número: </strong>{record.numero}</p>
              <p><strong>Complemento: </strong>{record.complemento}</p>
              <p><strong>Bairro: </strong>{record.bairro}</p>
              <p><strong>CEP: </strong>{record.cep}</p>
              <p><strong>Cidade: </strong>{record.cidade}</p>
              <p><strong>Estado: </strong>{record.estado}</p>
              <p><strong>Região: </strong>{record.regiao}</p>
              <p><strong>Observações: </strong>{record.observacao}</p>
              {
                record.tipoId !== "CPF" &&
                <div>
                  <p><strong>(RF) DDD: </strong>{record.recDdd}</p>
                  <p><strong>(RF) Telefone: </strong>{record.recTelefone}</p>
                  <p><strong>(RF) Email: </strong>{record.recEmail}</p>
                  <p><strong>(RF) Situação Cadastral: </strong>{record.recSituacaoCadastral ? record.recSituacaoCadastral : '---'}</p>
                  <p><strong>(RF) Data Situação Cadastral: </strong>{record.recDataSituacaoCadastral ? (moment(record.recDataSituacaoCadastral).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(record.recDataSituacaoCadastral).format('DD/MM/YYYY')) : '---'}</p>
                  <p><strong>(RF) Razão Social: </strong>{record.recRazaoSocial ? record.recRazaoSocial : '---'}</p>
                  <p><strong>(RF) Capital Social: </strong>{record.recCapitalSocial ? record.recCapitalSocial : '---'}</p>
                  <p><strong>(RF) Nome Fantasia: </strong>{record.recNomeFantasia ? record.recNomeFantasia : '---'}</p>
                  <p><strong>(RF) Tipo de Logradouro: </strong>{record.recTipoLogradouro ? record.recTipoLogradouro : '---'}</p>
                  <p><strong>(RF) Logradouro: </strong>{record.recLogradouro ? record.recLogradouro : '---'}</p>
                  <p><strong>(RF) Número: </strong>{record.recNumero ? record.recNumero : '---'}</p>
                  <p><strong>(RF) Complemento: </strong>{record.recComplemento ? record.recComplemento : '---'}</p>
                  <p><strong>(RF) Bairro: </strong>{record.recBairro ? record.recBairro : '---'}</p>
                  <p><strong>(RF) CEP: </strong>{record.recCep ? record.recCep : '---'}</p>
                  <p><strong>(RF) Cidade: </strong>{record.recCidadeNome ? record.recCidadeNome : '---'}</p>
                  <p><strong>(RF) Estado: </strong>{record.recEstadoSigla ? record.recEstadoSigla : '---'}</p>
                  <p><strong>(RF) Situação Especial: </strong>{record.recSituacaoEspecial ? record.recSituacaoEspecial : '---'}</p>
                  <p><strong>(RF) Atividade Principal: </strong>{record.recCnaePrincipal ? record.recCnaePrincipal : '---'}</p>
                  <p><strong>(RF) Atividade Secundária: </strong>{record.recCnaeSecundario ? record.recCnaeSecundario : '---'}</p>
                  <p><strong>(RF) Data Situação Especial: </strong>{record.recDataSituacaoEspecial ? (moment(record.recDataSituacaoEspecial).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(record.recDataSituacaoEspecial).format('DD/MM/YYYY')) : '---'}</p>
                </div>
              }
              <p><strong>Criado em: </strong>{record.criadoEm ? moment(record.criadoEm).format('DD/MM/YYYY') : '---'}</p>
              <p><strong>Atualizado em: </strong>{record.atualizadoEm ? (moment(record.atualizadoEm).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(record.atualizadoEm).format('DD/MM/YYYY')) : '---'}</p>
              <p><strong>Higienizado: </strong>{record.higienizado ? 'Sim' : 'Não'}</p>
              <p><strong>Higienizado em: </strong>{record.higienizadoEm ? (moment(record.higienizadoEm).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(record.higienizadoEm).format('DD/MM/YYYY')) : '---'}</p>

              {/*<p><strong>Filial: </strong>{record.recruitmentTicketData?.branch?.nomeFantasia}</p>*/}
              {/*<p><strong>Descrição do CR: </strong>{record.recruitmentTicketData?.protheusCtt?.ctT_RES} - {record.recruitmentTicketData?.protheusCtt?.ctT_DESC01}</p>*/}
            </div>
          ),
          rowExpandable: (record) => true,
        }}
        dataSource={data}
      />

    </>
  );
}




export default TableEst;