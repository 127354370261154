import { useEffect, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import { TextField, Autocomplete } from '@mui/material'

interface IBuscaUsersAdmins {
  action: any
  mainList: any
  buscarClienteAdmin: any
}

function BuscaUsersAdmins(props: IBuscaUsersAdmins) {
  const { action, mainList, buscarClienteAdmin } = props;
  const { promiseInProgress } = usePromiseTracker()

  const [itemSelecionado, setItemSelecionado] = useState({})

  const [clientesLista, setClientesLista] = useState<any>([])
  const statusRetorno = 'Busque por Usuários'

  const retornaItem = (item: any) => {
    if (action) {
      action(item)
    }
  }

  const buscarCliente = (value:string) => {
    if (value.length > 2) {
      buscarClienteAdmin(value)
      // setClientesLista(mainList?.filter((item:any) => item.name.toUpperCase().includes(value.toUpperCase())))
    }
  }

  useEffect(() => {
    setClientesLista(mainList)
  }, [mainList])
  
  return (
    <Autocomplete
      style={{minWidth: '200px'}}
      value={itemSelecionado}
      onChange={(event, newValue) => {
        retornaItem(newValue)
      }}
      isOptionEqualToValue={(option:any, value: any) =>
        option.name === value.name
      }
      getOptionLabel={(option:any) => option?.name || ''}
      disableClearable
      options={clientesLista}
      disabled={promiseInProgress}
      noOptionsText={statusRetorno}
      loading={false}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          onChange={e => buscarCliente(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default BuscaUsersAdmins
