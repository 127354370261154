import React, { } from 'react'
import { Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import './style.scss'
import { IDashboardEnvTeam } from '../../interfaces'
import {IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import moment from 'moment'

interface TableEnvironmentsProps{
  dataSource: IDashboardEnvTeam[]
  deleteDashboardTeam: any
  dashboardId: number
  environmentId: number
}

const TableEnvTeams: React.FC<TableEnvironmentsProps> = ({ deleteDashboardTeam, dataSource}) => {

  const columns: ColumnsType<IDashboardEnvTeam> = [
    {
      title: 'Nome da equipe',
      render: (_, record) => {
        return (record.team.name)
      },
    },
    {
      title: 'Usuários',
      render: (_, record) => {
        if (record.team.members.length === 0) {
          return ("Nenhum usuário");
        }
        else if(record.team.members.length === 1){
          return ("1 usuário")
        }
        else {
          return (`${record.team.members.length} usuários`)
        }
      },
    },
    {
      title: 'Adicionado em',
      render: (_, record) => {
        return (moment(record.createdAt).format('DD/MM/YYYY HH:mm'))
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <div style={{textAlign: 'right'}}>
          <Popconfirm title="Deseja deletar esta equipe?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => deleteDashboardTeam(record.id)}>
            <IconButton className='btn-purple mr-6 content-end' disabled={false}>
              <DeleteForeverIcon fontSize='small' />
            </IconButton>
          </Popconfirm>
        </div>
      ),
    },
  ];


  return (
    <div style={{textAlign: "right"}}>
      <Table
        columns={columns}
        dataSource={dataSource?.map((item, index) => ({ ...item, key: `${item.id}-${index}` }))}
        locale={{ emptyText:
            ' Nenhuma equipe'
        }}
        pagination={false}
      />;

    </div>


  );
};

export default TableEnvTeams;