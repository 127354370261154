import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  IconButton
} from '@mui/material'
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';

import SystemMenu from '../Menu'
import "./style.scss";
import { getCompany } from "../../configs/company";
import iconTrade from  "../../assets/img/trade/icon.png";
import logoTrade from  "../../assets/img/trade/logo.png";
import iconPop from  "../../assets/img/pop/icon.png";
import logoPop from  "../../assets/img/pop/logo.png";
import iconTradeMark from  "../../assets/img/trademark/icon.png";
import logoTradeMark from  "../../assets/img/trademark/logo.png";
import iconSeven from  "../../assets/img/seven/icon.png";
import logoSeven from  "../../assets/img/seven/logo.png";

const company = getCompany()
let logo: string
let icon : string
if (company.slug == 'TRADE') {
  logo = logoTrade
  icon = iconTrade
}
else if(company.slug == "POP"){
  logo = logoPop
  icon = iconPop
}
else if(company.slug == "SEVEN"){
  logo = logoSeven
  icon = iconSeven
}
else if(company.slug == "TRADEMARK"){
  logo = logoTradeMark
  icon = iconTradeMark
}
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function IconToggleMenu() {
  const toggleMenu = () => {
    const wrapperMenuLargeOpen = document.body.classList.contains("m-l-o");
    const wrapperMenuSmallOpen = document.body.classList.contains("m-s-o");

    if (document.body.clientWidth > 768) {
      if (wrapperMenuLargeOpen) {
        document.body.classList.remove("m-l-o");
      } else {
        document.body.classList.add("m-l-o");
      }
    }

    if (wrapperMenuSmallOpen) {
      document.body.classList.remove("m-s-o");
    } else {
      if (document.body.clientWidth <= 992) {
        document.body.classList.add("m-s-o");
      }
    }
  };

  return (
    <>
      <BootstrapTooltip title="Menu" placement="right">
        <IconButton className="system-main-menu-icon" onClick={toggleMenu}>
          <MenuIcon />
        </IconButton>
      </BootstrapTooltip>
    </>
  );
}

function LogoutMenu() {
  const navigate = useNavigate();

  const logOut = () => {
    Cookies.remove("TradeTalentosTokenPAT");
    Cookies.remove("TradeTalentosUserPAT");
    navigate("/");
  };

  useEffect(() => {
    const menuContainer = document.getElementById('wrapper-container-submenu')
    const menuContainerAdmin = document.getElementById('wrapper-container-submenuAdmin')
    
    if (menuContainer && window.outerWidth <= 768) menuContainer.classList.toggle('hide')
    if (menuContainerAdmin && window.outerWidth <= 768) menuContainerAdmin.classList.toggle('hide')
  }, []);

  return (
    <>
      <BootstrapTooltip title="Sair" placement="top">
        <div
          id="system-main-header-logout-button"
          className="system-main-header-logout-button"
          onClick={logOut}
        >
          {/* <div className="icon-name">{userIniciais}</div> */}
          <div className="icon-name"><LogoutIcon /> <span>Sair</span></div>
        </div>
      </BootstrapTooltip>
    </>
    
  );
}

function UserMenu(props:any) {
  const { action } = props
  return (
    <BootstrapTooltip title="Meus dados" placement="right">
      <div
        id="system-main-header-user-button"
        className="system-main-header-user-button"
        onClick={action}
      >
        <div className="icon-name"><PersonOutlineIcon /></div>
      </div>
    </BootstrapTooltip>
  );
}

function Header() {
  return (
    <>
      <header className="system-main-header">
        <div className="system-main-header-logo-2">

          <img src={logo} className="header-logo-destop" alt="Trade & Talentos" />
          <img src={icon} className="header-logo-mobile" alt="Trade & Talentos" />
        </div>
        <SystemMenu />
        <IconToggleMenu />
        <LogoutMenu />
      </header>
    </>
  );
}

export default Header;
