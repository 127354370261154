import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Tooltip,
  IconButton,
  InputAdornment
} from '@mui/material'
import { useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import services from '../../services';
import { Divider, Popconfirm } from 'antd'
import { number } from '../../services/mask';
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification'
import TableFilters from './TableFilters'
import { IDashboardEnvOverview, IDashboardFilter, IDropdownOptions, IEnvironment } from '../../interfaces'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Modal from '../../components/Modal'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { getDashboardById, getDashboardEnvOverview } from '../../services/api'
import { Delete, Save } from '@mui/icons-material'
import TableEnvironments from './TableEnvironments'
import MyComponent from '../../components/DropdownSearch'
import DropdownSearch from '../../components/DropdownSearch'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

function DashboardEdit() {
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardOrder, setDashboardOrder] = useState<number | null>(null);
  const [dashboardActive, setDashboardActive] = useState<boolean | undefined>(undefined);
  const [dashboardEnableFilters, setDashboardEnableFilters] = useState<boolean | undefined>(undefined);
  const [dashboardEnableNavigationBar, setDashboardEnableNavigationBar] = useState<boolean | undefined>(undefined);
  const [dashboardFilters, setDashboardFilters] = useState<IDashboardFilter[] | undefined>(undefined);
  const [dashboardReportPath, setDashboardReportPath] = useState("");
  const [dashboardRefreshTime, setDashboardRefreshTime] = useState<number | null>(null);
  const [dashboardChanged, setDashboardChanged] = useState<boolean>(false);
  const [changes, setDashboardChanges] = useState<number>(0);

  //DASH ENVIRONMENTS VARS
  const [dashboardEnvOverview, setDashboardEnvOverview] = useState<IDashboardEnvOverview[] | undefined>(undefined);
  const [dashEnvModal, setDashEnvModal] = useState<boolean>(false);
  const [envsList, setEnvsList] = useState<IDropdownOptions[]>([]);
  const [groupsList, setGroupsList] = useState<IDropdownOptions[]>([]);
  const [envSelected, setEnvSelected] = useState<number | undefined>(undefined);
  const [groupSelected, setGroupSelected] = useState<number | undefined>(undefined);
  const [resetKey, setResetKey] = useState<number>(0);
  const [dashEnvButton, setDashEnvButton] = useState<boolean>(false);


  //FILTERS VARS
  const [dashFilterTable, setDashFilterTable] = useState("");
  const [dashFilterColumn, setDashFilterColumn] = useState("");
  const [dashFilterOperator, setDashFilterOperator] = useState("EQ");
  const [dashFilterValueType, setDashFilterValueType] = useState("STATIC");
  const [dashFilterValue, setDashFilterValue] = useState("");
  const [dashFilterAcumulator, setDashFilterAcumulator] = useState("AND");
  const [dashFilterModal, setDashFilterModal] = useState<boolean>(false);

  //FILTERS EDIT VARS
  const [dashFilterIdEdit, setDashFilterIdEdit] = useState("");
  const [dashFilterTableEdit, setDashFilterTableEdit] = useState("");
  const [dashFilterColumnEdit, setDashFilterColumnEdit] = useState("");
  const [dashFilterOperatorEdit, setDashFilterOperatorEdit] = useState("AND");
  const [dashFilterValueTypeEdit, setDashFilterValueTypeEdit] = useState("STATIC");
  const [dashFilterValueEdit, setDashFilterValueEdit] = useState("");
  const [dashFilterAcumulatorEdit, setDashFilterAcumulatorEdit] = useState("AND");
  const [dashFilterModalEdit, setDashFilterModalEdit] = useState<boolean>(false);



  const { id } = useParams();
  const { notify } = useNotification()

  const testoptions = [
    { value: '1', label: 'Opção 1' },
    { value: '2', label: 'Opção 2' },
    { value: '3', label: 'Opção 3' }
  ];



  async function AddDashboardFilter() {

    let filterData = {
      table: dashFilterTable,
      colunm: dashFilterColumn,
      operator: dashFilterOperator,
      acumulator: dashFilterAcumulator,
      order: null,
      dashboardId: id,
      environmentId: null,
      type: dashFilterValueType,
      value: dashFilterValue,
    }

    let addedData = await trackPromise(services.api.addFilterToDashboard(filterData));

    if (addedData.fail || addedData.status !== 200) {
      notify("Não foi possivel carregar os dados do dashboard", { variant: 'error' })
      return
    }

    notify("Filtro padrão adicionado com sucesso!", { variant: 'success' })
    setDashFilterModal(false)
    setDashFilterValue("")
    setDashFilterColumn("")
    setDashFilterTable("EQ")
    await GetDashboardData(number(id))
  }

  async function UpdateDashboardFilter() {

    let filterData = {
      id: dashFilterIdEdit,
      table: dashFilterTableEdit,
      colunm: dashFilterColumnEdit,
      operator: dashFilterOperatorEdit,
      acumulator: dashFilterAcumulatorEdit,
      type: dashFilterValueTypeEdit,
      value: dashFilterValueEdit,
    }

    let update = await trackPromise(services.api.updateFilterToDashboard(filterData));

    if (update.fail || update.status !== 200) {
      notify("Não foi possivel atualizar o filtro", { variant: 'error' })
      return
    }
    notify("Filtro atualizado com sucesso", { variant: 'success' })
    setDashFilterModalEdit(false)
    setDashFilterValueEdit("")
    setDashFilterColumnEdit("")
    setDashFilterTableEdit("")
    await GetDashboardData(number(id))
  }

  const HandleDashboardEdit = async (filterData: IDashboardFilter) => {
    setDashFilterTableEdit(filterData.table);
    setDashFilterColumnEdit(filterData.colunm);
    setDashFilterOperatorEdit(filterData.operator);
    setDashFilterAcumulatorEdit(filterData.acumulator);
    setDashFilterValueEdit(filterData.value);
    setDashFilterValueTypeEdit(filterData.type);
    setDashFilterIdEdit(number(filterData.id))
    setDashFilterModalEdit(true)
  }

  const DeleteDashboardFilter = async (filterId: number) => {
      let remove = await trackPromise(services.api.removeFilterFromDashboard(filterId));
      if (remove.status !== 200) {
        notify("Não foi possivel deletar este filtro", { variant: 'error' })
        return
      }
      notify("Filtro removido com sucesso!", { variant: 'success' })
      await GetDashboardData(number(id))
  }


  async function GetDashboardData(id: number) {
    let dashboardData = await trackPromise(services.api.getDashboardById(id));
    let dashboardEnvOverviewData = await trackPromise(services.api.getDashboardEnvOverview(id));
    setDashboardEnvOverview(dashboardEnvOverviewData.data)

    if (dashboardData.fail) {
      notify("Não foi possivel carregar os dados do dashboard", { variant: 'error' })
      return
    }
    else if(dashboardData.status === 204){
      notify("Dashboard inexistente", { variant: 'error' })
      return
    }
    setDashboardName(dashboardData.data.name);
    setDashboardOrder(dashboardData.data.order);
    setDashboardActive(dashboardData.data.active);
    setDashboardReportPath(dashboardData.data.reportPath);
    setDashboardRefreshTime(dashboardData.data.autoRefreshTime);
    setDashboardEnableFilters(dashboardData.data.enableFilters);
    setDashboardEnableNavigationBar(dashboardData.data.enableNavigationBar);
    setDashboardFilters(dashboardData.data.dashboardFilters);
  }

  async function OpenAddEnvModal() {
    setEnvSelected(undefined)
    setGroupSelected(undefined)
    let envs = await trackPromise(services.api.getEnvironmentsAll());
    const filteredEnvs = envs.data.filter((env: IEnvironment) => {
      return !dashboardEnvOverview!.some((dashboardEnv) => dashboardEnv.environmentId === env.id);
    });
    setEnvsList(filteredEnvs.map((env: IEnvironment) => ({ value: env.id, label: env.name })));
    setDashEnvModal(true)
  }

  async function HandleEnvSelected(id: number) {
    if(id !== null || undefined){
      setEnvSelected(id)
      setGroupSelected(undefined)
      setResetKey(resetKey + 1)
      setGroupsList([])
      let groups = await trackPromise(services.api.getAllEnvGroups(id));
      setGroupsList(groups.data.map((env: IEnvironment) => ({ value: env.id, label: env.name })));
    }
  }

  async function HandleEnvGroupSelected(id: number) {
    setGroupSelected(id)
    setDashEnvButton(true)
  }

  async function HandleEnvAdd() {
    setDashEnvModal(false)
    let addedDashboardGroup = await trackPromise(services.api.addDashboardGroup(id, groupSelected));

    setGroupsList([])
    setEnvsList([])
    setGroupSelected(undefined)
    setEnvSelected(undefined)

    if (addedDashboardGroup.fail) {
      notify(addedDashboardGroup.error, { variant: 'error' })
      return
    }
    else if(addedDashboardGroup.status === 200){
      notify("Ambiente adicionado ao dashboard", { variant: 'success' })
      await GetDashboardData(number(id))
      return
    }
  }

  async function HandleEnvRemove(dashboardId: number, groupId: number) {
    let result = await trackPromise(services.api.deleteDashboardGroup(dashboardId, groupId));
    if (result.fail) {
      notify(result.error, { variant: 'error' })
      return
    }
    else if(result.status === 200){
      notify("Ambiente removido do dashboard", { variant: 'warning' })
      await GetDashboardData(number(id))
      return
    }
  }

  async function HandleDashRemove() {
    let result = await trackPromise(services.api.deleteDashboard(id));
    if (result.fail) {
      notify(result.error, { variant: 'error' })
      return
    }
    else if(result.status === 200){
      notify("Dashboard removido com sucesso!", { variant: 'warning' })
      setTimeout(() => {
        window.location.href = `/app/dash_management/functionalities`
      }, 1000);
    }
  }




  async function UpdateDashboardData() {
    let dashboard = {
      Id: id,
      Name: dashboardName,
      ReportPath: dashboardReportPath,
      Order: dashboardOrder,
      Active: dashboardActive,
      AutoRefreshTime: dashboardRefreshTime,
      EnableFilters: dashboardEnableFilters,
      EnableNavigationBar: dashboardEnableNavigationBar
    };
    let result = await trackPromise(services.api.editDashboard(dashboard))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      setDashboardChanges(2)
      setDashboardChanged(false)
      return
    }
    notify("Dashboard atualizado com exito", { variant: 'success' })
    setDashboardChanges(2)
    setDashboardChanged(false)
  }

  async function SetDashboardFilterOrder(ids: number[]) {
    let result = await trackPromise(services.api.setDashboardFilterOrder(ids))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    notify("Ordem dos filtros salva com sucesso.", { variant: 'success' })
  }

  useEffect(() => {
    if (changes > 1) {
      setDashboardChanged(true);
    }
    setDashboardChanges(changes + 1)
  }, [dashboardName, dashboardOrder, dashboardActive, dashboardReportPath, dashboardRefreshTime, dashboardEnableNavigationBar, dashboardEnableFilters]);

  useEffect(() => {
    if(dashFilterValueType !== "STATIC")
      setDashFilterValue("ENVIRONMENT_NAME")
    else
      setDashFilterValue("")
  }, [dashFilterValueType]);

  useEffect(() => {
    if(dashFilterValueTypeEdit !== "STATIC")
      setDashFilterValueEdit("ENVIRONMENT_NAME")
    else
      setDashFilterValueEdit("")
  }, [dashFilterValueTypeEdit]);

  useEffect(() => {
    const init = async () => {
      await GetDashboardData(number(id));
    };
    init().then()
  }, [id]);

  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle subText={`Editando o dashboard #${id}`}>
          <div>{dashboardName ? `Dashboards > ${dashboardName}` : 'Dashboards > ...'}</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={11} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowBackIcon sx={{ fontSize: 40, marginRight: '10px', cursor: 'pointer' }} onClick={() => window.location.href = `/app/dash_management/functionalities`} />
                  <h2>Dados do dashboard</h2>
                </div>
                <br />
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      required
                      size='small'
                      label="Nome"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={dashboardName}
                      onChange={e =>
                        setDashboardName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <TextField
                      fullWidth
                      required
                      size='small'
                      label="Ordem"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={dashboardOrder === null ? '' : dashboardOrder.toString()}
                      onChange={e => setDashboardOrder(number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Auto refresh - segundos"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={dashboardRefreshTime === null ? '' : dashboardRefreshTime.toString()}
                      onChange={e => setDashboardRefreshTime(number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <FormControl>
                      <InputLabel>Ativo</InputLabel>
                      <Select
                        fullWidth
                        required
                        notched
                        size="small"
                        label="Ativo"
                        value={dashboardActive === undefined ? "" : dashboardActive.toString()}
                        onChange={e => setDashboardActive(e.target.value === "true")}
                      >
                        <MenuItem value={"true"} >
                          Sim
                        </MenuItem>
                        <MenuItem value={"false"} >
                          Não
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <TextField
                      fullWidth
                      required
                      size='small'
                      label="Report Path"
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ shrink: true }}
                      value={dashboardReportPath}
                      onChange={e => setDashboardReportPath(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <FormControl>
                      <InputLabel>Ativar filtro PowerBI</InputLabel>
                      <Select
                        fullWidth
                        required
                        notched
                        size="small"
                        label="Ativar filtro PowerBI"
                        value={dashboardEnableFilters === undefined ? "" : dashboardEnableFilters.toString()}
                        onChange={e => setDashboardEnableFilters(e.target.value === "true")}
                      >
                        <MenuItem value={"true"} >
                          Sim
                        </MenuItem>
                        <MenuItem value={"false"} >
                          Não
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <FormControl>
                      <InputLabel>Barra de navegação PowerBI</InputLabel>
                      <Select
                        fullWidth
                        required
                        notched
                        size="small"
                        label="Barra de navegação PowerBI"
                        value={dashboardEnableNavigationBar === undefined ? "" : dashboardEnableNavigationBar.toString()}
                        onChange={e => setDashboardEnableNavigationBar(e.target.value === "true")}
                      >
                        <MenuItem value={"true"} >
                          Sim
                        </MenuItem>
                        <MenuItem value={"false"} >
                          Não
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>


                </Grid>
                <br />
                <Grid item xs={12} className="flex justify-end gap-6">

                  <div style={{textAlign: "right", marginTop: "10px"}}>

                    <Popconfirm title="Deseja deletar este dashboard?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => HandleDashRemove()}>
                      <Button
                        variant="contained"
                        className='btn-red mr-6 content-end mb-12'
                        type="button"
                        size='small'
                        color="inherit"
                        //onClick={Delete}
                      >
                        <Delete style={{marginRight: '5px'}} fontSize='small' />  Excluir dashboard
                      </Button>
                    </Popconfirm>


                  </div>

                  <div style={{textAlign: "right", marginTop: "10px"}}>
                    <Button
                      variant="contained"
                      className='btn-purple mr-6 content-end mb-12'
                      type="button"
                      size='small'
                      color="inherit"
                      disabled={!dashboardChanged}
                      onClick={UpdateDashboardData}
                    >
                      <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar alterações
                    </Button>
                  </div>



                </Grid>

                <Divider>   </Divider>
                <h3>Filtros padrões</h3>


                <div style={{textAlign: "right"}}>
                  <Button
                    variant="contained"
                    className='btn-purple mr-6 content-end mb-12'
                    type="button"
                    size='small'
                    color="inherit"
                    onClick={() => setDashFilterModal(true)}
                  >
                    <AddCircleOutlineIcon style={{marginRight: '5px'}} fontSize='small' />  Novo filtro
                  </Button>
                </div>

                <TableFilters
                  data={dashboardFilters || []}
                  deleteDashboardFilter={DeleteDashboardFilter}
                  editDashboardFilter={HandleDashboardEdit}
                  setDashboardFilterOrder={SetDashboardFilterOrder}
                ></TableFilters>


                <Divider></Divider>
                <h3>Ambientes</h3>
                <div style={{textAlign: "right"}}>
                  <Button
                    variant="contained"
                    className='btn-purple mr-6 content-end mb-12'
                    type="button"
                    size='small'
                    color="inherit"
                    onClick={() => OpenAddEnvModal()}
                  >
                    <AddCircleOutlineIcon style={{marginRight: '5px'}} fontSize='small' />  Vincular ambiente
                  </Button>
                </div>

                <TableEnvironments
                  dataSource={dashboardEnvOverview || []}
                  deleteDashboardGroup={HandleEnvRemove}
                  dashboardId={number(id)}
                ></TableEnvironments>


              </div>
            </div>
          </Grid>
        </Grid>



        <Modal size='sm' open={dashFilterModalEdit} close={() => setDashFilterModalEdit(false)} titulo={'Editar filtro'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Tabela"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterTableEdit}
                onChange={e =>
                  setDashFilterTableEdit(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Coluna"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterColumnEdit}
                onChange={e =>
                  setDashFilterColumnEdit(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Operador</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Barra de navegação PowerBI"
                  value={dashFilterOperatorEdit}
                  onChange={e => setDashFilterOperatorEdit(e.target.value)}
                >
                  <MenuItem value={"EQ"} >
                    EQ (Igual a)
                  </MenuItem>
                  <MenuItem value={"NE"} >
                    NE (Diferente de)
                  </MenuItem>
                  <MenuItem value={"GE"} >
                    GE (Maior ou igual)
                  </MenuItem>
                  <MenuItem value={"GT"} >
                    GT (Maior que)
                  </MenuItem>
                  <MenuItem value={"LE"} >
                    LE (Menor ou igual)
                  </MenuItem>
                  <MenuItem value={"LT"} >
                    LT (Menor que)
                  </MenuItem>
                  <MenuItem value={"IN"} >
                    IN (Incluindo)
                  </MenuItem
                  >
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Tipo de valor</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Tipo de valor"
                  value={dashFilterValueTypeEdit}
                  onChange={e => setDashFilterValueTypeEdit(e.target.value)}
                >
                  <MenuItem value={"VARIABLE"} >
                    Variavel do sistema
                  </MenuItem>
                  <MenuItem value={"STATIC"} >
                    Valor estatico
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>

            {dashFilterValueTypeEdit === "STATIC" &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  required
                  size='small'
                  label="Valor"
                  inputProps={{ maxLength: 120 }}
                  InputLabelProps={{ shrink: true }}
                  value={dashFilterValueEdit}
                  onChange={e =>
                    setDashFilterValueEdit(e.target.value)}
                />
              </Grid>
            }

            {dashFilterValueTypeEdit === "VARIABLE" &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <InputLabel>Valor</InputLabel>
                  <Select
                    fullWidth
                    required
                    notched
                    size="small"
                    label="Valor"
                    value={dashFilterValueEdit}
                    onChange={e => setDashFilterValueEdit(e.target.value)}
                  >
                    <MenuItem value={"ENVIRONMENT_NAME"} >
                      Nome do ambiente
                    </MenuItem>
                    <MenuItem value={"USER_NAME"} >
                      Nome do usuário
                    </MenuItem>
                    <MenuItem value={"USER_CPF"} >
                      CPF do usuário
                    </MenuItem>
                    <MenuItem value={"USER_EMAIL"} >
                      Email do usuário
                    </MenuItem>
                    <MenuItem value={"USER_COMPANY"} >
                      Empresa do usuário
                    </MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            }

            {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
            {/*  <FormControl>*/}
            {/*    <InputLabel>Acumulador</InputLabel>*/}
            {/*    <Select*/}
            {/*      fullWidth*/}
            {/*      required*/}
            {/*      notched*/}
            {/*      size="small"*/}
            {/*      label="Acumulador"*/}
            {/*      value={dashFilterAcumulatorEdit}*/}
            {/*      onChange={e => setDashFilterAcumulatorEdit(e.target.value)}*/}
            {/*    >*/}
            {/*      <MenuItem value={"AND"} >*/}
            {/*        AND (E)*/}
            {/*      </MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={12} className="flex justify-end gap-6">

              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setDashFilterModalEdit(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="button"
                size='small'
                color="secondary"
                onClick={UpdateDashboardFilter}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Modal>




        <Modal size='sm' open={dashFilterModal} close={() => setDashFilterModal(false)} titulo={'Novo filtro padrão'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Tabela"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterTable}
                onChange={e =>
                  setDashFilterTable(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Coluna"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterColumn}
                onChange={e =>
                  setDashFilterColumn(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Operador</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Barra de navegação PowerBI"
                  value={dashFilterOperator}
                  onChange={e => setDashFilterOperator(e.target.value)}
                >
                  <MenuItem value={"EQ"} >
                    EQ (Igual a)
                  </MenuItem>
                  <MenuItem value={"NE"} >
                    NE (Diferente de)
                  </MenuItem>
                  <MenuItem value={"GE"} >
                    GE (Maior ou igual)
                  </MenuItem>
                  <MenuItem value={"GT"} >
                    GT (Maior que)
                  </MenuItem>
                  <MenuItem value={"LE"} >
                    LE (Menor ou igual)
                  </MenuItem>
                  <MenuItem value={"LT"} >
                    LT (Menor que)
                  </MenuItem>
                  <MenuItem value={"IN"} >
                    IN (Incluindo)
                  </MenuItem
                  >
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Tipo de valor</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Tipo de valor"
                  value={dashFilterValueType}
                  onChange={e => setDashFilterValueType(e.target.value)}
                >
                  <MenuItem value={"VARIABLE"} >
                    Variavel do sistema
                  </MenuItem>
                  <MenuItem value={"STATIC"} >
                    Valor estatico
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>

            {dashFilterValueType === "STATIC" &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  required
                  size='small'
                  label="Valor"
                  inputProps={{ maxLength: 120 }}
                  InputLabelProps={{ shrink: true }}
                  value={dashFilterValue}
                  onChange={e =>
                    setDashFilterValue(e.target.value)}
                />
              </Grid>
            }

            {dashFilterValueType === "VARIABLE" &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <InputLabel>Valor</InputLabel>
                  <Select
                    fullWidth
                    required
                    notched
                    size="small"
                    label="Valor"
                    value={dashFilterValue}
                    onChange={e => setDashFilterValue(e.target.value)}
                  >
                    <MenuItem value={"ENVIRONMENT_NAME"} >
                      Nome do ambiente
                    </MenuItem>
                    <MenuItem value={"USER_NAME"} >
                      Nome do usuário
                    </MenuItem>
                    <MenuItem value={"USER_CPF"} >
                      CPF do usuário
                    </MenuItem>
                    <MenuItem value={"USER_EMAIL"} >
                      Email do usuário
                    </MenuItem>
                    <MenuItem value={"USER_COMPANY"} >
                      Empresa do usuário
                    </MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            }

            {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
            {/*  <FormControl>*/}
            {/*    <InputLabel>Acumulador</InputLabel>*/}
            {/*    <Select*/}
            {/*      fullWidth*/}
            {/*      required*/}
            {/*      notched*/}
            {/*      size="small"*/}
            {/*      label="Acumulador"*/}
            {/*      value={dashFilterAcumulator}*/}
            {/*      onChange={e => setDashFilterAcumulator(e.target.value)}*/}
            {/*    >*/}
            {/*      <MenuItem value={"AND"} >*/}
            {/*        AND (E)*/}
            {/*      </MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={12} className="flex justify-end gap-6">

              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setDashFilterModal(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="button"
                size='small'
                color="secondary"
                //disabled={promiseInProgress}
                onClick={AddDashboardFilter}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Modal>



        <Modal size='sm' open={dashEnvModal} close={() => setDashEnvModal(false)} titulo={'Vincular ambiente'}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DropdownSearch
                options={envsList}
                onChange={(e)=> HandleEnvSelected(number(e)) }
                disabled={false}
                label={"Ambiente"}
                key={0}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DropdownSearch
                options={groupsList}
                onChange={(e)=> HandleEnvGroupSelected(number(e)) }
                disabled={envSelected === undefined}
                label={"Grupo"}
                key={resetKey}
              />
            </Grid>

            <Grid item xs={12} className="flex justify-end gap-6">
              <br/>
              <Button
                variant="contained"
                className='btn-purple mr-6 content-end mb-12'
                type="button"
                size='small'
                color="secondary"
                disabled={groupSelected === undefined}
                onClick={()=> HandleEnvAdd()}
              >
                Adicionar
              </Button>


            </Grid>

          </Grid>
        </Modal>


      </div>
    </div>
  )
}

export default DashboardEdit;
