import React, { useState, useEffect } from "react";
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PageTitle } from "../../components/PageElements";
import { useAuth } from "../../hooks/useAuth";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableTrct from "./TableTrct";
import { deleteDocument, listDocuments } from "../../services/api";
import Modal from "../../components/Modal";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from "moment";
import { ITrct } from "../../interfaces";
import services from "../../services";
import { trackPromise } from "react-promise-tracker";
import { useNotification } from '../../hooks/useNotification.js'
import { validarCpfCnpj } from "../ComptradeEst/CpfCnpjValidator";
import SearchIcon from '@mui/icons-material/Search';
import { cpfCnpj } from "../../services/mask";

function TrctOverview() {
    const { getUserRoles } = useAuth();
    const userRoles = getUserRoles();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [data, setData] = useState<ITrct[]>([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [orderDesc, setOrderDesc] = useState<boolean>(false);
    const [modalNewItem, setModalNewItem] = useState(false);
    const { notify } = useNotification()

    // Estados para o modal
    const [fileType, setFileType] = useState<string>('');
    const [cpf, setCpf] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);

    const handleReload = async () => {
        fetchFiles(currentPage, pageSize);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if(validarCpfCnpj(cpf)){
                    if (file && fileType && cpf) {
            try {
                const res = await trackPromise(services.api.uploadSingleTrct(file, fileType, cpf));
                
                if (res.fail) {
                    throw new Error(res.error || 'Não foi possível enviar anexo');
                }

                const newNamefile = `${res?.fileName}`;
                const newFile = new File([file], newNamefile);
                console.log("file newFile", newFile);
                
                await trackPromise(services.api.uploadS3(newFile, res.url));
                console.log("Arquivo enviado com sucesso!");
                setModalNewItem(false)
                notify("Arquivo Enviado", { variant: 'success' })
                handleReload()

            } catch (error) {
                console.error("Erro ao enviar arquivo", error);
                notify("Erro ao enviar arquivo", { variant: 'error' })

            }
        } else {
            console.error("Por favor, preencha todos os campos e selecione um arquivo PDF.");
        }
        }
        else
        notify("CPF Inválido", { variant: 'error' })


    };

    const handlePagination = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        if (pageSize) setPageSize(pageSize);
    };

    const closeNewItemModal = () => {
        setModalNewItem(false);
    };

    const openNewItemModal = () => {
        setModalNewItem(true);
    };

    const handleSortOrder = (data: any) => {
        try {
            setOrderDesc(data.order !== 'ascend');
        } catch {
            // Handle error
        }
        fetchFiles(currentPage, pageSize);
    };
    const onDelete = (fileName: string) => {
        deleteDocument("TrctWorker/", fileName)
        notify("Arquivo Deletado", { variant: 'success' })
        fetchFiles(currentPage, pageSize)
    };

    const formatCpf = (cpf: string) => {
        const cleanCpf = cpf.replace(/\D+/g, '');
        return cleanCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            await fetchFiles(currentPage, pageSize)

        } catch (error) {
            console.error("Failed to search files", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFiles = async (page: number, pageSize: number) => {
        setLoading(true);
        try {
            const sanitizedSearchTerm = searchTerm.replace(/\D/g, '');

            const response = await listDocuments(page, pageSize, !orderDesc, sanitizedSearchTerm );
            const { data, totalCount } = response;

            const formattedFiles = data.map((file: any) => ({
                name: file.name,
                fileName: file.fileName,
                cpf: formatCpf(file.cpf),
                createdAt: moment(file.createdAt).format('DD/MM/YYYY')
            }));

            setData(formattedFiles);
            setTotalCount(totalCount);
        } catch (error) {
            console.error("Failed to fetch files", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFiles(currentPage, pageSize);
    }, [currentPage, pageSize, orderDesc]);

    return (
        <div className='wrapper-container-content bgwhite flex flex-col'>
            <div className='wrapper-page-content' id='wrapper-page-content'>
                <PageTitle subText={"Gerenciar Trct"}>
                    <div>Termos de rescisão contratual de trabalho</div>
                </PageTitle>
                <div className='panel'>
                    <div className='panel-body'>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
                            <Button
                                variant="contained"
                                type="button"
                                color="secondary"
                                onClick={openNewItemModal}
                                startIcon={<AddCircleOutlineIcon fontSize='small' />}
                            >
                                <span>Upload</span>
                            </Button>
                            <TextField
                                 size='small'
                                label='Buscar CPF'
                                placeholder='Buscar por CPF'
                                value={searchTerm}
                                onChange={(e) => {
                                const onlyNumbers = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
                                setSearchTerm(onlyNumbers);
                                }}
                                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                />
                            <IconButton onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton onClick={handleReload}>
                                <RefreshIcon />
                            </IconButton>
                        </div>
                        <TableTrct
                            data={data}
                            sortFunction={handleSortOrder}
                            loading={loading}
                            pageSize={pageSize}
                            totalPages={Math.ceil(totalCount / pageSize)}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            handlePagination={handlePagination}
                            handleDelete={onDelete}
                        />
                    </div>
                </div>
            </div>
            <Modal open={modalNewItem} close={closeNewItemModal} titulo={'Upload TRCT'}>
                <form 
                    className="flex flex-col"
                    onSubmit={handleSubmit}
                >
                    <FormControl variant="outlined" margin="normal">
                        <InputLabel id="fileType-label">Tipo de Arquivo</InputLabel>
                        <Select
                            labelId="fileType-label"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value as string)}
                            label="Tipo de Arquivo"
                        >
                            <MenuItem value="1">TRCT</MenuItem>
                            <MenuItem value="2">Seguro Desemprego</MenuItem>
                            <MenuItem value="3">Extrato FGTS</MenuItem>
                            <MenuItem value="4">Ficha Cadastral</MenuItem>
                            {/* Adicione mais tipos de arquivos, se necessário */}
                        </Select>
                    </FormControl>
                    <TextField
    label='CPF'
    placeholder='Insira o CPF'
    value={cpfCnpj(cpf.substring(0, 15))}
    onChange={(e) => {
        const numericValue = e.target.value.replace(/\D/g, ''); // Remove tudo que não é número
        setCpf(numericValue);
    }}
    margin="normal"
/>

                    <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                    />
                    <Button className="mt-16" variant="contained" type="submit" color="secondary" startIcon={<UploadFileIcon fontSize='small' />}>
                        Upload
                    </Button>
                </form>
            </Modal>
        </div>
    );
}

export default TrctOverview;
