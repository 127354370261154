import React, { useCallback, useEffect, useState } from 'react'

import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material'
import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IComptradeEst, IComptradeEstOverview } from '../../interfaces/index'
import services from '../../services/index.js'

//import './style.scss'
import TableEst from './TableEst'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Pagination } from '../../components/Table'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { trackPromise } from 'react-promise-tracker'
import EstOverview from './EstOverview'
import { Divider } from 'antd'
import estOverview from './EstOverview'
import { getChamadosRes } from '../../services/api'
import { validarCpfCnpj } from './CpfCnpjValidator'


function ComptradeEst() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()


  const [comptradeEsts, setComptradeEsts] = useState<IComptradeEst[] | []>([])
  const [overviewTodos, setOverviewTodos] = useState<number | undefined>()
  const [overviewCpfs, setOverviewCpfs] = useState<number | undefined>()
  const [overviewCnpjs, setOverviewCnpjs] = useState<number | undefined>()
  const [overviewHigienizados, setOverviewHigienizados] = useState<number | undefined>()
  const [overviewAtualizar, setOverviewAtualizar] = useState<number | undefined>()
  const [overviewFilter, setOverviewFilter] = useState<string | undefined>()
  const [modalNewItem, setModalNewItem] = useState<boolean>(false)


  const [cpfCnpj, setCpfCnpj] = useState<string>('')
  const [cpfCnpjIsValid, setCpfCnpjIsValid] = useState<boolean>(false)


  const [overviewActive, setOverviewActive] = useState<string>()
  const [commonSearch, setCommonSearch] = useState<string>('')
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDesc, setOrderDesc] = useState<boolean>(false)
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)


  const handleSortOrder = (data: any) => {
    try {
      setOrderBy(data.column.key)
      setOrderDesc(data.order != 'ascend')
    } catch {
      setOrderBy(undefined)
    }
    getComptradeEsts()
  }

  const getComptradeEsts = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.getComptradeEstPaged(currentPage, pageSize, orderBy, commonSearch, overviewFilter, orderDesc))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os estabelecimentos'
        )
      }
      setComptradeEsts(res.data.data)
      setTotalCount(res.data.totalCount)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [overviewFilter, orderBy, orderDesc, currentPage, pageSize, commonSearch])


  const getEstOverview = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.getEstOverview())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os estabelecimentos'
        )
      }
      let dt: IComptradeEstOverview = res.data
      setOverviewCnpjs(dt.cnpjs)
      setOverviewCpfs(dt.cpfs)
      setOverviewTodos(dt.total)
      setOverviewAtualizar(dt.toUpdate)
      setOverviewHigienizados(dt.higienizados)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [overviewCnpjs, overviewHigienizados, overviewAtualizar, overviewCnpjs, overviewTodos])


  const createNewItem = useCallback(async () => {
    try {
      let data = {
        idInterno: cpfCnpj.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
      }
      debugger
      let res = await trackPromise(services.api.createNewEstItem(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os estabelecimentos'
        )
      }
      window.location.href = "/app/comptrade/est/" + res.data.id
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [cpfCnpj, cpfCnpjIsValid])


  const handlePagination = async (page: number, busca: any, itemsPerPage: number) => {
    setPageSize(itemsPerPage)
    setCurrentPage(page)
  }

  const handleReload = async () => {
    getComptradeEsts()
    getEstOverview()
  }

  const handleSelectedOverview = async (type: string) => {
    setOverviewActive(type)
    if(type === "ALL"){
      setOverviewFilter(undefined)
    }

    if(type === "CNPJ"){
      setOverviewFilter("tipoId=CNPJ")
    }

    if(type === "CPF"){
      setOverviewFilter("tipoId=CPF")
    }

    if(type === "HIGIENIZADOS"){
      setOverviewFilter("higienizado=true")
    }

    if(type === "PARA_ATUALIZAR"){
      setOverviewFilter("toUpdate=true")
    }
  }

  const openNewItemModal = () =>{
    setModalNewItem(true)
  }

  const closeNewItemModal = () =>{
    setModalNewItem(false)
  }

  const formatCpfCnpj = (value: string): string => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length === 11) {
      return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    else if (cleanedValue.length === 14) {
      return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return cleanedValue;
  };

  const handleCpfCnpj = async (data:string) => {
    setCpfCnpj(formatCpfCnpj(data))
    setCpfCnpjIsValid(validarCpfCnpj(data))
  }


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getComptradeEsts()
    }, 50)

    return () => clearTimeout(delayDebounceFn)
  }, [currentPage, pageSize])

  useEffect(() => {
    getEstOverview()
  }, [estOverview])

  useEffect(() => {
    getComptradeEsts()
  }, [overviewFilter])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getComptradeEsts()
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [commonSearch])


  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id='wrapper-page-content'>
        <PageTitle subText={"Gerenciar os estabelecimentos"}>
          <div>Estabelecimentos</div>
        </PageTitle>

        <Grid container justifyContent='center' spacing={{ xs: 3 }}>
          <Grid item md={12} xs={12}>
            <div className='panel'>
              <div className='panel-body'>


                <div className='wrapper-page-content-interna'>
                  <div className='flex justify-center'>
                    <EstOverview onSelect={handleSelectedOverview} type={'ALL'} value={overviewTodos} active={overviewActive} />
                    <EstOverview onSelect={handleSelectedOverview} type={'CNPJ'} value={overviewCnpjs} active={overviewActive} />
                    <EstOverview onSelect={handleSelectedOverview} type={'CPF'} value={overviewCpfs} active={overviewActive} />
                    <EstOverview onSelect={handleSelectedOverview} type={'HIGIENIZADOS'} value={overviewHigienizados} active={overviewActive} />
                    <EstOverview onSelect={handleSelectedOverview} type={'PARA_ATUALIZAR'} value={overviewAtualizar} active={overviewActive} />
                  </div>
                </div>

                <Divider></Divider>


                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>


                  {
                    userRoles.includes("comptrade_add_est") &&
                    <Button
                      variant="contained" type="button" color="secondary"
                      onClick={openNewItemModal}
                      startIcon={<AddCircleOutlineIcon fontSize='small' />}
                      //disabled={promiseInProgress}
                    >
                      <span>Novo</span>
                    </Button>
                  }

                  <TextField
                    size='small'
                    label='Buscar nos estabelecimentos'
                    placeholder='Buscar nos estabelecimentos'
                    value={commonSearch}
                    onChange={(e) => setCommonSearch(e.target.value)}
                    defaultValue=''
                  />

                  <Tooltip title='Recarregar busca'>

                    <span>
                      <IconButton className='btn-purple' onClick={handleReload}>
                        <RefreshIcon fontSize='small' />
                      </IconButton>
                    </span>

                  </Tooltip>
                </div>

                <TableEst
                  data={comptradeEsts}
                  sortFunction={handleSortOrder}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  itensLength={200}
                  totalCount={totalCount}
                  handlePagination={handlePagination}
                  colunaBusca={''}
                  search={''}
                />

              </div>
            </div>
          </Grid>
        </Grid>








      </div>

      <Modal
        open={modalNewItem}
        close={closeNewItemModal}
        titulo={'Novo cadastro'}
      >
        <Grid container spacing={{ xs: 3 }} className="sm-min-500">

          <Grid item xs={12} >

            <div className={"flex justify-center mb-12"}>
              <span style={{ display: cpfCnpjIsValid ? 'inline' : 'none' }} className="badge badge-success">CPF ou CNPJ válido</span>
              <span style={{ display: !cpfCnpjIsValid ? 'inline' : 'none' }} className="badge badge-error">CPF ou CNPJ inválido</span>
            </div>

            <div className="flex justify-center">
              <TextField
                size='small'
                label='CPF ou CNPJ'
                placeholder='Buscar nos chamados'
                value={cpfCnpj}
                onChange={(e) => handleCpfCnpj(e.target.value)}
                defaultValue=""
              />
            </div>

          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success"
              type="button"
              size='small'
              disabled={!cpfCnpjIsValid}
              onClick={createNewItem}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Modal>

    </div>
  )
}

export default ComptradeEst
