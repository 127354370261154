import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RequireAuth from '../layouts/RequireAuth'
import Login from '../views/Login'
import Dashboard from '../views/Dashboard'
import NotFound from '../views/NotFound'
import Funcionalidades from '../views/Funcionalidades'
import Usuarios from '../views/Usuarios'
import AccessDenied from '../views/AccessDenied'

import Chamados from '../views/Chamados'
import Chamado from '../views/Chamados/Chamado'
import ChamadoNew from '../views/Chamados/ChamadoNew'
import ChamadoNewMultFuncoes from '../views/Chamados/ChamadoNewMultFuncoes'
import ChamadoNewMultLocais from '../views/Chamados/ChamadoNewMultLocais'
import Clientes from '../views/Clientes'
import ClientesAll from '../views/Clientes/ClientesAll'
import DashboardEdit from '../views/DashboardEdit'
import EnvironmentEdit from '../views/EnvironmentEdit'
import DashboardNew from '../views/DashboardNew'
import DashboardEnvEdit from '../views/DashboardEnvEdit'
import ComptradeEst from '../views/ComptradeEst'
import ComptradeEstEdit from '../views/ComptradeEstEdit'
import DashboardRules from '../views/DashboardRules'
import DashboardRuleEdit from '../views/DashboardRuleEdit'
import TrctOverview from '../views/Trct'
import TrctInclude from '../views/TrctInclude'
import TrctLog from '../views/TrctLog'

function SystemRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/app" element={<RequireAuth roles={['bi_dashboards_read_any', 'bi_dashboards_read']} />}>
          <Route path="/app/dashboard" element={<Dashboard />} />
          <Route path="/app/dashboard/:id/:envId" element={<Dashboard />} />
        </Route>
        <Route path="/app/dash_management" element={<RequireAuth roles={['bi_dashboards_create_any', 'bi_dashboards_create']} />}>
          <Route path="/app/dash_management/functionalities" element={<Funcionalidades />} />
          <Route path="/app/dash_management/dashboard/:id" element={<DashboardEdit />} />
          <Route path="/app/dash_management/dashboard/:id/environment/:envId" element={<DashboardEnvEdit />} />
          <Route path="/app/dash_management/dashboard/new" element={<DashboardNew />} />
          <Route path="/app/dash_management/environment/:id" element={<EnvironmentEdit />} />
          <Route path="/app/dash_management/rules" element={<DashboardRules />} />
          <Route path="/app/dash_management/rules/:id" element={<DashboardRuleEdit />} />
        </Route>

        <Route path="/app/comptrade" element={<RequireAuth roles={['comptrade_read_est']} />}>
          <Route path="/app/comptrade/est" element={<ComptradeEst/>} />
          <Route path="/app/comptrade/est/:id" element={<ComptradeEstEdit />} />

        </Route>

        <Route path="/app/trct" element={<RequireAuth roles={['showmenu_trct']} />}>
          <Route path="/app/trct/list" element={<TrctOverview/>} />
          <Route path="/app/trct/include" element={<TrctInclude />} />
          <Route path="/app/trct/log" element={<TrctLog />} />
        </Route>

        <Route path="/app/dash_management" element={<RequireAuth roles={['showmenu_bi_dashboards_edit']} />}>
          <Route path="/app/dash_management/users" element={<Usuarios />} />
        </Route>
        
        <Route path="/app/clientes" element={<RequireAuth roles={['showmenu_recruitermaps']} />}>
          <Route path="/app/clientes/listagem" element={<Clientes />} />
          <Route path="/app/clientes/usuarios" element={<ClientesAll />} />
          <Route path="/app/clientes/unidades/:idCliente" element={<Clientes />} />
          <Route path="/app/clientes/unidade/:idUnidade" element={<Clientes />} />
        </Route>

        <Route path="/app/chamados" element={<RequireAuth roles={['showmenu_ticket_res']} />}>
          <Route path="/app/chamados/meus-chamados" element={<Chamados />} />
          <Route path="/app/chamados/detalhe/:idChamado" element={<Chamado />} />
          <Route path="/app/chamados/novo" element={<ChamadoNew />} />
          <Route path="/app/chamados/novo/multi-funcoes-por-local/:idTypeContrato" element={<ChamadoNewMultFuncoes />} />
          <Route path="/app/chamados/novo/multi-locais-por-funcoes/:idTypeContrato" element={<ChamadoNewMultLocais />} />
        </Route>
        
        <Route path="/nao-autorizado" element={<AccessDenied />} />
        
        <Route path="/auth" element={<Login />}>
          <Route path="/auth" element={<Login />} />
          <Route path="/auth/signin" element={<Login />} />
        </Route>
        <Route path="/" element={<Login />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default SystemRoutes
