import { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { debounce } from 'lodash'

import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
  Autocomplete,
  CircularProgress,
  MenuItem
} from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useAuth } from '../../../hooks/useAuth'
import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import services from '../../../services/index.js'

interface IPerfis {
  list: any[] | []
  remove: any
  add: any
  ticketDataId?: number
  ticketStatus?: string
  indexLocal?: number
  indexFuncao?: number
  edicaoMode?: boolean
}

function Perfis(props: IPerfis) {
  const { 
    list, remove, add, ticketDataId, 
    ticketStatus, indexLocal, indexFuncao, edicaoMode
  } = props;
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const { notify } = useNotification()
  const { promiseInProgress } = usePromiseTracker();

  const [modalAdd, setModalAdd] = useState<boolean>(false)

  const [tipoItemList, setTipoItemList] = useState<any>([
    {id: 1, nome: 'Curso'},
    {id: 2, nome: 'Idioma'},
    {id: 3, nome: 'Personalizado'}
  ])
  const [tipoCursoList, setTipoCursoList] = useState<any>([])
  const [cursoNomeList, setCursoNomeList] = useState<any>([])
  const [nivelList, setNivelList] = useState<any>([
    {nome: 'Completo ', id: 1}, {nome: 'Cursando', id: 2}
  ])
  const [obrigatoriedadeList, setObrigatoriedadeList] = useState<any>([
    {nome: 'Sim'}, {nome: 'Não'}
  ])
  const [idiomaList, setIdiomaList] = useState<any>([
    {nome: 'Inglês', id: 1}, {nome: 'Espanhol', id: 2}, 
    {nome: 'Mandarim (Chinês)', id: 3}, {nome: 'Alemão', id: 4}, 
    {nome: 'Francês', id: 5}, {nome: 'Árabe', id: 6}, {nome: 'Russo', id: 7}, 
    {nome: 'Japonês', id: 8}, {nome: 'Coreano', id: 9}, {nome: 'Português', id: 10}
  ])
  const [idiomaNivelList, setIdiomaNivelList] = useState<any>([
    {nome: 'Básico', id: 1}, {nome: 'Intermediário', id: 2}, {nome: 'Avançado', id: 3}, {nome: 'Fluente', id: 4}
  ])

  const [tipoItem, setTipoItem] = useState<string | number>('')
  const [tipoCurso, setTipoCurso] = useState<string>('')
  const [cursoSelected, setCursoSelected] = useState<any>({})
  const [nivel, setNivel] = useState<string>('')
  const [obrigatoriedade, setObrigatoriedade] = useState<string>('')
  const [idioma, setIdioma] = useState<string>('')
  const [nomeItem, setNomeItem] = useState<string>('')
  const [descricao, setDescricao] = useState<string>('')

  const [blockEdicao, setBlockEdicao] = useState<boolean>(true)

  const [statusRetorno, setStatusRetorno] = useState('Busque por Usuários')
  const load = statusRetorno === 'Buscando...'

  const changeTipoCurso = async (value: string) => {
    setTipoCurso(value)
    setCursoSelected({})

    try {
      const response:any = await trackPromise(services.api.getCursosAllPaged(value, ''))
      if (response.fail) {
        throw new Error('Não foi possível carregar os cursos')
      }
      setStatusRetorno('Busque pelo cursos')
      setCursoNomeList(response.data.data)
      if (response.data.data.length === 1) {
        setCursoSelected(response.data.data[0])
      }
    } catch (error: any) {
      notify(error.message, { variant: 'warning' })
    }
  }

  const retornaItem = (item: any) => {
    setCursoSelected(item)
  }
  const buscarItem = (value:string) => {
    if (value.length > 2 || !value.length) {
      setStatusRetorno('Buscando...')
      handle(value)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handle = useCallback(
    debounce(async nomeCurso => {
      try {
        const response:any = await trackPromise(services.api.getCursosAllPaged(tipoCurso, nomeCurso))
        if (response.fail) {
          throw new Error('Não foi possível carregar os cursos')
        }
        setStatusRetorno('Busque pelo curso')
        setCursoNomeList(response.data.data)
      } catch (error: any) {
        notify(error.message, { variant: 'warning' })
      }
    }, 700),
    [tipoCurso]
  )

  const resetModal = () => {
    setModalAdd(false)
    setTipoItem('')
    setTipoCurso('')
    //setCursoNome('')
    setCursoSelected({})
    setNivel('')
    setObrigatoriedade('')
    setIdioma('')
    setNomeItem('')
    setDescricao('')
  }

  const adicionar = async () => {
    function validForm() {
      if (!tipoItem) {
        notify('Informe o tipo de item', { variant: 'warning' })
        return false
      }
      if (tipoItem === 1) {
        if (!tipoCurso) {
          notify('Informe o tipo de curso', { variant: 'warning' })
          return false
        }
        if (!cursoSelected?.id) {
          notify('Informe o nome do curso', { variant: 'warning' })
          return false
        }
        if (!nivel) {
          notify('Informe o nivel', { variant: 'warning' })
          return false
        }
      }
      if (tipoItem === 2) {
        if (!idioma) {
          notify('Informe o idioma', { variant: 'warning' })
          return false
        }
        if (!nivel) {
          notify('Informe o nivel', { variant: 'warning' })
          return false
        }
      }
      if (tipoItem === 3) {
        if (!nomeItem) {
          notify('Informe o Nome do item', { variant: 'warning' })
          return false
        }
        if (!descricao) {
          notify('Informe a descrição do item', { variant: 'warning' })
          return false
        }
      }
      if (!obrigatoriedade) {
        notify('Informe a Obrigatoriedade', { variant: 'warning' })
        return false
      }
      return true
    }

    // console.log('tipoItem', tipoItem)
    // console.log('tipoCurso', tipoCurso)
    // console.log('cursoSelected', cursoSelected)
    // console.log('nivel', nivel)
    // console.log('obrigatoriedade', obrigatoriedade)
    // console.log('idioma', idioma)
    // console.log('nomeItem', nomeItem)
    // console.log('descricao', descricao)
    
    if (validForm()) {
      let nomeDoCursoConcat = ''
      let descricaoDoCursoConcat = ''
      let mandatory = obrigatoriedade === 'Sim' ? true : false
      
      if (tipoItem === 1) {
        nomeDoCursoConcat = tipoCursoList.find((x:any) => x?.id === tipoCurso).nome
        descricaoDoCursoConcat = cursoSelected.nome + ' - ' + nivelList.find((x:any) => x?.id === nivel).nome
      }
      if (tipoItem === 2) {
        nomeDoCursoConcat = tipoItemList.find((x:any) => x?.id === tipoItem).nome
        descricaoDoCursoConcat = idiomaList.find((x:any) => x?.id === idioma).nome +  ' - ' + idiomaNivelList.find((x:any) => x?.id === nivel).nome
      }
      if (tipoItem === 3) {
        nomeDoCursoConcat = nomeItem
        descricaoDoCursoConcat = descricao
      }
      // console.log('-------------------------------------------')
      // console.log('nomeDoCursoConcat', nomeDoCursoConcat)
      // console.log('descricaoDoCursoConcat', descricaoDoCursoConcat)
      // console.log('mandatory', mandatory)
      if(ticketDataId) {
        // tela de edição
        try {
          const res = await trackPromise(services.api.addPerfil(ticketDataId, nomeDoCursoConcat, descricaoDoCursoConcat, mandatory))
          if (res.fail) {
            throw new Error(
              res.error || 'Não foi possível adicionar o perfil'
            )
          }
          add(nomeDoCursoConcat, descricaoDoCursoConcat, mandatory, res.data.id)
          resetModal()
        } catch (error: any) {
          notify(error.message, { variant: 'error' })
        }
      } else {
        add(nomeDoCursoConcat, descricaoDoCursoConcat, mandatory, 0, indexLocal, indexFuncao)
        resetModal()
      }
    } 
    
  }

  const handleRemove = async (index: number) => {
    if(ticketDataId) {
      try {
        const res = await trackPromise(services.api.deletePerfil(list[index].id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível remover o beneficio'
          )
        }
        remove(index)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      remove(index, indexLocal, indexFuncao)
    }
  }

  const handleOpenModal = async () => {
    try {
      const [ resLevels ] = await trackPromise(
        Promise.all(
          [
            services.api.getCursosLevels(),
          ]
        )
      )
      if (resLevels.fail) {
        throw new Error(
          resLevels.error || 'Não foi possível consultar niveis'
        )
      }

      setTipoCursoList(resLevels.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

    setModalAdd(true)
  }

  useEffect(() => {
    if (!ticketStatus) {
      setBlockEdicao(false)
      return
    }
    if(
      (
        userRoles.includes('ticket_res_update') ||
        userRoles.includes('ticket_res_update_any')
      ) &&
      (ticketStatus === 'IN_PROGRESS' || ticketStatus === 'IN_REVISION')
    ) {
      setBlockEdicao(false)
      return
    }
    setBlockEdicao(true)
  }, [ticketStatus])
  
  return (
    <>
      <div className="flex gap-10 justify-between items-center mb-10" style={{borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px'}}>
        Perfil
      </div>

      <div className='table-container'>
        <table className='table_styled'>
          <thead>
            <tr>
              <th>
                <div>
                  <span className="th-title">Item</span>
                </div>
              </th>
              <th>
                <div>
                  <span className="th-title">Descrição</span>
                </div>
              </th>
              <th>
                <div>
                  <span className="th-title">Obrigatoriedade</span>
                </div>
              </th>
              {
                !edicaoMode &&
                <th>
                  <div className='flex justify-end'>
                    <Tooltip title="Adicionar Perfil">
                      <span>
                        <IconButton
                          style={{ color: 'white', marginRight: '6px',  borderRadius: '4px'}}
                          onClick={handleOpenModal}
                          edge="end"
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {list?.length === 0 ? 
              <tr><td colSpan={!edicaoMode ? 4 : 3}><div className='flex justify-center'>Não ha Itens</div></td></tr> : ''
            }
            {
              list.map((item, index) => (
                <tr key={index}>
                  <td><div>{item.name}</div></td>
                  <td><div>{item.description}</div></td>
                  <td><div>{item.mandatory ? 'Sim' : 'Não'}</div></td>
                  {
                    !edicaoMode &&
                    <td>
                      <div className='flex justify-end'>
                        <Tooltip title="Excluir Perfil">
                          <span>
                            <IconButton
                              style={{backgroundColor: 'red', color: 'white', marginRight: '0px',  borderRadius: '4px'}}
                              onClick={() => handleRemove(index)}
                              edge="end"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      {/* modal add  */}
      <Modal
        size='sm'
        open={modalAdd}
        close={() => setModalAdd(false)}
        titulo={'Adicionar item ao perfil'}
      >
        <Grid container spacing={{ xs: 3 }} className='mt-0'>
          <Grid item xs={12} md={6}>
            <FormControl>
              <InputLabel shrink>Tipo de item</InputLabel>
              <Select
                fullWidth
                required
                notched
                size="small"
                label="Tipo de item"
                value={tipoItem || ''}
                onChange={e => 
                  setTipoItem(e.target.value)
                }
              >
                <MenuItem value={""} >
                  Selecione
                </MenuItem>
                {
                  tipoItemList?.map((tipo: any) => (
                    <MenuItem value={tipo.id} key={tipo.id}>
                      {tipo.nome}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {Number(tipoItem) === 1 &&
            <Grid item xs={12} md={6}>
              <FormControl>
                <InputLabel shrink>Tipo do curso</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Tipo do curso"
                  value={tipoCurso || ''}
                  onChange={e => 
                    changeTipoCurso(e.target.value)
                  }
                >
                  <MenuItem value={""} >
                    Selecione
                  </MenuItem>
                  {
                    tipoCursoList?.map((tipo: any) => (
                      <MenuItem value={tipo.id} key={tipo.id}>
                        {tipo.nome}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {Number(tipoItem) === 1 &&
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={cursoSelected}
                onChange={(event, newValue) => {
                  retornaItem(newValue)
                }}
                isOptionEqualToValue={(option:any, value: any) =>
                  option.nome === value.nome
                }
                getOptionLabel={(option:any) => option?.nome || ''}
                disableClearable
                options={cursoNomeList}
                noOptionsText={statusRetorno}
                loading={load}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    label="Nome do curso"
                    onChange={e => buscarItem(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {load && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          }
          {Number(tipoItem) === 2 &&
            <Grid item xs={12} md={6}>
              <FormControl>
                <InputLabel shrink>Idioma</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Idioma"
                  value={idioma || ''}
                  onChange={e => 
                    setIdioma(e.target.value)
                  }
                >
                  <MenuItem value={""} >
                    Selecione
                  </MenuItem>
                  {
                    idiomaList?.map((tipo: any) => (
                      <MenuItem value={tipo.id} key={tipo.id}>
                        {tipo.nome}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {Number(tipoItem) === 2 &&
            <Grid item xs={12} md={6}>
              <FormControl>
                <InputLabel shrink>Nível</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Nível"
                  value={nivel || ''}
                  onChange={e => 
                    setNivel(e.target.value)
                  }
                >
                  <MenuItem value={""} >
                    Selecione
                  </MenuItem>
                  {
                    idiomaNivelList?.map((tipo: any) => (
                      <MenuItem value={tipo.id} key={tipo.id}>
                        {tipo.nome}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {Number(tipoItem) === 1 &&
            <Grid item xs={12} md={6}>
              <FormControl>
                <InputLabel shrink>Nível</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Nível"
                  value={nivel || ''}
                  onChange={e => 
                    setNivel(e.target.value)
                  }
                >
                  <MenuItem value={""} >
                    Selecione
                  </MenuItem>
                  {
                    nivelList?.map((tipo: any) => (
                      <MenuItem value={tipo.id} key={tipo.id}>
                        {tipo.nome}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {Number(tipoItem) === 3 &&
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome do item"
                inputProps={{ maxLength: 120 }}
                value={nomeItem}
                onChange={e => 
                  setNomeItem(e.target.value)
                }
              />
            </Grid>
          }
          {Number(tipoItem) === 3 &&
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                size='small'
                label="Descrição"
                inputProps={{ maxLength: 120 }}
                value={descricao}
                onChange={e => 
                  setDescricao(e.target.value)
                }
              />
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <FormControl>
              <InputLabel shrink>Obrigatoriedade</InputLabel>
              <Select
                fullWidth
                required
                notched
                size="small"
                label="Obrigatoriedade"
                value={obrigatoriedade || ''}
                onChange={e => 
                  setObrigatoriedade(e.target.value)
                }
              >
                <MenuItem value={""} >
                  Selecione
                </MenuItem>
                {
                  obrigatoriedadeList?.map((tipo: any) => (
                    <MenuItem value={tipo.nome} key={tipo.nome}>
                      {tipo.nome}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size='small'
              color="inherit"
              onClick={() => setModalAdd(false)}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size='small'
              color="secondary" 
              disabled={promiseInProgress}
              onClick={adicionar}
            >
              adicionar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default Perfis
