import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { } from 'react'
import { Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import './style.scss'
import { IDashboardEnvUser } from '../../interfaces'
import {IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import moment from 'moment'


interface TableEnvironmentsProps{
  dataSource: IDashboardEnvUser[]
  deleteDashboardUser: any
  dashboardId: number
  environmentId: number
}

const TableEnvUsers: React.FC<TableEnvironmentsProps> = ({ deleteDashboardUser, dataSource}) => {

  const columns: ColumnsType<IDashboardEnvUser> = [
    {
      title: 'Nome',
      render: (_, record) => {
        return (record.user.name)
      },
    },
    {
      title: 'Email',
      render: (_, record) => {
        return (record.user.email)
      },
    },
    {
      title: 'Empresa',
      render: (_, record) => {
        return (record.user.company)
      },
    },
    {
      title: 'Adicionado em',
      render: (_, record) => {
        return (moment(record.createdAt).format('DD/MM/YYYY HH:mm'))
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <div style={{textAlign: 'right'}}>
          <Popconfirm title="Deseja deletar este acesso?" cancelText={"Cancelar"} okText={"Deletar"} onConfirm={() => deleteDashboardUser(record.id)}>
            <IconButton className='btn-purple mr-6 content-end' disabled={false}>
              <DeleteForeverIcon fontSize='small' />
            </IconButton>
          </Popconfirm>
        </div>
      ),
    },
  ];


  return (
    <div style={{textAlign: "right"}}>
      <Table
        columns={columns}
        dataSource={dataSource?.map((item, index) => ({ ...item, key: `${item.id}-${index}` }))}
        locale={{ emptyText:
            ' Nenhum usuário'
        }}
        pagination={false}
      />;

    </div>


  );
};

export default TableEnvUsers;