import { useCallback, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  Grid,
  TextField,
  Button
} from '@mui/material'

import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import services from '../../../services/index.js'
import { useAuth } from '../../../hooks/useAuth'

interface IModelComponent {
  ticketId: number | undefined
  attendant: any
  filialId: any
  reload: any
}

function Attendant(props: IModelComponent) {
  const { ticketId, attendant, filialId, reload } = props;
  const { promiseInProgress } = usePromiseTracker();
  const { notify } = useNotification()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [modal, setModal] = useState<boolean>(false)
  const [availableAttendants, setAvailableAttendants] = useState<any>(null)
  const [availableAttendantsFullList, setAvailableAttendantsFullList] = useState<any>(null)
  const [search, setSearch] = useState<string>('')

  const handleSearch = async (value: string) => {
    setSearch(value);
    try {
      let paramns: any = {}
      paramns.filterBy = 'fullName'
      paramns.filterValue = value
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 10))
      
      if (res.fail) {
        setAvailableAttendants([])
        throw new Error(
          res.error || 'Não foi possível consultar atendentes disponíveis'
        )
      }
      setAvailableAttendants(res.data)
      setAvailableAttendantsFullList(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleopen = async () => {
    setModal(true)
    try {
      const res = await trackPromise(services.api.getUsersAll(1, 10))
      
      if (res.fail) {
        setAvailableAttendants([])
        throw new Error(
          res.error || 'Não foi possível consultar atendentes disponíveis'
        )
      }
      setAvailableAttendants(res.data)
      setAvailableAttendantsFullList(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const changeAttendant = async (atendente:any) => {
    try {
      const res = await trackPromise(services.api.setAttendants(ticketId, atendente.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi atribuir atendente'
        )
      }
      notify('Atendente alterado com sucesso', { variant: 'success' })
      reload()
      setModal(false)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }
  
  return (
    <>
      {ticketId && attendant && 
        <Button
          variant="contained"
          type="button"
          size='small'
          disabled={promiseInProgress}
          color="secondary" 
          onClick={handleopen}
        >
          Atribuir Chamado
        </Button>
      }

      <Modal
        size='sm'
        open={modal}
        close={() => setModal(false)}
        titulo={'Atribuir Chamado'}
      >
        <div className='chamados_box sm-min-500'>
          <Grid container spacing={{ xs: 3 }}>
            {
              (
                userRoles.includes("ticket_messages_create") ||
                userRoles.includes("ticket_messages_create_any")
              ) && 
              <>
                <Grid item xs={12} >
                  <TextField
                    fullWidth
                    required
                    size='small'
                    label="Buscar"
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ shrink: true }}
                    value={search || ''}
                    onChange={e => 
                      handleSearch(e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className='table-container'>
                    <table className='table_styled'>
                      <thead>
                        <tr>
                          <th>
                            <div>
                              <span className='th-title'>Usuário</span>
                            </div>
                          </th>
                          <th><div><span className='th-title'></span></div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {!availableAttendants && 
                          <tr><td colSpan={8}><div className='justify-center'>loading...</div></td></tr>
                        }
                        {availableAttendants?.length === 0 && 
                          <tr><td colSpan={8}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                        }
                        {availableAttendants?.data.map((item: any, index: number) => (
                          <tr key={item.id}>
                            <td>
                              <div style={{flexDirection: 'column', alignItems: 'start'}}>
                                <span>{item.name}</span>
                                <span style={{fontSize: '12px'}}>{item.email}</span>
                                <span style={{fontSize: '12px'}}>{item.company}</span>
                              </div>
                            </td>
                            <td>
                              <div className='justify-end'>
                                <Button 
                                  variant="contained" type="button" color="secondary" 
                                  onClick={() => changeAttendant(item)} 
                                  size="small"
                                  disabled={promiseInProgress}
                                >
                                  <span>Atribuir</span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Grid>
                
              </>
            }
          </Grid>

        </div>
      </Modal>

    </>
  )
}

export default Attendant
