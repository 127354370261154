import trade from "../company/trade/content.json"
import pop from "../company/pop/content.json"
import seven from "../company/seven/content.json"
import trademark from "../company/trademark/content.json"


export const getCompany = ():CompanyProps => {
  const company = process.env.NEXT_PUBLIC_COMPANY
  return trade
}


interface CompanyProps {
    companyName: string
    slug: string
    legalName: string
    imageUrl: string
  }