import { createContext, useContext } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useSnackbar } from 'notistack'

const NotificationContext = createContext()

const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const deliveryMesage = (mensagem, options) => {
    enqueueSnackbar(mensagem, {
      variant: options?.variant,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top'
      },
      action: key => (
        <>
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        </>
      )
    })
  }

  const notify = (mensagem, options) => {
    let stringMessage
	  try {
      if(mensagem.toString().includes("||")){
        let splited = mensagem.split("||")
        splited.forEach(item => {
          if(item !== "")
            deliveryMesage(item, options);
        });
      }
      else{
        stringMessage = JSON.parse(mensagem)
        let textA = Object.values(stringMessage)[0]
        deliveryMesage(textA, options)
      }
    } catch (error) {
      stringMessage = mensagem
      deliveryMesage(stringMessage, options)
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        notify
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

function useNotification() {
  const context = useContext(NotificationContext)
  return context
}

export { NotificationProvider, useNotification }
