import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'

import {
  Grid,
  Button,
  IconButton
} from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';


import { IUser } from '../../interfaces/index'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services/index.js'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useAuth } from '../../hooks/useAuth'

import Modal from '../../components/Modal'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { TabelaSearchColumn  } from '../../components/Table'

interface ITableaUsuario {
  mainList: Array<any>
  reload?: any
  user?: any
  objBusca: any
  setObjBusca: any
  filter:any,
  handleOrderby: any
}


function TabelaHeaderTh(props: any) {
  const { 
    indexRef, visibleColumn, paramns, setSearchChange, ordeby,
  } = props;
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
         {/* {((userRoles === 'ADMINISTRATOR') || (userRoles === 'CUSTOMER_ADMINISTRATOR' && idParentAtivo)) ? */}
          {
            (
              paramns.label === 'Ações' &&
              (
                userRoles.includes("bi_teams_read_any")
              )
            ) || paramns.label !== 'Ações' ?
            
              <th>
                <div>
                  <div>
                    {
                      paramns?.showOrderby &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false, paramns.searchRef)}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true, paramns.searchRef)}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                    
                    <span className='th-title'>{paramns.label}</span>
 
                  </div>
                  {
                    paramns?.showSearch &&
                    <div>
                      <SearchIcon fontSize='small' className='tableSeachIccon' 
                        onClick={() => 
                          setSearchChange((prevState:any) => [
                            ...prevState.slice(0, indexRef),
                            {...prevState[indexRef], inputVisible: true},
                            ...prevState.slice(indexRef + 1),
                          ])
                        }
                      />
                    </div>
                  }
                </div>
              </th>
              
            :
            ''
          }
        </>
      }
    </>
  )
}

function TabelaGrupo(props: ITableaUsuario) {
  const { 
     mainList, reload, user, objBusca, setObjBusca, filter, handleOrderby
  } = props;
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const { notify } = useNotification()

  const [filterList, setFilterList] = useState<any>([])

  const [grupoSelected, setGrupoSelected] = useState<any>({})

  const [modalDeletarUsuario, setModalDeletarUsuario] = useState<boolean>(false)

  const [visibleColumns, setVisibleColumns] = useState<any>(["ID", "Nome", "Ações"])

  const [anchorElOrderby, setAnchorElOrderby] = useState<null | HTMLElement>(null);
  const openOrderby = Boolean(anchorElOrderby);
  const handleCloseOrderby = () => {
    setAnchorElOrderby(null);
  };

  const deletarUsuario = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      if (!grupoSelected.id) {
        throw new Error('Não foi possível identificar o item selecionado')
      }

      const res:any = await trackPromise(services.api.removeUserFromTeam(grupoSelected.id, user.id))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Remover grupo')
      }
      notify(`Grupo Removido com Sucesso`, { variant: 'success' })
      reload(user, true)
      setModalDeletarUsuario(false)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleDelete = (grupo: any) => {

    setGrupoSelected(grupo)
    setModalDeletarUsuario(true)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    filter(busca)
    console.log('buca')
  }, 800),  [])

  const handleChangeBusca = (busca:string) => {
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    handleCloseOrderby()
    handleOrderby(column, direction)
  }

  useEffect(() => {
    setFilterList(mainList.map((item:IUser) => {
      return item
    }))
  }, [mainList])

  return (
    <>
      <div className=''>
        <table className='table_styled table_selectable'>
          <thead>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn 
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)} 
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh 
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)} 
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                    // setListItensSelected={setListItensSelected}
                    // itemsSelecionadosLength={listItensSelected.length}
                    // rows={filterList}
                  />
                ))
              }
            </tr>
          </thead>
          <tbody>
            {!filterList && 
              <tr><td colSpan={userRoles.includes("bi_teams_read_any") ? 3 : 2}><div className='justify-center'>loading...</div></td></tr>
            }
            {filterList?.length === 0 && <tr><td colSpan={userRoles.includes("bi_teams_read_any") ? 3 : 2}><div style={{textAlign: 'center'}}>Nenhum dado foi encontrado</div></td></tr> }
            {filterList.map((item: any, index: number) => (
              <tr key={item.id}>
                <td><div>{item.id}</div></td>
                <td><div>{item.name}</div></td>
                {userRoles.includes("bi_teams_read_any") &&
                  <td>
                    <div className='flex justify-end'>
                      <IconButton  
                        size="small" aria-label="editar senha" component="label" 
                        onClick={() => handleDelete(item)}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>

      </div>

      {/* modal confirmar exclusao */}
      <Modal
        size='sm'
        open={modalDeletarUsuario}
        close={() => setModalDeletarUsuario(false)}
        titulo={'Remover Usuário'}
      >
        <form onSubmit={deletarUsuario}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className='center'>
                Deseja mesmo remover este registro? <br />
              </p>
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalDeletarUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="error"
                disabled={promiseInProgress}
              >
                desativar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

    </>
  )
}

export default TabelaGrupo
