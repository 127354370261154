import * as React from 'react';
import { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useNotification } from '../../hooks/useNotification.js'
import { debounce } from 'lodash'
// import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../components/Modal'

import {
  TextField,
  Tooltip,
  InputAdornment,
  Grid,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  IconButton
} from '@mui/material'

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import services from '../../services/index.js'
import { ITeam } from '../../interfaces/index'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useAuth } from '../../hooks/useAuth'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import './style.scss'

interface ISubMenuAdminComponent {
  mainList?: Array<ITeam> | [] | undefined
  totalRegistros?: number
  listEnvironments?: Array<ITeam> | []
  actionEdit?: any
  actionRemove?: any
  title: string
  subTitle?: string
  actionAdd?: any
  actionSelect?: any
  idItemAtivo?: number | undefined
  classNameContainer?: string
}

const ContentItens = (props: any) => {
  const {
    subTitle, userRoles, actionAdd, promiseInProgress, search,
    setSearch, idItemAtivo, clickItem, totalRegistros, filterList, handleClick, open
  } = props;
  return (
    <>
      {subTitle && 
        <label className="labelAllis big" style={{marginTop: '20px'}}>
          <span>{subTitle}</span>
          {
            actionAdd && (
              userRoles.includes('bi_teams_create') 
            ) &&
            <Tooltip title={`Criar ${subTitle.toLowerCase()}`} className="mr-6">
              <span>
                <IconButton size="small" aria-label="Criar Equipe" 
                  onClick={actionAdd} disabled={promiseInProgress}
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          }
        </label>
      }
      
      <div className='submenu-content'>
        <div className='mb-14 mr-6'>
          <TextField
            size='small'
            className='btn-rounded'
            placeholder='Busca'
            inputProps={{ maxLength: 100 }}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        <ul>
          <li className={`item-group ${!idItemAtivo  ? 'active' : ''}`} >
            <div className='submenu-title-group'>
              <div 
                onClick={() => clickItem()}
              >
                <PeopleAltOutlinedIcon fontSize="small" /> Todos 
                {totalRegistros ? ` (${totalRegistros})` : ''}
              </div> 
            </div>
          </li>
          {filterList?.map((item: ITeam, index: number) => (
            <li className={`item-group item-id-${item.id} ${idItemAtivo === item.id ? 'active' : ''}`} key={item.id}>
              <div className='submenu-title-group'>
                <div onClick={() => clickItem(item)}>
                  <PeopleAltOutlinedIcon fontSize="small" /> {item.name} {`(${item.members.length})`}
                </div> 
                <span>
                  {
                    (
                      userRoles.includes('bi_teams_addusers') || 
                      userRoles.includes('bi_teams_addusers_any') || 
                      userRoles.includes('bi_teams_update') || 
                      userRoles.includes('bi_teams_delete')
                    ) &&
                    <Tooltip title={`Opções`}>
                      <span>
                        <IconButton 
                          size="small" aria-label="opções"
                          onClick={(e) => handleClick(e, item)}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          disabled={promiseInProgress}
                        >
                          <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  } 
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

const SubMenuAdmin = (props: ISubMenuAdminComponent) => {
  const { 
    mainList, actionRemove, actionEdit, totalRegistros, classNameContainer,
    actionSelect, idItemAtivo, subTitle, actionAdd
  } = props
  const { promiseInProgress } = usePromiseTracker()

  const [showMsgRegistros, setShowMsgRegistros] = useState(true)
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [filterList, setFilterList] = useState<any>([])
  const [search, setSearch] = useState('')
  
  const [filterUsers, setFilterUsers] = useState<any>([])
  const [users, setUsers] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)

  const [itemSelecionado, setItemSelecionado] = useState<any>({})
  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setAnchorEl(event.currentTarget);
    setItemSelecionado(item)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEnableAddUser(false)
  };

  const [enableAddUser, setEnableAddUser] = useState<boolean>(false);
  const handleClickUser = () => {
    setEnableAddUser(true)
    if (userRoles.includes('common_users_read') || userRoles.includes('common_users_read_any')) {
      getUsers()
    }
    actionSelect(itemSelecionado)

    handleClose()
    setModalPerfil(true)
  };

  const clickItem = (subMenu?: ITeam | undefined) => {
    if (subMenu) {
      actionSelect(subMenu)
    } else {
      actionSelect({id: 0, name: 'Todos'})
    }
  }

  const toggleContainerMenu = () => {
    const menuContainer:any = document.getElementById('wrapper-container-submenuAdmin')
    if (menuContainer) {
      menuContainer.classList.toggle('hide')
      menuContainer.style = ''
    }
    // const pageContainer:any = document.getElementById('wrapper-page-content')
    // if (pageContainer) {
    //   pageContainer.classList.toggle('reductedSizeMenu')
    // }

    if (document.body.clientWidth < 768) {
      setOpenModal(true)
    }
  }

  const editarItem = () => {
    handleClose()
    actionEdit(itemSelecionado)
  }

  const removerItem = () => {
    handleClose()
    actionRemove(itemSelecionado)
  }

 
  const [modalPerfil, setModalPerfil] = useState(false)
  const [userDisponiveis, setUserDisponiveis] = useState<any>(null)
  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')
  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null);
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel);
  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget);
  };
  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null);
  };

  const handleChangeBuscaUserDisponivel = (busca:string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800),  [colunaBuscaUserDisponivel])

  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca
    
    // try {
    //   const res = await trackPromise(services.api.getUsersDisponiveis(paramns, 1, 20))
    //   if (res.fail) {
    //     throw new Error(
    //       res.error || 'Não foi possível consultar usuários disponiveis'
    //     )
    //   }
    //   setUserDisponiveis(res.data.data)
    // } catch (error: any) {
    //   notify(error.message, { variant: 'error' })
    // }
    try {
      let termoBuscado = busca ? busca : busca
      let res: any
      let paramns = {adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : ''}
      if (userRoles.includes("common_users_read_any")) {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      } else {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      let usersOrdeby = []
      if (userRoles.includes("common_users_read_any")) {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
      } else {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        // usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
      }
      setFilterUsers(usersOrdeby)
    } catch (error: any) {
      notify(error.message, { variant: 'warning' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])


  const getUsers = useCallback(async (busca?: string) => {
    try {
      let termoBuscado = busca ? busca : busca
      let res: any
      let paramns = {adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : ''}
      if (userRoles.includes("common_users_read_any")) {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      } else {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      let usersOrdeby = []
      if (userRoles.includes("common_users_read_any")) {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
      } else {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        //usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
      }
      setUsers(usersOrdeby)
      setFilterUsers(usersOrdeby)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handle = useCallback(
    debounce(async busca => {
      if (busca.length) {
        setShowMsgRegistros(false)
      } else {
        setShowMsgRegistros(true)
      }
      try {
        let termoBuscado = busca ? busca : busca
        let res: any
        let paramns = {adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : ''}
        if (userRoles.includes("common_users_read_any")) {
          res = await trackPromise(services.api.getUsersAll(paramns, 1))
        } else {
          res = await trackPromise(services.api.getUsersAll(paramns, 1))
        }
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Usuários'
          )
        }
        let usersOrdeby = []
        if (userRoles.includes("common_users_read_any")) {
          usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        } else {
          usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
          // usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
        }
        setFilterUsers(usersOrdeby)
      } catch (error: any) {
        notify(error.message, { variant: 'warning' })
      }
    }, 700),
    []
  )

  const vicularUsuarioComEquipe = useCallback(async (userId: any, equipe: ITeam, index: number) => {
    try {
      const res = await trackPromise(services.api.vicularUsuarioComEquipe(userId, equipe.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular usuário'
        )
      }
      actionSelect(equipe, true)
      // remover user ao add 
      setFilterUsers((prevState:any) => ([
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1)
        ]
      ))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickAddUser = (idUser: any, index: number) => {
    vicularUsuarioComEquipe(idUser, itemSelecionado, index)
  }

  useEffect(() => {
    getUsers()
  },[getUsers])

  useEffect(() => {
    setFilterList(mainList)
  }, [mainList])

  useEffect(() => {
    if (search && mainList?.length) {
      const newBusca = mainList?.filter((item:any) => item.name.toUpperCase().includes(search.toUpperCase()))
      setFilterList(newBusca)
    } else {
      setFilterList(mainList)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const buscaClienteRegularUser = async (busca:string) => {
    if (busca && users?.length) {
      const newBusca = users?.filter((item:any) => item.name.toUpperCase().includes(busca.toUpperCase()))
      setFilterUsers(newBusca)
    } else {
      setFilterUsers(users)
    }
  }

  const buscarClienteAdmin = (value:string) => {
    handle(value)
  }

  useEffect(() => {
    const handleDragBar = (e:any) => {
      e.preventDefault();
      let subMenu:any = document.getElementById('wrapper-container-submenuAdmin')

      let larguraHeaderLateral = 64
      let somaPaddingSubmenu = 36
      let largTotalElement = 220 + somaPaddingSubmenu + larguraHeaderLateral
      let limit = e.clientX - largTotalElement

      if (limit > 0 && limit < 400 ) {
        subMenu.style.cssText = `width: ${e.clientX - (larguraHeaderLateral + somaPaddingSubmenu)}px`
      }
    }
    let dragbar:any = document.getElementById('dragbar')
    dragbar.addEventListener("drag", handleDragBar)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      let subMenu:any = document.getElementById('wrapper-container-submenuAdmin')
      if (subMenu) { subMenu.style = '' }
    }
    window.addEventListener("resize", handleResize, false);
  },[])

  return (
    <>
      <div className={`wrapper-container-submenuAdmin ${classNameContainer}`} id="wrapper-container-submenuAdmin">
        <div id="dragbar" draggable="true"></div>
        <div className='btn-toggle-submenuAdmin' onClick={toggleContainerMenu}>
          <KeyboardDoubleArrowLeftIcon />
        </div>

        <div className='wrapper-content-submenuAdmin'>
          {!openModal &&
            <ContentItens 
              toggleContainerMenu={toggleContainerMenu} 
              subTitle={subTitle} 
              userRoles={userRoles}
              actionAdd={actionAdd} 
              promiseInProgress={promiseInProgress} 
              search={search}
              setSearch={setSearch} 
              idItemAtivo={idItemAtivo} 
              clickItem={clickItem} 
              totalRegistros={totalRegistros} 
              filterList={filterList} 
              handleClick={handleClick} 
              open={open}
            />
          }
        </div>

      </div>

      <Modal
        size='sm'
        open={openModal}
        close={() => setOpenModal(false)}
        titulo={'Equipes'}
      >
        <div className='conteiner-submenu-modal'>
          {openModal &&
            <ContentItens 
              toggleContainerMenu={toggleContainerMenu} 
              subTitle={subTitle} 
              userRoles={userRoles}
              actionAdd={actionAdd} 
              promiseInProgress={promiseInProgress} 
              search={search}
              setSearch={setSearch} 
              idItemAtivo={idItemAtivo} 
              clickItem={clickItem} 
              totalRegistros={totalRegistros} 
              filterList={filterList} 
              handleClick={handleClick} 
              open={open}
              setOpenModal={setOpenModal}
            />
          }
        </div>
      </Modal>

      {/* menu opções */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {enableAddUser ?
          <>
            {/* <MenuItem>
              <TextField
                size='small'
                className='btn-rounded'
                placeholder='Busca'
                inputProps={{ maxLength: 100 }}
                onChange={e => buscarClienteAdmin(e.target.value)}
              />
            </MenuItem>
            {showMsgRegistros && <div className='feedbackRegistros'>Exibindo 20 primeiros registros, pesquise acima para mais resultados</div>}
            <div className='subMenuAdmin-filter-list' >
              {filterUsers?.map((item: IUser, index: number) => (
                <MenuItem key={item.id} className="fontSmall" 
                  onClick={() => handleClickAddUser(item.id, index)} 
                  disabled={promiseInProgress}
                >
                  <ListItemIcon>
                    <PersonAddOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  {item.name}
                </MenuItem>
              ))}
            </div> */}
          </>
        :
          <div>
            {(userRoles.includes("bi_teams_addusers_any") || userRoles.includes("bi_teams_addusers")) &&
              <MenuItem onClick={handleClickUser}>
                <ListItemIcon>
                  <AddBoxOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Adicionar Usuário
              </MenuItem>
            }
            {userRoles.includes("bi_teams_update") &&
              <>
                <MenuItem onClick={editarItem}>
                  <ListItemIcon>
                    <ModeEditOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Editar
                </MenuItem>
              </>
            }
            {userRoles.includes("bi_teams_delete") &&
              <>
                <MenuItem onClick={removerItem}>
                  <ListItemIcon>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Remover
                </MenuItem>
              </>
            }
          </div>
        }
      </Menu>

      {/* vincular */}
      <Modal
        size='sm'
        open={modalPerfil}
        close={() => setModalPerfil(false)}
        titulo={'Adicionar Usuários'}
      >
        <Grid container spacing={{ xs: 3 }} id="Modal_seach_user">
          <Grid item md={12} xs={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item md={12}>
                <TextField
                  size='small'
                  className='w-200'
                  label='Pesquisa'
                  placeholder='Pesquisa'
                  inputProps={{ maxLength: 50 }}
                  value={searchUserDisponivel}
                  onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                  disabled={promiseInProgress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{backgroundColor: '$cor-primary', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                          aria-label="toggle password visibility"
                          onClick={(e) => handleClickBuscaUserDisponivel(e)}
                          edge="end"
                        >
                          <FilterAltIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <div className='table-container'>
                  <table className='table_styled'>
                    <thead>
                      <tr>
                        <th>
                          <div>
                            <span className='th-title'>Usuário</span>
                          </div>
                        </th>
                        <th><div><span className='th-title'></span></div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!filterUsers && 
                        <tr><td colSpan={8}><div className='justify-center'>loading...</div></td></tr>
                      }
                      {filterUsers?.length === 0 && 
                        <tr><td colSpan={8}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                      }
                      {filterUsers?.map((item: any, index: number) => (
                        <tr key={item.id}>
                          <td>
                            <div style={{flexDirection: 'column', alignItems: 'start'}}>
                              <span>{item.name}</span>
                              <span style={{fontSize: '12px'}}>{item.email}</span>
                              <span style={{fontSize: '12px'}}>{item.company}</span>
                            </div>
                          </td>
                          <td>
                            <div className='justify-end'>
                              <Button 
                                variant="contained" type="button" color="secondary" 
                                onClick={() => handleClickAddUser(item.id, index)} 
                                size="small"
                                disabled={promiseInProgress}
                              >
                                <span>Adicionar</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size='small'
              color="inherit"
              onClick={() => setModalPerfil(false)}
            >
              cancelar
            </Button>
          </Grid>
        </Grid>
       
      </Modal>

      {/* menu opções busca user disponivel*/}
      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>


    </>
  )
}

export default SubMenuAdmin;