import { usePromiseTracker } from 'react-promise-tracker'
import './style.scss'

interface IChamadosTotais {
  onSelect: any
  status: string
  value?: number
  active?: string
}

const ChamadosTotais = (props: IChamadosTotais) => {
  const { value,  onSelect, active, status} = props;
  const { promiseInProgress } = usePromiseTracker()

  const handleSelect = (status:string) => {
    if (!promiseInProgress) {
      onSelect(status)
    }
  }

  return (
    <div className={`chamados_totais_item ${status} ${active === status ? 'active' : ''}`} onClick={() => handleSelect(status)}>
      <div className='chamados_totais_value'>{value}</div> 
      <div className='chamados_totais_label'>
        {status === 'ALL' && 'Todos os Chamados'}
        {status === 'IN_PROGRESS' && 'Em Andamento'}
        {status === 'FINISHIED' && 'Chamados Finalizados'}
        {status === 'CANCELED' && 'Chamados Cancelados'}
        {status === 'AWAITING_CANCELATION_APROVEMENT' && 'Aguardando aprovação de cancelamento'}
        {status === 'AWAITING_FINISH_APROVEMENT' && 'Aguardando aprovação de conclusão'}
        {status === 'AWAITING_START' && 'Aguardando Início'}
        {status === 'AWAITING_ATTENDANT' && 'Aguardando Atendente'}
        {status === 'AWAITING_REVISION' && 'Aguardando revisão'}
        {status === 'IN_REVISION' && 'Em revisão'}
        {status === 'CANCELED_PARTIAL' && 'Cancelado parcial'}
      </div>
    </div>
  )
}

export default ChamadosTotais
