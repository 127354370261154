import React, { useState, useEffect } from 'react';
import { ConfigProvider, Select as AntdSelect } from 'antd'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid, Tooltip, IconButton
} from '@mui/material'
import { useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import services from '../../services';
import { Collapse, Divider, Input, Popconfirm, Space } from 'antd'
import { number } from '../../services/mask';
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification'
import {
  IComptradeEst, IComptradeEstUpdate,
  IDashboardEnvOverview,
  IDashboardFilter,
  IDropdownOptions,
  IEnvironment
} from '../../interfaces'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Modal from '../../components/Modal'
import { Delete, Download, Save, ThumbUp, WidthFull } from '@mui/icons-material'
import DropdownSearch from '../../components/DropdownSearch'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import moment from 'moment'
import { toNumber } from 'lodash'
import { addComptradeChannel, refreshEstFederalData } from '../../services/api'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import './style.scss'
import locale from 'antd/locale/pt_BR'
import RefreshIcon from '@mui/icons-material/Refresh'
import SettingsIcon from '@mui/icons-material/Settings'


function ComptradeEstEdit() {

  const [tipoId, setTipoId] = useState<string>("");
  const [idInterno, setIdInterno] = useState<string>("");
  const [razaoSocial, setRazaoSocial] = useState<string | undefined>();
  const [rede, setRede] = useState<string | undefined>();
  const [bandeira, setBandeira] = useState<string | undefined>();

  const [atividadePrincipal, setAtividadePrincipal] = useState<string | undefined>();
  const [atividadeSecundaria, setAtividadeSecundaria] = useState<string | undefined>();
  const [observacao, setObservacao] = useState<string | undefined>();
  const [subcanal, setSubcanal] = useState<string | undefined>();
  const [tipoLocal, setTipoLocal] = useState<string | undefined>();

  const [nomeFantasia, setNomeFantasia] = useState<string | undefined>();
  const [filial, setFilial] = useState<string | undefined>();
  const [canal, setCanal] = useState<string | undefined>();
  const [shopping, setShopping] = useState<string | undefined>();
  const [subRegiao, setSubRegiao] = useState<string | undefined>();

  const [tipoLogradouro, setTipoLogradouro] = useState<string | undefined>();
  const [logradouro, setLogradouro] = useState<string | undefined>();
  const [numero, setNumero] = useState<string | undefined>();
  const [complemento, setComplemento] = useState<string | undefined>();
  const [bairro, setBairro] = useState<string | undefined>();
  const [cep, setCep] = useState<string | undefined>();
  const [cidade, setCidade] = useState<string | undefined>();
  const [estado, setEstado] = useState<string | undefined>();
  const [regiao, setRegiao] = useState<string | undefined>();
  const [latitude, setLatitude] = useState<string | undefined>();
  const [longitude, setLongitude] = useState<string | undefined>();
  const [recSituacaoCadastral, setRecSituacaoCadastral] = useState<string | undefined>();
  const [recDataSituacaoCadastral, setRecDataSituacaoCadastral] = useState<string | undefined>();
  const [recRazaoSocial, setRecRazaoSocial] = useState<string | undefined>();
  const [recCapitalSocial, setRecCapitalSocial] = useState<string | undefined>();
  const [recNomeFantasia, setRecNomeFantasia] = useState<string | undefined>();
  const [recTipoLogradouro, setRecTipoLogradouro] = useState<string | undefined>();
  const [recLogradouro, setRecLogradouro] = useState<string | undefined>();
  const [recNumero, setRecNumero] = useState<string | undefined>();
  const [recComplemento, setRecComplemento] = useState<string | undefined>();
  const [recBairro, setRecBairro] = useState<string | undefined>();
  const [recCep, setRecCep] = useState<string | undefined>();
  const [recCidadeNome, setRecCidadeNome] = useState<string | undefined>();
  const [recEstadoSigla, setRecEstadoSigla] = useState<string | undefined>();
  const [ddd, setDdd] = useState<string | undefined>();
  const [telefone, setTelefone] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [criadoEm, setCriadoEm] = useState<string | undefined>();
  const [atualizadoEm, setAtualizadoEm] = useState<string | undefined>();
  const [higienizado, setHigienizado] = useState<boolean | undefined>();
  const [higienizadoEm, setHigienizadoEm] = useState<string | undefined>();
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [afterLoad, setAfterLoad] = useState<boolean>(false);
  const [canais, setCanais] = useState<string[]>([]);
  const [toCreateChannel, setToCreateChannel] = useState<string>('');
  const [subCanais, setSubCanais] = useState<string[]>([]);
  const [toCreateSubChannel, setToCreateSubChannel] = useState<string>('');

  const [placeTypes, setPlaceTypes] = useState<string[]>([]);
  const [toCreatePlaceTypes, setToCreatePlaceTypes] = useState<string>('');

  const center = {
    lat: 0,
    lng: 0
  };

  const [position, setPosition] = useState(center);

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const formatCurrency = (value: string | undefined) => {
    if (!value) return 'R$ 0,00';
    value = value.replace(/\D/g, '');
    value = parseInt(value) + '';
    value = value.replace('.', ',');
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return 'R$ ' + value;
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDFmJivsk7aqC2_Ea1saAu7aeltlftoxbs"
  })


  const handleDragEnd = (data: google.maps.MapMouseEvent) => {
    const newPosition = {
      lat: data.latLng!.lat(),
      lng: data.latLng!.lng(),
    };
    setLatitude(toNullable(data.latLng!.lat()))
    setLongitude(toNullable(data.latLng!.lng()))
    setPosition(newPosition);
  };

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null)
  }, [])

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    if(latitude !== undefined){
      const bounds = new window.google.maps.LatLngBounds({ lat: toNumber(latitude), lng: toNumber(longitude) });
      map.fitBounds(bounds);
    }
    else{
      const bounds = new window.google.maps.LatLngBounds({ lat: 0, lng: 0 });
      map.fitBounds(bounds);
    }

    setMap(map)
  }, [latitude, longitude])

  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const { id } = useParams();
  const { notify } = useNotification()

  function toNullable(value: any): any {
    return value === undefined || value === '' ? null : value;
  }


  function validateFields(){
    if(razaoSocial == "" || undefined){

    }

  }

  async function UpdateEst() {


    let data: IComptradeEstUpdate = {
      id: toNumber(id),
      razaoSocial: toNullable(razaoSocial?.toUpperCase()),
      rede: toNullable(rede?.toUpperCase()),
      bandeira: toNullable(bandeira?.toUpperCase()),
      nomeFantasia: toNullable(nomeFantasia?.toUpperCase()),
      filial: toNullable(filial?.toUpperCase()),
      canal: toNullable(canal?.toUpperCase()),
      tipoLogradouro: toNullable(tipoLogradouro?.toUpperCase()),
      logradouro: toNullable(logradouro?.toUpperCase()),
      numero: toNullable(numero?.toUpperCase()),
      complemento: toNullable(complemento?.toUpperCase()),
      bairro: toNullable(bairro?.toUpperCase()),
      cep: toNullable(cep),
      cidade: toNullable(cidade?.toUpperCase()),
      estado: toNullable(estado?.toUpperCase()),
      regiao: toNullable(regiao?.toUpperCase()),
      latitude: toNullable(latitude),
      longitude: toNullable(longitude),
      observacao: toNullable(observacao),
      subCanal: toNullable(subcanal),
      tipoLocal: toNullable(tipoLocal),
      shopping: toNullable(shopping),
      subRegiao: toNullable(subRegiao)
    }

    if (
      data.razaoSocial === null || data.razaoSocial === undefined || data.razaoSocial === '' ||
      data.rede === null || data.rede === undefined || data.rede === '' ||
      data.bandeira === null || data.bandeira === undefined || data.bandeira === '' ||
      data.nomeFantasia === null || data.nomeFantasia === undefined || data.nomeFantasia === '' ||
      data.canal === null || data.canal === undefined || data.canal === '' ||
      data.logradouro === null || data.logradouro === undefined || data.logradouro === '' ||
      data.numero === null || data.numero === undefined || data.numero === '' ||
      data.bairro === null || data.bairro === undefined || data.bairro === '' ||
      data.cep === null || data.cep === undefined || data.cep === '' ||
      data.cidade === null || data.cidade === undefined || data.cidade === '' ||
      data.estado === null || data.estado === undefined || data.estado === '' ||
      data.latitude === null || data.latitude === undefined || data.latitude === '' ||
      data.longitude === null || data.longitude === undefined || data.longitude === ''
    ) {
      debugger
      notify("Verifique os campos obrigatórios!", { variant: 'warning' })
      return
    }


    let response = await trackPromise(services.api.updateComptradeEst(data));

    if (response.fail) {
      notify("Não foi possivel atualizar o estabelecimento!", { variant: 'error' })
      return
    }
    else{
      notify("Estabelecimento atualizado com sucesso!", { variant: 'success' })
      GetEstData(toNumber(id))
      setNeedUpdate(false)
    }
  }

  async function AddChannel() {

    let response = await trackPromise(services.api.addComptradeChannel ({value: toCreateChannel.toUpperCase(), type: "CHANNEL"}));

    if (response.fail) {
      notify("Não foi possivel criar o canal!", { variant: 'error' })
      return
    }
    else{
      notify("Canal adicionado com sucesso!", { variant: 'success' })
      let channels = await trackPromise(services.api.getComptradeChannels("CHANNEL"));
      let values: string[] = channels.data
      setCanais(values)
      setToCreateChannel("")
    }
  }

  async function AddSubChannel() {

    let response = await trackPromise(services.api.addComptradeChannel ({value: toCreateSubChannel.toUpperCase(), type: "SUB-CHANNEL"}));

    if (response.fail) {
      notify("Não foi possivel criar o sub-canal!", { variant: 'error' })
      return
    }
    else{
      notify("Sub-canal adicionado com sucesso!", { variant: 'success' })
      let channels = await trackPromise(services.api.getComptradeChannels("SUB-CHANNEL"));
      let values: string[] = channels.data
      setSubCanais(values)
      setToCreateSubChannel("")
    }
  }

  async function AddPlaceType() {

    let response = await trackPromise(services.api.addComptradeChannel ({value: toCreatePlaceTypes.toUpperCase(), type: "PLACE-TYPE"}));

    if (response.fail) {
      notify("Não foi possivel criar o tipo de local!", { variant: 'error' })
      return
    }
    else{
      notify("Tipo de local adicionado com sucesso!", { variant: 'success' })
      let channels = await trackPromise(services.api.getComptradeChannels("PLACE-TYPE"));
      let values: string[] = channels.data
      setPlaceTypes(values)
      setToCreatePlaceTypes("")
    }
  }


  async function ApproveEst() {
    let response = await trackPromise(services.api.aproveComptradeEst(id));

    if (response.fail) {
      notify("Não foi possivel aprovar o estabelecimento!", { variant: 'error' })
      return
    }
    GetEstData(toNumber(id))
  }


  async function RefreshEstReceitaData() {
    let response = await trackPromise(services.api.refreshEstFederalData(id));

    if (response.fail) {
      notify("Não foi possivel atualizar os dados da receita - CNPJ não encontrado", { variant: 'error' })
      return
    }
    else if(response.status === 204){
      notify("Informações não encontradas na receita federal!", { variant: 'error' })
      return
    }
    GetEstData(toNumber(id))
  }

  async function GetEstData(id: number) {
    let response = await trackPromise(services.api.getComptradeEstById(id));
    let channels = await trackPromise(services.api.getComptradeChannels("CHANNEL"));
    let subChannels = await trackPromise(services.api.getComptradeChannels("SUB-CHANNEL"));
    let placeTypes = await trackPromise(services.api.getComptradeChannels("PLACE-TYPE"));
    let values: string[] = channels.data
    setCanais(values)
    setSubCanais(subChannels.data)
    setPlaceTypes(placeTypes.data)

    if (response.fail) {
      notify("Não foi possivel carregar os dados do dashboard", { variant: 'error' })
      return
    }
    else if(response.status === 204){
      notify("Dashboard inexistente", { variant: 'error' })
      return
    }

    let estData: IComptradeEst = response.data
    setIdInterno(estData.idInterno!);
    setTipoId(estData.tipoId!);
    setRazaoSocial(estData.razaoSocial);
    setRede(estData.rede);
    setBandeira(estData.bandeira);
    setNomeFantasia(estData.nomeFantasia);
    setFilial(estData.filial);
    setCanal(estData.canal);
    setTipoLogradouro(estData.tipoLogradouro);
    setLogradouro(estData.logradouro);
    setNumero(estData.numero);
    setComplemento(estData.complemento);
    setBairro(estData.bairro);
    setCep(estData.cep);
    setCidade(estData.cidade);
    setEstado(estData.estado);
    setRegiao(estData.regiao);
    setLatitude(estData.latitude?.toString());
    setLongitude(estData.longitude?.toString());
    setRecSituacaoCadastral(estData.recSituacaoCadastral);
    setRecRazaoSocial(estData.recRazaoSocial);
    setRecCapitalSocial(estData.recCapitalSocial);
    setRecNomeFantasia(estData.recNomeFantasia);
    setRecTipoLogradouro(estData.recTipoLogradouro);
    setRecLogradouro(estData.recLogradouro);
    setRecNumero(estData.recNumero);
    setRecComplemento(estData.recComplemento);
    setRecBairro(estData.recBairro);
    setRecCep(estData.recCep);
    setRecCidadeNome(estData.recCidadeNome);
    setRecEstadoSigla(estData.recEstadoSigla);
    setDdd(estData.recDdd);
    setTelefone(estData.recTelefone);
    setEmail(estData.recEmail);
    setRecDataSituacaoCadastral((moment(estData.recDataSituacaoCadastral).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(estData.recDataSituacaoCadastral).format('DD/MM/YYYY')));
    setCriadoEm((moment(estData.criadoEm).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(estData.criadoEm).format('DD/MM/YYYY')));
    setAtualizadoEm((moment(estData.atualizadoEm).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(estData.atualizadoEm).format('DD/MM/YYYY')));
    setHigienizado(estData.higienizado);
    setHigienizadoEm((moment(estData.higienizadoEm).format('DD/MM/YYYY') === '01/01/0001' ? '---' : moment(estData.higienizadoEm).format('DD/MM/YYYY')));
    setObservacao(estData.observacao)
    setAtividadePrincipal(estData.recCnaePrincipal)
    setAtividadeSecundaria(estData.recCnaeSecundario)
    setSubcanal(estData.subCanal)
    setTipoLocal(estData.tipoLocal)
    setShopping(estData.shopping)
    setSubRegiao(estData.subRegiao)

    if(estData.latitude !== undefined){
      const newPosition = {
        lat: estData.latitude,
        lng: estData.longitude!
      };
      setPosition(newPosition);
    }


  }

  useEffect(() => {
    if(afterLoad){
      setNeedUpdate(true)
    }
  }, [id, razaoSocial, rede, bandeira, nomeFantasia, filial, canal, tipoLogradouro, logradouro, numero,
    complemento, bairro, cep, cidade, estado, regiao, latitude, longitude, ddd, telefone, email, subcanal, observacao, subRegiao, shopping, tipoLocal]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAfterLoad(true);
    }, 1000);

    return () => clearTimeout(timeout);
  });

  // useEffect(() => {
  //   if (changes > 1) {
  //     setDashboardChanged(true);
  //   }
  //   setDashboardChanges(changes + 1)
  // }, [dashboardName, dashboardOrder, dashboardActive, dashboardReportPath, dashboardRefreshTime, dashboardEnableNavigationBar, dashboardEnableFilters]);


  useEffect(() => {
    const init = async () => {
      await GetEstData(number(id));
    };
    init().then()
  }, [id]);


  return (
    <div className='wrapper-container-content bgwhite'>
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle subText={`Editando o estabelecimento #${id}`}>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowBackIcon sx={{ fontSize: 40, marginRight: '10px', cursor: 'pointer' }} onClick={() => window.location.href = `/app/comptrade/est`} />
            <div>{idInterno ? `Estabelecimento > ${idInterno} - ${tipoId}` : 'Estabelecimento > ...'}</div>
          </div>


        </PageTitle>


        <div className='panel'>
          <div className='panel-body'>

            <h3>Dados do estabelecimento</h3>

            <span className={higienizado ? `badge badge-success` : `badge badge-warning`}>
          {higienizado ? `Higienizado` : `Não higienizado`}
        </span>

            <Divider></Divider>


            <Collapse defaultActiveKey={['1']}>
              <CollapsePanel header="Informações do estabelecimento" key="1">

                <Grid container justifyContent="start" spacing={{ xs: 3 }}>

                  {/*RAZÃO SOCIAL*/}
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Razão Social"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={razaoSocial?.toString()}
                      className={razaoSocial === '' || razaoSocial === null ? 'error-border' : ''}
                      onChange={(e) => setRazaoSocial(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>

                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Razão Social"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recRazaoSocial?.toString()}
                      disabled={true}
                      onChange={(e) => setRecRazaoSocial(e.target.value)}
                    />

                  </Grid>
                  }


                  {/*NOME FANTASIA*/}
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Nome Fantasia"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={nomeFantasia?.toString()}
                      className={nomeFantasia === '' || nomeFantasia === null ? 'error-border' : ''}
                      onChange={(e) => setNomeFantasia(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Nome Fantasia"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recNomeFantasia?.toString()}
                      disabled={true}
                      onChange={(e) => setRecNomeFantasia(e.target.value)}
                    />
                  </Grid>
                  }

                  {/*FILIAL E SITU CADASTRAL*/}
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Filial"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={filial?.toString()}
                      onChange={(e) => setFilial(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Situação Cadastral"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      disabled={true}
                      value={recSituacaoCadastral?.toString()}
                      onChange={(e) => setRecSituacaoCadastral(e.target.value)}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Rede"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={rede?.toString()}
                      className={rede === '' || rede === null ? 'error-border' : ''}
                      onChange={(e) => setRede(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>

                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Data Situação Cadastral"
                      InputLabelProps={{ shrink: true }}
                      value={recDataSituacaoCadastral?.toString()}
                      disabled={true}
                    />
                  </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Bandeira"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      className={bandeira === '' || bandeira === null ? 'error-border' : ''}
                      value={bandeira?.toString()}
                      onChange={(e) => setBandeira(e.target.value)}
                    />
                  </Grid>


                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Capital Social"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      value={recCapitalSocial?.toString()}
                      disabled={true}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>

                    <label className={canal === '' || canal === null ? 'custom-input-label error-border' : 'custom-input-label'}>  Canal  </label>

                    <AntdSelect
                      style={{ width: "100%", height: "100%"}}
                      className={'antd-select-text'}
                      placeholder="Selecionar"
                      value={canal}
                      onChange={(e) => setCanal(e)}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />


                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <TextField
                              fullWidth
                              style={{ flexGrow: 1 }}
                              placeholder="Nome do canal"
                              value={toCreateChannel}
                              onChange={(e) => setToCreateChannel(e.target.value.toUpperCase())}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              variant="contained"
                              className='mr-6 content-end'
                              type="button"
                              size='large'
                              color="success"
                              onClick={AddChannel}
                              disabled={toCreateChannel.length < 3}
                              style={{ flexShrink: 0 }}
                            >
                              <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize='small' />
                            </Button>
                          </div>

                        </>
                      )}
                      options={canais.map((item) => ({ label: item, value: item }))}
                    />

                  </Grid>


                  {
                    tipoId !== "CPF"
                    &&
                    <Grid item sm={12} md={3} xs={3}>
                      <TextField
                        fullWidth
                        size='small'
                        label="(RF) DDD"
                        type={"text"}
                        // inputProps={{
                        //   maxLength: 3,
                        //   pattern: "\\d{0,3}" // Expressão regular para aceitar até 3 dígitos numéricos
                        // }}
                        InputLabelProps={{ shrink: true }}
                        value={ddd?.toString()}
                        disabled={true}
                        onChange={(e) => setDdd(e.target.value)}
                      />
                    </Grid>
                  }

                  {
                    tipoId !== "CPF"
                    &&
                    <Grid item sm={12} md={3} xs={3}>
                      <TextField
                        fullWidth
                        size='small'
                        label="(RF) Telefone"
                        // inputProps={{
                        //   maxLength: 9,
                        //   pattern: "\\d{0,9}"
                        // }}
                        InputLabelProps={{ shrink: true }}
                        value={telefone?.toString()}
                        disabled={true}
                        onChange={(e) => setTelefone(e.target.value)}
                      />
                    </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>

                    <label className ={'custom-input-label'}>  Sub Canal  </label>

                    <AntdSelect
                      style={{ width: "100%", height: "100%"}}
                      className={'antd-select-text'}
                      placeholder="Selecionar"
                      value={subcanal}
                      onChange={(e) => setSubcanal(e)}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />


                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <TextField
                              fullWidth
                              style={{ flexGrow: 1 }}
                              placeholder="Nome do sub canal"
                              value={toCreateSubChannel}
                              onChange={(e) => setToCreateSubChannel(e.target.value.toUpperCase())}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              variant="contained"
                              className='mr-6 content-end'
                              type="button"
                              size='large'
                              color="success"
                              onClick={AddSubChannel}
                              disabled={toCreateSubChannel.length < 3}
                              style={{ flexShrink: 0 }}
                            >
                              <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize='small' />
                            </Button>
                          </div>

                        </>
                      )}
                      options={subCanais.map((item) => ({ label: item, value: item }))}
                    />

                  </Grid>


                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Email"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={email?.toString()}
                      disabled={true}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>

                    <label className ={'custom-input-label'}>  Tipo de local  </label>

                    <AntdSelect
                      style={{ width: "100%", height: "100%"}}
                      className={'antd-select-text'}
                      placeholder="Selecionar"
                      value={tipoLocal}
                      onChange={(e) => setTipoLocal(e)}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />


                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <TextField
                              fullWidth
                              style={{ flexGrow: 1 }}
                              placeholder="Tipo de local"
                              value={toCreatePlaceTypes}
                              onChange={(e) => setToCreatePlaceTypes(e.target.value.toUpperCase())}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              variant="contained"
                              className='mr-6 content-end'
                              type="button"
                              size='large'
                              color="success"
                              onClick={AddPlaceType}
                              disabled={toCreatePlaceTypes.length < 3}
                              style={{ flexShrink: 0 }}
                            >
                              <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize='small' />
                            </Button>
                          </div>

                        </>
                      )}
                      options={placeTypes.map((item) => ({ label: item, value: item }))}
                    />

                  </Grid>


                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Shopping"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={shopping?.toString()}
                      onChange={(e) => setShopping(e.target.value)}
                    />
                  </Grid>


                  {
                    tipoId !== "CPF"
                    &&

                    <Grid item sm={12} md={12} xs={12}>
                      <TextField
                        fullWidth
                        size='small'
                        label="(RF) Atividade Principal"
                        inputProps={{  }}
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        value={atividadePrincipal?.toString()}
                      />
                    </Grid>
                  }

                  {
                    tipoId !== "CPF"
                    &&

                    <Grid item sm={12} md={12} xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        size='medium'
                        label="(RF) Atividade Secundária"
                        inputProps={{  }}
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        value={atividadeSecundaria?.toString()}
                      />
                    </Grid>
                  }





                </Grid>


              </CollapsePanel>

              <CollapsePanel header="Endereço" key="2">
                <Grid container justifyContent="start" spacing={{ xs: 3 }}>

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Tipo de Logradouro"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={tipoLogradouro?.toString()}
                      onChange={(e) => setTipoLogradouro(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Tipo Logradouro"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recTipoLogradouro?.toString()}
                      disabled={true}
                      onChange={(e) => setRecTipoLogradouro(e.target.value)}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Logradouro"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      className={logradouro === '' || logradouro === null ? 'error-border' : ''}
                      value={logradouro?.toString()}
                      onChange={(e) => setLogradouro(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Logradouro"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recLogradouro?.toString()}
                      disabled={true}
                      onChange={(e) => setRecLogradouro(e.target.value)}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Número"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={numero?.toString()}
                      className={numero === '' || numero === null ? 'error-border' : ''}
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Número"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recNumero?.toString()}
                      disabled={true}
                      onChange={(e) => setRecNumero(e.target.value)}
                    />
                  </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Complemento"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={complemento?.toString()}
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Complemento"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recComplemento?.toString()}
                      disabled={true}
                      onChange={(e) => setRecComplemento(e.target.value)}
                    />
                  </Grid>
                  }

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Bairro"
                      inputProps={{  }}
                      className={bairro === '' || bairro === null ? 'error-border' : ''}
                      InputLabelProps={{ shrink: true }}
                      value={bairro?.toString()}
                      onChange={(e) => setBairro(e.target.value)}
                    />
                  </Grid>


                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Bairro"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recBairro?.toString()}
                      disabled={true}
                      onChange={(e) => setRecBairro(e.target.value)}
                    />
                  </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="CEP"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      className={cep === '' || cep === null ? 'error-border' : ''}
                      value={cep?.toString()}
                      onChange={(e) => setCep(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) CEP"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recCep?.toString()}
                      disabled={true}
                      onChange={(e) => setRecCep(e.target.value)}
                    />
                  </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Cidade"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      className={cidade === '' || cidade === null ? 'error-border' : ''}
                      value={cidade?.toString()}
                      onChange={(e) => setCidade(e.target.value)}
                    />
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) Cidade Nome"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recCidadeNome?.toString()}
                      disabled={true}
                      onChange={(e) => setRecCidadeNome(e.target.value)}
                    />
                  </Grid>
                  }


                  <Grid item sm={12} md={6} xs={6}>
                    <FormControl>
                      <InputLabel className={estado === '' || estado === null ? 'error-border' : ''} shrink>Estado</InputLabel>
                      <Select
                        fullWidth
                        required
                        notched
                        size="small"
                        label="Estado"
                        value={estado || ''}
                        onChange={e => setEstado(e.target.value)}
                      >
                        <MenuItem value={''}>
                          Selecione
                        </MenuItem>
                        <MenuItem value={"AC"}>Acre (AC)</MenuItem>
                        <MenuItem value={"AL"}>Alagoas (AL)</MenuItem>
                        <MenuItem value={"AP"}>Amapá (AP)</MenuItem>
                        <MenuItem value={"AM"}>Amazonas (AM)</MenuItem>
                        <MenuItem value={"BA"}>Bahia (BA)</MenuItem>
                        <MenuItem value={"CE"}>Ceará (CE)</MenuItem>
                        <MenuItem value={"DF"}>Distrito Federal (DF)</MenuItem>
                        <MenuItem value={"ES"}>Espírito Santo (ES)</MenuItem>
                        <MenuItem value={"GO"}>Goiás (GO)</MenuItem>
                        <MenuItem value={"MA"}>Maranhão (MA)</MenuItem>
                        <MenuItem value={"MT"}>Mato Grosso (MT)</MenuItem>
                        <MenuItem value={"MS"}>Mato Grosso do Sul (MS)</MenuItem>
                        <MenuItem value={"MG"}>Minas Gerais (MG)</MenuItem>
                        <MenuItem value={"PA"}>Pará (PA)</MenuItem>
                        <MenuItem value={"PB"}>Paraíba (PB)</MenuItem>
                        <MenuItem value={"PR"}>Paraná (PR)</MenuItem>
                        <MenuItem value={"PE"}>Pernambuco (PE)</MenuItem>
                        <MenuItem value={"PI"}>Piauí (PI)</MenuItem>
                        <MenuItem value={"RJ"}>Rio de Janeiro (RJ)</MenuItem>
                        <MenuItem value={"RN"}>Rio Grande do Norte (RN)</MenuItem>
                        <MenuItem value={"RS"}>Rio Grande do Sul (RS)</MenuItem>
                        <MenuItem value={"RO"}>Rondônia (RO)</MenuItem>
                        <MenuItem value={"RR"}>Roraima (RR)</MenuItem>
                        <MenuItem value={"SC"}>Santa Catarina (SC)</MenuItem>
                        <MenuItem value={"SP"}>São Paulo (SP)</MenuItem>
                        <MenuItem value={"SE"}>Sergipe (SE)</MenuItem>
                        <MenuItem value={"TO"}>Tocantins (TO)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {
                    tipoId !== "CPF"
                    &&
                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="(RF) UF"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={recEstadoSigla?.toString()}
                      disabled={true}
                      onChange={(e) => setRecEstadoSigla(e.target.value)}
                    />
                  </Grid>
                  }



                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Região"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={regiao?.toString()}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} md={6} xs={6}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Sub Região"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={subRegiao?.toString()}
                      onChange={(e) => setSubRegiao(e.target.value)}
                    />
                  </Grid>

                </Grid>


              </CollapsePanel>


              <CollapsePanel header="Localização" key="3">
                <Grid container justifyContent="start" spacing={{ xs: 3 }}>
                  <Grid item sm={12} md={3} xs={3}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Latitude"
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      className={latitude === '' || latitude === null ? 'error-border' : ''}
                      value={latitude?.toString()}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={12} md={3} xs={3}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Longitude"
                      className={longitude === '' || longitude === null ? 'error-border' : ''}
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={longitude?.toString()}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <br/>


                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={position}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  { /* Child components, such as markers, info windows, etc. */ }
                  <Marker
                    position={position}
                    draggable={true}
                    onDragEnd={handleDragEnd}
                  />
                </GoogleMap>

              </CollapsePanel>

              <CollapsePanel header="Informações" key="4">
                <Grid container justifyContent="start" spacing={{ xs: 3 }}>

                  <Grid item sm={12} md={3} xs={3}>

                    <TextField
                      fullWidth
                      size='small'
                      label="Criado Em"
                      InputLabelProps={{ shrink: true }}
                      value={criadoEm?.toString()}
                    />

                  </Grid>
                  <Grid item sm={12} md={3} xs={3}>

                    <TextField
                      fullWidth
                      size='small'
                      label="Atualizado Em"
                      InputLabelProps={{ shrink: true }}
                      value={atualizadoEm?.toString()}
                    />

                  </Grid>
                  <Grid item sm={12} md={3} xs={3}>

                    <TextField
                      fullWidth
                      size='small'
                      label="Higienizado Em"
                      InputLabelProps={{ shrink: true }}
                      value={higienizadoEm?.toString()}
                    />

                  </Grid>

                  <Grid item sm={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      label="Observações"
                      multiline
                      rows={3}
                      inputProps={{  }}
                      InputLabelProps={{ shrink: true }}
                      value={observacao?.toString()}
                      onChange={(e) => setObservacao(e.target.value)}
                    />
                  </Grid>

                </Grid>

              </CollapsePanel>


            </Collapse>



              <br/>


            <Divider></Divider>


            <Grid item xs={12} className="flex justify-end gap-6">

              {
                tipoId !== "CPF"
                &&
                <div style={{textAlign: "right", marginTop: "10px"}}>

                  <Popconfirm title="Deseja atualizar os dados da receita federal?" cancelText={"Cancelar"} okText={"Atualizar"} onConfirm={RefreshEstReceitaData}>
                    <Button
                      variant="contained"
                      className='mr-6 content-end mb-12'
                      type="button"
                      size='small'
                      color="success"
                    >
                      <Download style={{marginRight: '5px'}} fontSize='small' />  Atualizar dados da receita
                    </Button>
                  </Popconfirm>
                </div>
              }



              <div style={{textAlign: "right", marginTop: "10px"}}>

                <Popconfirm title="Deseja aprovar este estabelecimento?" cancelText={"Cancelar"} okText={"Aprovar"} onConfirm={ApproveEst}>
                  <Button
                    variant="contained"
                    className='mr-6 content-end mb-12'
                    type="button"
                    size='small'
                    color="success"
                  >
                    <ThumbUp style={{marginRight: '5px'}} fontSize='small' />  Aprovar estabelecimento
                  </Button>
                </Popconfirm>
              </div>

              <div style={{textAlign: "right", marginTop: "10px"}}>
                <Popconfirm title="Deseja atualizar este estabelecimento?" cancelText={"Cancelar"} okText={"Atualizar"} onConfirm={UpdateEst}>
                <Button
                  variant="contained"
                  className='btn-purple mr-6 content-end mb-12'
                  type="button"
                  size='small'
                  color="inherit"
                  disabled={!needUpdate}
                >
                  <Save style={{marginRight: '5px'}} fontSize='small' />  Salvar alterações
                </Button>
                </Popconfirm>

              </div>



            </Grid>


          </div>
        </div>




      </div>
    </div>
  )
}

export default ComptradeEstEdit;
